<template>
  <div class="business-departments">
    <div class="departments-layout">
      <!-- Left Panel: Department Selection -->
      <div class="selection-panel">
        <h2>Select Departments</h2>
        <div class="department-grid">
          <div v-for="dept in availableDepartments" 
               :key="dept.id"
               :class="[
                 'department-card', 
                 { 
                   'selected': selectedDepartments.some(d => d.id === dept.id),
                   'requested': isDepartmentRequested(dept.id)
                 }
               ]"
               @click="toggleDepartment(dept)">
            <div class="department-header">
              <div class="department-icon">
                <i :class="dept.icon"></i>
              </div>
              <div class="info-icon" @click.stop="showDepartmentDetails(dept)">
                <i class="fas fa-info-circle"></i>
              </div>
            </div>
            <h3>{{ dept.name }}</h3>
            <p class="department-brief">{{ dept.brief }}</p>
            <div class="department-price">
              <template v-if="isDepartmentRequested(dept.id)">
                Already Requested
              </template>
              <template v-else>
                Starting at ${{ dept.basePrice.toLocaleString() }}
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Panel: Selected Departments -->
      <div class="summary-panel">
        <div class="selected-departments">
          <h2>Your Selections</h2>
          <div v-if="selectedDepartments.length === 0" class="empty-state">
            <p>Select departments to see pricing and details</p>
          </div>
          <div v-else class="department-list">
            <div v-for="dept in selectedDepartments" 
                 :key="dept.id" 
                 class="selected-department-card">
              <div class="department-header">
                <h3>{{ dept.name }}</h3>
                <button @click="removeDepartment(dept)" class="remove-btn">
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="employee-range">
                <label>Number of Employees:</label>
                <div class="range-selector">
                  <select v-model="dept.employeeRange">
                    <option v-for="range in employeeRanges" 
                            :key="range.id" 
                            :value="range.id">
                      {{ range.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="department-price-details">
                <span>Cost:</span>
                <span>${{ calculateDepartmentPrice(dept).toLocaleString() }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Total and Action Section -->
        <div class="total-section" v-if="selectedDepartments.length > 0">
          <div class="price-breakdown">
            <div class="subtotal">
              <span>Subtotal:</span>
              <span>${{ subtotal.toLocaleString() }}</span>
            </div>
            <div class="discount" v-if="discount > 0">
              <span>Multi-Department Discount:</span>
              <span>-${{ discount.toLocaleString() }}</span>
            </div>
            <div class="total">
              <span>Total:</span>
              <span>${{ total.toLocaleString() }}</span>
            </div>
          </div>
          <button @click="requestProposal" 
                  class="request-proposal-btn"
                  :disabled="!canRequestProposal">
            Request Proposal
          </button>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div v-if="showSuccessModal" 
         class="modal-overlay"
         @click="closeSuccessModal">
      <div class="modal-content success-modal" @click.stop>
        <div class="success-message">
          <i class="fas fa-check-circle"></i>
          <h2>Proposal Request Submitted!</h2>
          <p>You will receive it in your email shortly.</p>
          <div class="progress-bar">
            <div class="progress-fill" :style="{ width: progressWidth + '%' }"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Department Details Modal -->
    <div v-if="selectedDepartmentForDetails" 
         class="modal-overlay"
         @click="closeDetailsModal">
      <div class="modal-content" @click.stop>
        <button class="close-modal" @click="closeDetailsModal">
          <i class="fas fa-times"></i>
        </button>
        <h2>{{ selectedDepartmentForDetails.name }}</h2>
        <div class="department-details">
          <h3>Example AI Applications</h3>
          <ul>
            <li v-for="(benefit, index) in selectedDepartmentForDetails.benefits" 
                :key="index">
              {{ benefit }}
            </li>
          </ul>
          <h3>Example Implementation Steps</h3>
          <ol>
            <li v-for="(step, index) in selectedDepartmentForDetails.process" 
                :key="index">
              {{ step }}
            </li>
          </ol>
          <div class="expected-outcomes">
            <h3>Potential Results</h3>
            <p>{{ selectedDepartmentForDetails.outcomes }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateBusinessDepartments, getBusinessPlan } from '@/services/api'

export default {
  name: 'DepartmentsContent',
  data() {
    return {
      selectedDepartmentForDetails: null,
      selectedDepartments: [],
      existingPlan: [],
      availableDepartments: [
        {
          id: 1,
          name: 'Sales',
          icon: 'fas fa-chart-line',
          brief: 'Optimize sales processes and increase conversion rates with AI',
          basePrice: 5000,
          benefits: [
            'AI-powered email and proposal writing',
            'Automated personalized outreach at scale',
            'Smart lead qualification and prioritization',
            'Real-time conversation insights and coaching'
          ],
          process: [
            'Analysis of current sales communications and processes',
            'Custom AI model training on your successful sales data',
            'Integration with CRM and communication tools',
            'Team training on AI-enhanced workflows'
          ],
          outcomes: 'Example improvements could include: 35% faster deal closure through automated follow-ups, 40% increase in response rates with AI-optimized messaging, and 25% higher conversion rates using intelligent lead prioritization.'
        },
        {
          id: 2,
          name: 'Customer Service',
          icon: 'fas fa-headset',
          brief: 'Enhance customer support with AI-powered solutions',
          basePrice: 5000,
          benefits: [
            'AI-powered response generation and suggestions',
            'Intelligent ticket classification and routing',
            'Automated response drafting for common issues',
            'Real-time sentiment analysis and escalation'
          ],
          process: [
            'Analysis of support tickets and common issues',
            'Custom AI training on your best service interactions',
            'Integration with support and communication platforms',
            'Team training on AI-assisted support workflows'
          ],
          outcomes: 'Potential improvements include: 60% reduction in response time using AI-assisted replies, 45% faster resolution times through intelligent routing, and up to 35% higher customer satisfaction scores with consistent, quality responses.'
        },
        {
          id: 3,
          name: 'Marketing',
          icon: 'fas fa-bullhorn',
          brief: 'Transform marketing with AI-driven insights and automation',
          basePrice: 5000,
          benefits: [
            'AI-powered content creation and optimization',
            'Automated multi-channel campaign generation',
            'Smart audience segmentation and targeting',
            'Real-time campaign performance optimization'
          ],
          process: [
            'Analysis of current marketing content and performance',
            'Custom AI model training on successful campaigns',
            'Integration with marketing platforms and tools',
            'Team training on AI-enhanced content creation'
          ],
          outcomes: 'Potential benefits include: 40% faster content creation with AI assistance, 35% higher engagement rates through optimized messaging, and approximately 30% improvement in campaign ROI using AI-driven insights.'
        },
        {
          id: 4,
          name: 'Operations',
          icon: 'fas fa-cogs',
          brief: 'Streamline operations with intelligent automation',
          basePrice: 5000,
          benefits: [
            'AI-powered process optimization and automation',
            'Smart workflow suggestions and improvements',
            'Automated documentation and reporting',
            'Real-time efficiency monitoring and alerts'
          ],
          process: [
            'Analysis of current operational workflows',
            'Custom AI model training on your processes',
            'Integration with operational systems',
            'Team training on AI-optimized workflows'
          ],
          outcomes: 'Example efficiency gains: 40% reduction in manual task time through automation, 35% improvement in process efficiency with AI optimization, and potentially 30% fewer operational errors using AI monitoring.'
        },
        {
          id: 5,
          name: 'Inventory Management',
          icon: 'fas fa-boxes',
          brief: 'Optimize inventory levels and supply chain with AI predictions',
          basePrice: 5000,
          benefits: [
            'AI-powered demand forecasting and planning',
            'Smart reorder point optimization',
            'Automated supplier communication',
            'Real-time inventory optimization suggestions'
          ],
          process: [
            'Analysis of inventory data and patterns',
            'Custom AI model training on your demand history',
            'Integration with inventory management systems',
            'Team training on AI-driven inventory decisions'
          ],
          outcomes: 'Potential optimization results: Up to 50% reduction in stockout incidents, 40% decrease in excess inventory through AI forecasting, and approximately 35% improvement in inventory turnover rates.'
        },
        {
          id: 6,
          name: 'Finance',
          icon: 'fas fa-calculator',
          brief: 'Enhance financial operations with AI insights',
          basePrice: 12000,
          benefits: [
            'AI-powered financial analysis and reporting',
            'Automated anomaly detection and fraud prevention',
            'Smart cash flow forecasting and optimization',
            'Real-time financial insights and recommendations'
          ],
          process: [
            'Analysis of financial data and reporting needs',
            'Custom AI model training on your financial patterns',
            'Integration with financial systems and tools',
            'Team training on AI-enhanced financial workflows'
          ],
          outcomes: 'Potential financial improvements: Up to 65% faster reporting cycles with automation, estimated 70% better fraud detection rates using AI monitoring, and approximately 45% more accurate cash flow forecasting.'
        }
      ],
      employeeRanges: [
        { id: 1, label: '1-10 employees', multiplier: 1 },
        { id: 2, label: '11-20 employees', multiplier: 1.8 },
        { id: 3, label: '21-30 employees', multiplier: 2.5 },
        { id: 4, label: '31-40 employees', multiplier: 3.2 },
        { id: 5, label: '41-50 employees', multiplier: 4 }
      ],
      showSuccessModal: false,
      progressWidth: 0
    }
  },
  computed: {
    subtotal() {
      return this.selectedDepartments.reduce((total, dept) => {
        return total + this.calculateDepartmentPrice(dept)
      }, 0)
    },
    discount() {
      if (this.selectedDepartments.length > 1) {
        return Math.floor(this.subtotal * 0.1)
      }
      return 0
    },
    total() {
      return this.subtotal - this.discount
    },
    canRequestProposal() {
      return this.selectedDepartments.length > 0 && 
             this.selectedDepartments.every(dept => dept.employeeRange)
    },
    isDepartmentRequested() {
      return (deptId) => {
        return this.existingPlan.some(dept => dept.id === deptId)
      }
    }
  },
  methods: {
    toggleDepartment(dept) {
      if (this.isDepartmentRequested(dept.id)) {
        return
      }
      
      const index = this.selectedDepartments.findIndex(d => d.id === dept.id)
      if (index === -1) {
        this.selectedDepartments.push({
          ...dept,
          employeeRange: 1
        })
      } else {
        this.selectedDepartments.splice(index, 1)
      }
    },
    removeDepartment(dept) {
      const index = this.selectedDepartments.findIndex(d => d.id === dept.id)
      if (index !== -1) {
        this.selectedDepartments.splice(index, 1)
      }
    },
    calculateDepartmentPrice(dept) {
      const range = this.employeeRanges.find(r => r.id === dept.employeeRange)
      return Math.floor(dept.basePrice * (range ? range.multiplier : 1))
    },
    showDepartmentDetails(dept) {
      this.selectedDepartmentForDetails = dept
    },
    closeDetailsModal() {
      this.selectedDepartmentForDetails = null
    },
    async requestProposal() {
      try {
        let uid;
        const assessmentData = localStorage.getItem('assessmentData');
        if (assessmentData) {
          const parsedData = JSON.parse(assessmentData);
          uid = parsedData.assessment.uid;
        }
        
        if (!uid) {
          throw new Error('User not authenticated')
        }

        const departmentsData = this.selectedDepartments.map(dept => ({
          id: dept.id,
          name: dept.name,
          employeeRange: this.employeeRanges.find(r => r.id === dept.employeeRange).label,
          price: this.calculateDepartmentPrice(dept)
        }))

        const response = await updateBusinessDepartments(uid, departmentsData)
        
        if (response.success) {
          this.showSuccessModal = true
          this.progressWidth = 0
          
          // Start progress animation
          const startTime = Date.now()
          const duration = 3000 // 3 seconds
          
          const updateProgress = () => {
            const elapsed = Date.now() - startTime
            this.progressWidth = Math.min((elapsed / duration) * 100, 100)
            
            if (elapsed < duration) {
              requestAnimationFrame(updateProgress)
            } else {
              this.closeSuccessModal()
              this.$emit('navigate', 'BusinessDashboardContent')
            }
          }
          
          requestAnimationFrame(updateProgress)
        }
        
        // Emit event for parent component to handle
        this.$emit('proposal-requested', {
          departments: departmentsData,
          subtotal: this.subtotal,
          discount: this.discount,
          total: this.total
        })
      } catch (error) {
        console.error('Error requesting proposal:', error)
        alert('There was an error requesting the proposal. Please try again.')
      }
    },
    closeSuccessModal() {
      this.showSuccessModal = false
    },
    async fetchExistingPlan() {
      try {
        const assessmentData = localStorage.getItem('assessmentData');
        if (assessmentData) {
          const parsedData = JSON.parse(assessmentData);
          const uid = parsedData.assessment.uid;
          
          if (uid) {
            const response = await getBusinessPlan(uid);
            if (response.success && response.plan) {
              this.existingPlan = response.plan;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching existing plan:', error);
      }
    }
  },
  async created() {
    await this.fetchExistingPlan();
  }
}
</script>

<style scoped>
.business-departments {
  padding: 2rem;
  max-width: 1500px;
  margin: 0 auto;
}

.departments-layout {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 2rem;
  min-height: 80vh;
}

.selection-panel, .summary-panel {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.department-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.department-card {
  background: white;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.department-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-color: var(--accent-victory-green);
}

.department-card.selected {
  border-color: var(--accent-victory-green);
  background-color: rgba(var(--accent-victory-green-rgb), 0.05);
}

.department-icon {
  font-size: 2rem;
  color: var(--accent-victory-green);
  margin-bottom: 0.5rem;
}

.department-card h3 {
  font-size: 1.2rem;
  color: var(--main-text-color);
  margin: 0;
}

.department-brief {
  font-size: 0.9rem;
  color: #666;
  flex-grow: 1;
}

.department-price {
  font-size: 0.9rem;
  color: var(--accent-victory-green);
  font-weight: bold;
}

.selected-departments {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.empty-state {
  text-align: center;
  color: #666;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 8px;
}

.selected-department-card {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.department-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.remove-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.remove-btn:hover {
  color: #dc3545;
}

.employee-range {
  margin-bottom: 1rem;
}

.range-selector select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.department-price-details {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: var(--accent-victory-green);
}

.total-section {
  margin-top: auto;
  border-top: 2px solid #e0e0e0;
  padding-top: 1.5rem;
  background: white;
}

.price-breakdown {
  margin-bottom: 1.5rem;
}

.price-breakdown > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.total {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-victory-green);
}

.request-proposal-btn {
  width: 100%;
  padding: 1rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.request-proposal-btn:hover:not(:disabled) {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.request-proposal-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.department-details h3 {
  color: var(--accent-victory-green);
  margin: 1.5rem 0 1rem;
}

.department-details ul,
.department-details ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.department-details li {
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.expected-outcomes {
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1.5rem;
}

.expected-outcomes p {
  line-height: 1.6;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.department-list {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.summary-panel {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

/* Scrollbar styling */
.department-list::-webkit-scrollbar {
  width: 8px;
}

.department-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.department-list::-webkit-scrollbar-thumb {
  background: var(--accent-victory-green);
  border-radius: 4px;
}

.department-list::-webkit-scrollbar-thumb:hover {
  background: var(--accent-sunny-yellow);
}

.info-icon {
  color: var(--accent-victory-green);
  opacity: 0.7;
  transition: opacity 0.3s ease;
  cursor: pointer;
  padding: 5px;
}

.info-icon:hover {
  opacity: 1;
}

.department-card.requested {
  opacity: 0.6;
  cursor: not-allowed;
  border-color: #ccc;
  background-color: #f5f5f5;
}

.department-card.requested:hover {
  transform: none;
  box-shadow: none;
  border-color: #ccc;
}

.success-modal {
  max-width: 400px;
  text-align: center;
  padding: 2rem;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.success-message i {
  font-size: 3rem;
  color: var(--accent-victory-green);
}

.success-message h2 {
  margin: 0;
  color: var(--accent-victory-green);
}

.success-message p {
  margin: 0;
  color: #666;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: #eee;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 1rem;
}

.progress-fill {
  height: 100%;
  background-color: var(--accent-victory-green);
  transition: width 0.1s linear;
}
</style> 