<template>
  <div class="chat-steps">
    <ol class="steps-list">
      <li v-for="step in 5" :key="step" 
          :class="{ 'active': currentStep === step }">
        <div class="step-content">
          <span class="step-number">{{ step }}.</span>
          <span class="step-text">{{ getStepText(step) }}</span>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ChatSteps',
  computed: {
    ...mapGetters('chatStore', ['currentStep'])
  },
  methods: {
    getStepText(step) {
      const steps = {
        1: 'Basic Info',
        2: 'Challenges & Goals',
        3: 'Departmental Impacts',
        4: 'Summary',
        5: 'Next Steps'
      }
      return steps[step]
    }
  }
}
</script>

<style scoped>
.chat-steps {
  padding: 40px 20px;
  height: 100%;
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.steps-list li {
  margin-bottom: 30px;
  position: relative;
}

.step-content {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.step-number {
  font-weight: bold;
  color: var(--accent-victory-green);
}

.step-text {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.2;
  flex: 1;
}

.active .step-content {
  background-color: var(--secondary-background-sky-blue);
  color: white;
}

.active .step-number,
.active .step-text {
  color: white;
}
</style> 