<template>
  <div v-if="show" class="microphone-error-modal" @click.self="$emit('close')">
    <div class="modal-content">
      <h3>Microphone Required</h3>
      <p>Thank you but your device must have a microphone to talk to an AI Agent.</p>
      <button type="button" @click="$emit('close')">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MicrophoneErrorModal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close']
}
</script>

<style scoped>
.microphone-error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: var(--primary-background-cream);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

p {
  margin-bottom: 1.5rem;
  color: var(--main-text-color);
  line-height: 1.5;
}

button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

/* Transition animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style> 