<template>
  <div class="lottie-button">
    <LottieAnimation
      v-if="animationData"
      :animationData="animationData"
      :height="size"
      :width="size"
      :loop="true"
      :autoplay="true"
      :speed="1"
      ref="lottieRef"
      class="lottie-animation"
    />
  </div>
</template>

<script>
import { LottieAnimation } from 'lottie-web-vue'
import { mapState, mapActions } from 'vuex'
import animationJson from '@/assets/Animation - 1735575806406.json'

export default {
  name: 'LottieButton',
  components: {
    LottieAnimation
  },
  props: {
    size: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      animationData: animationJson
    }
  },
  computed: {
    ...mapState({
      isListening: state => state.swimlaneMicrophone
    })
  },
  methods: {
    ...mapActions(['toggleSwimlaneMicrophone'])
  },
  watch: {
    isListening(newValue) {
      if (newValue) {
        this.$refs.lottieRef?.play()
      } else {
        this.$refs.lottieRef?.stop()
      }
    }
  }
}
</script>

<style scoped>
.lottie-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 40px;
  height: 40px;
  position: relative;
}

.lottie-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(8.5);
}
</style> 