<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h2>Audio Check</h2>
      <p>Please adjust your speakers until you can hear the music at a comfortable volume.</p>
      <div class="volume-icon">
        <i class="fas fa-volume-up"></i>
      </div>
      <div class="button-group">
        <button 
          class="action-button continue"
          @click="handleContinue"
        >
          I can hear the music
        </button>
        <button 
          class="action-button no-audio"
          @click="handleNoAudio"
        >
          I do not hear any music
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioCheckModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      audioContext: null,
      oscillator: null,
      gainNode: null
    }
  },
  methods: {
    initAudio() {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      this.gainNode = this.audioContext.createGain()
      this.gainNode.connect(this.audioContext.destination)
      this.gainNode.gain.value = 0.1 // Low volume
      this.playMelody()
    },
    playMelody() {
      const notes = [440, 523.25, 659.25, 523.25] // A4, C5, E5, C5
      const noteDuration = 0.5
      
      notes.forEach((freq, index) => {
        const osc = this.audioContext.createOscillator()
        osc.type = 'sine'
        osc.frequency.value = freq
        
        const noteGain = this.audioContext.createGain()
        noteGain.connect(this.audioContext.destination)
        noteGain.gain.value = 0
        
        osc.connect(noteGain)
        
        const startTime = this.audioContext.currentTime + index * noteDuration
        
        // Fade in
        noteGain.gain.setValueAtTime(0, startTime)
        noteGain.gain.linearRampToValueAtTime(0.1, startTime + 0.1)
        
        // Fade out
        noteGain.gain.linearRampToValueAtTime(0.1, startTime + noteDuration - 0.1)
        noteGain.gain.linearRampToValueAtTime(0, startTime + noteDuration)
        
        osc.start(startTime)
        osc.stop(startTime + noteDuration)
      })
      
      // Loop the melody
      setTimeout(() => {
        if (this.show) {
          this.playMelody()
        }
      }, notes.length * noteDuration * 1000)
    },
    stopAudio() {
      if (this.audioContext) {
        this.audioContext.close()
        this.audioContext = null
      }
    },
    handleContinue() {
      this.stopAudio()
      this.$emit('continue')
    },
    handleNoAudio() {
      this.stopAudio()
      this.$emit('no-audio')
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.initAudio()
      } else {
        this.stopAudio()
      }
    }
  },
  beforeDestroy() {
    this.stopAudio()
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  max-width: 90%;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

p {
  color: #333;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.volume-icon {
  font-size: 3rem;
  color: var(--accent-victory-green);
  margin: 1.5rem 0;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.continue {
  background-color: var(--accent-victory-green);
  color: white;
}

.continue:hover {
  background-color: var(--secondary-background-sky-blue);
}

.no-audio {
  background-color: white;
  color: var(--accent-victory-green);
  border: 2px solid var(--accent-victory-green);
}

.no-audio:hover {
  background-color: #f8f8f8;
}
</style> 