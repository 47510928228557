<template>
  <section class="challenge">
    <div class="challenge-content">
      <div class="left-column">
        <h2 class="problem-text">Problem</h2>
      </div>
      <div class="right-column">
        <div class="slide-container">
          <transition name="slide-fade" mode="out-in">
            <div :key="currentSlide" class="slide">
              <h3 class="slide-number">{{ slides[currentSlide].number }}</h3>
              <h4 class="slide-title">{{ slides[currentSlide].title }}</h4>
              <p class="slide-description">{{ slides[currentSlide].description }}</p>
            </div>
          </transition>
        </div>
        <div class="indicator-bar">
          <div 
            v-for="(slide, index) in slides" 
            :key="index" 
            class="indicator" 
            :class="{ active: currentSlide === index }"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ChallengeSection',
  props: ['currentSlide'], // Add this if you want to control it from the parent
  data() {
    return {
      slides: [
        {
          number: '01',
          title: 'Waste',
          description: 'Everyone is rushing to implement AI. This tends to lead to projects that shouldn\'t exist that don\'t deliver real ROI.'
        },
        {
          number: '02',
          title: 'Reluctance',
          description: 'Some businesses don\'t know where to start with AI. Others have no idea where it should be applied and where it should be avoided.'
        },
        {
          number: '03',
          title: 'Cost',
          description: 'Many companies end up overpaying for experts who don\'t spend the time fully understanding their business and may not understand how AI can actually help.'
        }
      ],
      currentSlide: 0,
      lastViewedSlide: 0,
      isOnFirstSlide: false,
      isOnLastSlide: false,
      isActive: false,
    }
  },
  computed: {
    currentSlideContent() {
      return this.slides[this.currentSlide];
    }
  },
  methods: {
    setActive(active) {
      this.isActive = active;
      if (active) {
        if (this.lastViewedSlide === this.slides.length - 1) {
          this.isOnLastSlide = true;
        } else {
          this.isOnLastSlide = false;
        }
        this.isOnFirstSlide = false;
        this.currentSlide = this.lastViewedSlide;
      } else {
        this.lastViewedSlide = this.currentSlide;
      }
    },

    handleScroll(event) {
      if (!this.isActive) return false;

      const direction = event.deltaY > 0 ? 1 : -1;
      
      if (direction > 0) {
        if (this.currentSlide < this.slides.length - 1) {
          this.currentSlide++;
          this.isOnFirstSlide = false;
          return true;
        } else if (!this.isOnLastSlide) {
          this.isOnLastSlide = true;
          return true;
        } else {
          this.$emit('challenge-completed');
          return false;
        }
      } else if (direction < 0) {
        if (this.isOnLastSlide) {
          this.isOnLastSlide = false;
          return true;
        } else if (this.currentSlide > 0) {
          this.currentSlide--;
          return true;
        } else if (!this.isOnFirstSlide) {
          this.isOnFirstSlide = true;
          return true;
        } else {
          this.$emit('challenge-scroll-top');
          return false;
        }
      }

      return true;
    },

    resetSlide() {
      this.currentSlide = 0;
      this.isOnFirstSlide = false;
      this.isOnLastSlide = false;
    },

    initTouchEvents() {
      let touchStartX = 0;
      let touchStartY = 0;
      
      this.$el.addEventListener('touchstart', (e) => {
        touchStartX = e.changedTouches[0].screenX;
        touchStartY = e.changedTouches[0].screenY;
        
        // Calculate horizontal and vertical movement
        const deltaX = Math.abs(touchStartX - e.changedTouches[0].screenX);
        const deltaY = Math.abs(touchStartY - e.changedTouches[0].screenY);

        // If it's more horizontal than vertical, prevent default
        if (deltaX > deltaY) {
          e.preventDefault();
          e.stopPropagation();
        }
      }, { passive: false }); // Change to false to allow preventDefault

      this.$el.addEventListener('touchmove', (e) => {
        const deltaX = Math.abs(touchStartX - e.changedTouches[0].screenX);
        const deltaY = Math.abs(touchStartY - e.changedTouches[0].screenY);

        // If it's more horizontal than vertical, prevent default
        if (deltaX > deltaY) {
          e.preventDefault();
          e.stopPropagation();
        }
      }, { passive: false }); // Change to false to allow preventDefault

      this.$el.addEventListener('touchend', (e) => {
        const touchEndX = e.changedTouches[0].screenX;
        const touchEndY = e.changedTouches[0].screenY;
        
        const deltaX = Math.abs(touchStartX - touchEndX);
        const deltaY = Math.abs(touchStartY - touchEndY);

        // Only handle as swipe if horizontal movement is greater than vertical
        if (deltaX > deltaY) {
          e.preventDefault();
          e.stopPropagation();
          this.handleSwipe(touchStartX, touchEndX);
        }
      }, { passive: false }); // Change to false to allow preventDefault
    },

    handleSwipe(startX, endX) {
      const SWIPE_THRESHOLD = 50;
      const diff = startX - endX;

      if (Math.abs(diff) > SWIPE_THRESHOLD) {
        if (diff > 0 && this.currentSlide < this.slides.length - 1) {
          // Swipe left - next slide
          this.currentSlide++;
        } else if (diff < 0 && this.currentSlide > 0) {
          // Swipe right - previous slide
          this.currentSlide--;
        }
      }
    }
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.initTouchEvents();
    }
  }
}
</script>

<style scoped>
.challenge {
  scroll-snap-align: start;
  height: 100vh;
  overflow: hidden;
  background-color: var(--accent-sunny-yellow);
  display: flex;
  justify-content: center;
  align-items: center;
}

.challenge-content {
  display: flex;
  width: 80%;
  max-width: 1200px;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-column {
  flex: 0 0 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--accent-victory-green);
  background-color: var(--primary-background-cream);
}

.problem-text {
  font-size: 4rem;
  color: var(--accent-victory-green);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  letter-spacing: 0.5rem;
  text-transform: uppercase;
}

.right-column {
  flex: 0 0 70%;
  overflow: hidden;
  position: relative;
  padding: 2rem;
  color: var(--accent-victory-green);
  background-color: #FCF8F2;
  border-radius: 0 20px 20px 0;
}

.slide-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  
  
}

.slide {
  width: 100%;
  transition: all 0.8s ease;
}

.slide-number {
  font-size: 3rem;
  color: var(--accent-victory-green);
  margin-bottom: 0.5rem;
}

.slide-title {
  font-size: 2rem;
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
}

.slide-description {
  font-size: 1.2rem;
  color: var(--main-text-color);
  line-height: 1.6;
}

.indicator-bar {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.indicator {
  width: 4px;
  height: 40px;
  background-color: rgba(9, 57, 39, 0.3);
  border-radius: 2px;
  transition: background-color 0.6s ease, height 0.6s ease;
}

.indicator.active {
  background-color: var(--accent-victory-green);
  height: 60px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-number,
.slide-title,
.slide-description {
  transition: all 0.3s ease;
}

/* Remove these rules as they're causing the left-to-right movement
.slide-fade-enter-from .slide-number,
.slide-fade-enter-from .slide-title,
.slide-fade-enter-from .slide-description {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to .slide-number,
.slide-fade-leave-to .slide-title,
.slide-fade-leave-to .slide-description {
  transform: translateY(-20px);
  opacity: 0;
}
*/

/* ... rest of the styles remain unchanged ... */

@media (max-width: 768px) {
  .challenge {
    padding: 0;
    height: 90vh;
    margin-bottom: 1rem;
    position: relative;
  }

  .challenge-content {
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    display: flex;
  }

  .left-column {
    flex: none;
    position: absolute;
    /* top: 100px; */
    left: 0;
    width: 100%;
    border-right: none;
    border-bottom: 2px solid var(--accent-victory-green);
    padding: 0;
    z-index: 1;
    background-color: var(--accent-sunny-yellow);
  }

  .problem-text {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 2.5rem;
    letter-spacing: normal;
    margin: 10px 0;
    padding: 10px 0;
    text-align: center;
  }

  .right-column {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 80px;
    margin: 20px;
    height: 60vh;
    position: relative;
    padding-bottom: 60px;
    border-radius: 20px;
  }

  .slide-container {
    padding: 0;
    margin-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    touch-action: pan-y pinch-zoom;
    user-select: none;
    -webkit-user-select: none;
  }

  .slide {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    touch-action: pan-x pan-y;
  }

  .slide-number {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--accent-victory-green);
  }

  .slide-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: var(--accent-victory-green);
  }

  .slide-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: var(--accent-victory-green);
  }

  .indicator-bar {
    flex-direction: row;
    position: absolute;
    bottom: 40px;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    gap: 10px;
    padding: 10px;
    border-radius: 20px;
    background: transparent;
    width: auto;
    z-index: 1;
  }

  .indicator {
    width: 40px;
    height: 4px;
    background-color: rgba(9, 57, 39, 0.2);
  }

  .indicator.active {
    width: 60px;
    height: 4px;
    background-color: var(--accent-victory-green);
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .slide-fade-enter-from {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
}
</style>