<template>
  <div class="realtime-chat">
    <div class="microphone-section">
      <LottieButton :size="40" />
      <p class="dynamic-text">
        <template v-if="isInitialStep">
          Hello, and welcome! I'm your discovery agent. I'm here to understand some of the challenges facing your business. Think of this like a first discovery call with a consultant.
        </template>
        <template v-else-if="isSecondStep">
          By the end of this discovery you'll have a clear understanding of how we can help your business and what the next steps are.
          Can I take a look at your website to better understand your business?
          <div class="button-container">
            <div v-if="!showWebsiteInput" class="choice-buttons">
              <button 
                @click="handleNoWebsite"
                class="action-button"
              >
                No Thanks
              </button>
              <button 
                @click="showWebsiteInput = true"
                class="action-button"
              >
                Sure
              </button>
            </div>
            <div v-if="showWebsiteInput" class="website-input-container">
              <input 
                v-model="websiteUrl"
                type="text"
                placeholder="Paste your website url..."
                class="website-input"
              />
              <button 
                @click="handleWebsiteSubmit"
                class="action-button"
                :disabled="!websiteUrl.trim()"
              >
                Next
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="isThirdStep">
          Before we go any further, would you like to text with me like a chat bot or talk to me in real time as if I were a person?
        </template>
        <template v-else-if="isTextStep">
          Great! The way this works is that I'm going to ask you some questions about your business and all you have to do is answer. If you'd rather talk you can press the microphone at any time next to the text input.
        </template>
        <template v-else-if="isVoiceStep">
          Great! The way this works is that I'm going to ask you some questions about your business and all you have to do is answer. You can talk as much as you want and feel free to interrupt or talk over me if I start talking. Can you hear me alright?
        </template>
        <template v-else-if="isFailureStep">
          We're going to have to continue with text for now. If you can get your microphone or audio working come back and we'll try again.
        </template>
        <template v-else-if="tempFailureMessage">
          We'll have to continue with text for now. You can try the microphone again once you get your audio/microphone working.
          <div class="button-container">
            <button 
              @click="tempFailureMessage = false"
              class="action-button"
            >
              Next
            </button>
          </div>
        </template>
        <template v-else-if="isNameStep">
          First, can you tell me your first and last name as well as your company name?
        </template>
        <template v-else-if="isRoleStep">
          Great, and what's your role at the company?
        </template>
        <template v-else-if="isPlanIntroStep">
          Thanks {{ firstName }}! As you can see on the right I'm going to build a custom plan in real time as we go. You can see what step we're on to the left and you can also tell me anything you'd like me to edit.
        </template>
        <template v-else-if="isReadyStep">
          Are you ready to get started?
        </template>
        <template v-else-if="isChallengeStep">
          Could you tell me about the biggest challenge you're facing in your business right now?
        </template>
        <template v-else-if="isChallengeDurationStep">
          How long have you been dealing with this challenge?
        </template>
        <template v-else-if="isGoalsStep">
          What are the top 2–3 goals you're aiming to achieve in the next 3–6 months? For example, are you looking to cut costs, boost revenue, improve customer experience, or streamline operations?
        </template>
        <template v-else-if="isPreviousAttemptsStep">
          What have you tried so far to address these challenges or reach these goals? And what's worked or not worked?
        </template>
        <template v-else-if="isAffectedDepartmentsStep">
          Which department(s) do you feel is most affected by these challenges? We mainly work with Customer Service, Sales, Inventory Management, Operations, Finance, or Marketing.
        </template>
        <template v-else-if="isBenefitingDepartmentsStep">
          What would be an ideal outcome for this department? If you change one thing, what would you like to see?
        </template>
        <template v-else-if="isSummaryStep">
          Thanks for taking the time to walk me through this. Let's summarize.
        </template>
        <template v-else>
          {{ lastMessage || 'Click the microphone to start speaking' }}
        </template>
      </p>
      <div class="button-container">
        <button 
          v-if="isInitialStep"
          @click="handleGetStarted"
          class="action-button"
        >
          Get Started
        </button>
        
        <template v-if="isThirdStep">
          <div class="choice-buttons">
            <button 
              @click="handleChoice('text')"
              class="action-button"
            >
              Text
            </button>
            <button 
              @click="handleChoice('voice')"
              class="action-button"
            >
              Voice
            </button>
          </div>
        </template>

        <button 
          v-if="isTextStep || isFailureStep || isPlanIntroStep"
          @click="handleContinueToName"
          class="action-button"
        >
          Next
        </button>

        <button 
          v-if="isReadyStep && !isVoiceMode"
          @click="handleReadyNext"
          class="action-button"
        >
          Next
        </button>
      </div>
    </div>
    <div class="input-section" v-if="showInputSection">
      <div class="input-container">
        <div class="input-row">
        <textarea 
          ref="textarea"
          v-model="textInput"
          placeholder="Or type your message here..."
          @keydown.enter.prevent="handleEnterPress"
          @input="adjustTextareaHeight"
          class="text-input"
          rows="1"
        ></textarea>
          <div class="input-controls">
            <button class="control-button" @click="handleMicrophoneClick">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 19V23" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 23H16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button class="control-button" @click="handleTextSubmit" :disabled="!textInput.trim()">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2L11 13M22 2L15 22L11 13L2 9L22 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <AudioCheckModal 
      :show="showAudioCheck"
      @continue="handleAudioSuccess"
      @no-audio="handleAudioFailure"
    />
    <MicrophoneCheckModal 
      :show="showMicrophoneCheck"
      @continue="handleMicrophoneSuccess"
      @no-microphone="handleMicrophoneFailure"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LottieButton from './LottieButton.vue'
import AudioCheckModal from './AudioCheckModal.vue'
import MicrophoneCheckModal from './MicrophoneCheckModal.vue'
import { sendDiscoveryResponse } from '@/services/api'

export default {
  name: 'RealtimeChat',
  components: {
    LottieButton,
    AudioCheckModal,
    MicrophoneCheckModal
  },
  data() {
    return {
      textInput: '',
      showAudioCheck: false,
      showMicrophoneCheck: false,
      previousMessage: '',
      isFromMicButton: false,
      tempFailureMessage: false,
      showWebsiteInput: false,
      websiteUrl: ''
    }
  },
  computed: {
    ...mapGetters('chatStore', [
      'lastMessage',
      'currentStep',
      'currentSubStep',
      'getStepAnswer'
    ]),
    firstName() {
      const nameAnswer = this.getStepAnswer(1, 8)
      return nameAnswer?.first_name || ''
    },
    showInputSection() {
      // Hide input during initial steps and when in voice mode
      if (this.isInitialStep || this.isSecondStep || this.isThirdStep || 
          this.isTextStep || this.isFailureStep || this.isPlanIntroStep || 
          (this.isReadyStep && !this.isVoiceMode)) {
        return false
      }
      // Hide input when in voice mode (unless we're in failure state)
      if (this.isVoiceMode && !this.tempFailureMessage && !this.isFailureStep) {
        return false
      }
      return true
    },
    isInitialStep() {
      return this.currentStep === 1 && this.currentSubStep === 1
    },
    isSecondStep() {
      return this.currentStep === 1 && this.currentSubStep === 2
    },
    isThirdStep() {
      return this.currentStep === 1 && this.currentSubStep === 3
    },
    isTextStep() {
      return this.currentStep === 1 && this.currentSubStep === 5
    },
    isVoiceStep() {
      return this.currentStep === 1 && this.currentSubStep === 6
    },
    isFailureStep() {
      return this.currentStep === 1 && this.currentSubStep === 7
    },
    isNameStep() {
      return this.currentStep === 1 && this.currentSubStep === 8
    },
    isRoleStep() {
      return this.currentStep === 1 && this.currentSubStep === 9
    },
    isPlanIntroStep() {
      return this.currentStep === 1 && this.currentSubStep === 10
    },
    isReadyStep() {
      return this.currentStep === 1 && this.currentSubStep === 11
    },
    isChallengeStep() {
      return this.currentStep === 2 && this.currentSubStep === 1
    },
    isChallengeDurationStep() {
      return this.currentStep === 2 && this.currentSubStep === 2
    },
    isGoalsStep() {
      return this.currentStep === 2 && this.currentSubStep === 3
    },
    isPreviousAttemptsStep() {
      return this.currentStep === 2 && this.currentSubStep === 4
    },
    isAffectedDepartmentsStep() {
      return this.currentStep === 3 && this.currentSubStep === 1
    },
    isBenefitingDepartmentsStep() {
      return this.currentStep === 3 && this.currentSubStep === 2
    },
    isSummaryStep() {
      return this.currentStep === 4 && this.currentSubStep === 1
    },
    isVoiceMode() {
      return this.getStepAnswer(1, 3) === 'voice'
    }
  },
  methods: {
    ...mapActions('chatStore', [
      'setLastMessage',
      'setActiveSubStep',
      'setSubStepAnswer',
      'setActiveStep'
    ]),
    async handleTextSubmit() {
      if (this.textInput.trim()) {
        const userText = this.textInput.trim()
        console.log('📤 Submitting text:', userText)
        console.log('Current step/substep:', this.currentStep, this.currentSubStep)

        // Store current step/substep for API call
        const currentStep = this.currentStep
        const currentSubStep = this.currentSubStep

        // Clear input and adjust height immediately
        this.textInput = ''
        this.$nextTick(() => {
          this.adjustTextareaHeight()
        })

        // Update UI immediately
        this.setLastMessage(userText)
        console.log('⏭️ Progressing to next step')
        this.progressToNextStep()

        // Make API call in background
        try {
          console.log('📚 Getting conversation history')
          const conversationHistory = this.getConversationHistory()

          console.log('🚀 Sending to API endpoint')
          sendDiscoveryResponse(
            currentStep,
            currentSubStep,
            userText,
            conversationHistory
          ).then(response => {
            console.log('📥 Received API response:', response)
            console.log('💾 Dispatching to store')
            return this.$store.dispatch('chatStore/handleAsyncResponse', {
              step: currentStep,
              subStep: currentSubStep,
              text: userText,
              response
            })
          }).then(() => {
            console.log('✅ Store update complete')
          }).catch(error => {
            console.error('❌ Error in API call:', error)
            // Update store with raw text on error
            this.$store.dispatch('chatStore/handleAsyncResponse', {
              step: currentStep,
              subStep: currentSubStep,
              text: userText,
              response: { success: false, error: error.message }
            })
          })
        } catch (error) {
          console.error('❌ Error in handleTextSubmit:', error)
        }
      }
    },

    progressToNextStep() {
      if (this.currentStep === 1) {
        if (this.currentSubStep === 8) {
          this.setActiveSubStep(9)
        } else if (this.currentSubStep === 9) {
          this.setActiveSubStep(10)
        } else if (this.currentSubStep === 10) {
          this.setActiveSubStep(11)
        } else if (this.currentSubStep === 11) {
          this.setActiveStep(2)
          this.setActiveSubStep(1)
        }
      } else if (this.currentStep === 2) {
        if (this.currentSubStep < 4) {
          this.setActiveSubStep(this.currentSubStep + 1)
        } else {
          this.setActiveStep(3)
          this.setActiveSubStep(1)
        }
      } else if (this.currentStep === 3) {
        if (this.currentSubStep < 2) {
          this.setActiveSubStep(this.currentSubStep + 1)
        } else {
          this.setActiveStep(4)
          this.setActiveSubStep(1)
        }
      }
    },
    getConversationHistory() {
      // Get all previous answers and format them as conversation history
      const history = []
      
      console.log('\n=== Building Conversation History ===')
      console.log('Current Step:', this.currentStep)
      console.log('Current Substep:', this.currentSubStep)
      
      // Add system messages and user responses in order
      if (this.currentStep >= 1) {
        console.log('\n--- Step 1 History ---')
        if (this.currentSubStep >= 8 || this.currentStep > 1) {
          const nameInfo = this.getStepAnswer(1, 8)
          console.log('Name Info:', nameInfo)
          history.push({
            role: "assistant",
            content: "What's your name and company name?"
          })
          if (nameInfo) {
            history.push({
              role: "user",
              content: nameInfo
            })
          }
        }
        if (this.currentSubStep >= 9 || this.currentStep > 1) {
          const roleInfo = this.getStepAnswer(1, 9)
          console.log('Role Info:', roleInfo)
          history.push({
            role: "assistant", 
            content: "What's your role at the company?"
          })
          if (roleInfo) {
            history.push({
              role: "user",
              content: roleInfo
            })
          }
        }
      }

      if (this.currentStep >= 2) {
        console.log('\n--- Step 2 History ---')
        // Challenge description
        const challengeInfo = this.getStepAnswer(2, 1)
        console.log('Challenge Info:', challengeInfo)
        history.push({
          role: "assistant",
          content: "What's the main challenge you're trying to solve?"
        })
        if (challengeInfo) {
          history.push({
            role: "user",
            content: challengeInfo
          })
        }

        if (this.currentSubStep >= 2 || this.currentStep > 2) {
          // Duration
          const durationInfo = this.getStepAnswer(2, 2)
          console.log('Duration Info:', durationInfo)
          history.push({
            role: "assistant",
            content: "How long has this been a challenge?"
          })
          if (durationInfo) {
            history.push({
              role: "user",
              content: durationInfo
            })
          }
        }

        if (this.currentSubStep >= 3 || this.currentStep > 2) {
          // Goals
          const goalsInfo = this.getStepAnswer(2, 3)
          console.log('Goals Info:', goalsInfo)
          history.push({
            role: "assistant",
            content: "What are your top 2-3 goals for the next 3-6 months?"
          })
          if (goalsInfo) {
            history.push({
              role: "user",
              content: goalsInfo
            })
          }
        }

        if (this.currentSubStep >= 4 || this.currentStep > 2) {
          // Previous attempts
          const attemptsInfo = this.getStepAnswer(2, 4)
          console.log('Previous Attempts Info:', attemptsInfo)
          history.push({
            role: "assistant",
            content: "What solutions have you tried before?"
          })
          if (attemptsInfo) {
            history.push({
              role: "user",
              content: attemptsInfo
            })
          }
        }
      }

      if (this.currentStep >= 3) {
        console.log('\n--- Step 3 History ---')
        // Department selection
        const departmentInfo = this.getStepAnswer(3, 1)
        console.log('Department Info:', departmentInfo)
        history.push({
          role: "assistant",
          content: "Which department(s) do you feel is most affected by these challenges? We mainly work with Customer Service, Sales, Inventory Management, Operations, Finance, or Marketing."
        })
        if (departmentInfo) {
          history.push({
            role: "user",
            content: {
              departments: departmentInfo.departments,
              impact_summary: departmentInfo.impact_summary
            }
          })
        }

        if (this.currentSubStep >= 2) {
          // Department outcome
          const outcomeInfo = this.getStepAnswer(3, 2)
          console.log('Outcome Info:', outcomeInfo)
          history.push({
            role: "assistant",
            content: "What would be an ideal outcome for this department? If you change one thing, what would you like to see?"
          })
          if (outcomeInfo) {
            history.push({
              role: "user",
              content: outcomeInfo
            })
          }
        }
      }

      console.log('\n=== Final Conversation History ===')
      console.log(JSON.stringify(history, null, 2))
      console.log('=====================================\n')

      return history
    },
    adjustTextareaHeight() {
      const textarea = this.$refs.textarea
      if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      }
    },
    handleGetStarted() {
      this.setActiveSubStep(2)
    },
    handleNext() {
      this.setActiveSubStep(3)
    },
    handleChoice(choice) {
      this.setSubStepAnswer({ step: 1, subStep: 3, answer: choice })
      if (choice === 'text') {
        this.setActiveSubStep(5)
      } else {
        this.isFromMicButton = false
        this.showAudioCheck = true
      }
    },
    handleAudioSuccess() {
      this.showAudioCheck = false
      this.showMicrophoneCheck = true
    },
    handleAudioFailure() {
      this.showAudioCheck = false
      if (this.isFromMicButton) {
        this.tempFailureMessage = true
      } else {
      this.setActiveSubStep(7)
      }
    },
    handleMicrophoneSuccess() {
      this.showMicrophoneCheck = false
      if (this.isFromMicButton) {
        // Return to previous state, but now in voice mode
      } else {
      this.setActiveSubStep(6)
      }
    },
    handleMicrophoneFailure() {
      this.showMicrophoneCheck = false
      if (this.isFromMicButton) {
        this.tempFailureMessage = true
      } else {
      this.setActiveSubStep(7)
      }
    },
    handleContinueToName() {
      if (this.isPlanIntroStep) {
        this.setActiveSubStep(11) // Go to ready step
      } else {
        this.setActiveSubStep(8) // Go to name step
      }
    },
    handleEnterPress(event) {
      if (event.shiftKey) {
        // Allow the new line to be added
        const start = event.target.selectionStart
        const end = event.target.selectionEnd
        this.textInput = this.textInput.substring(0, start) + '\n' + this.textInput.substring(end)
        this.$nextTick(() => {
          event.target.selectionStart = event.target.selectionEnd = start + 1
          this.adjustTextareaHeight()
        })
      } else if (this.textInput.trim()) {
        // Submit the message
        this.handleTextSubmit()
      }
    },
    handleMicrophoneClick() {
      this.isFromMicButton = true
      this.showAudioCheck = true
    },
    handleReadyNext() {
      this.setActiveStep(2)
      this.setActiveSubStep(1)
    },
    handleNoWebsite() {
      this.setActiveSubStep(3)
    },
    handleWebsiteSubmit() {
      if (this.websiteUrl.trim()) {
        console.log('Website URL submitted:', this.websiteUrl.trim())
        this.websiteUrl = ''
        this.showWebsiteInput = false
        this.setActiveSubStep(3)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.adjustTextareaHeight()
    })
  },
  watch: {
    showInputSection(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const textarea = this.$refs.textarea
          if (textarea) {
            // Force an initial height
            textarea.style.height = '2.5rem'
            // Then adjust based on content
    this.adjustTextareaHeight()
          }
          textarea?.focus()
        })
      }
    }
  }
}
</script>

<style scoped>
.realtime-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.microphone-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  padding: 1rem;
  max-height: 70vh;
}

.dynamic-text {
  color: #333;
  font-size: 1rem;
  text-align: center;
  min-height: 1.5rem;
  max-width: 80%;
  line-height: 1.5;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  /* margin-top: 2.5rem; */
}

.choice-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
}

.action-button {
  padding: 0.75rem 2rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 8rem;
}

.action-button:hover {
  background-color: var(--secondary-background-sky-blue);
}

.input-section {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background-color: #FCF8F2;
  border-top: 1px solid #E5E5E5;
  max-height: 50vh;
  overflow-y: auto;
}

.input-container {
  max-width: 90%;
  margin: 0 auto;
}

.input-row {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.input-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.control-button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: var(--accent-victory-green);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  padding: 0;
}

.control-button:hover {
  background-color: var(--secondary-background-sky-blue);
}

.control-button:disabled {
  background-color: #CCCCCC;
  cursor: not-allowed;
}

.control-button svg {
  width: 24px;
  height: 24px;
}

.control-button:disabled svg {
  opacity: 0.7;
}

.text-input {
  flex: 1;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: none;
  padding: 0.75rem 1.25rem;
  border: 2px solid var(--accent-victory-green);
  border-radius: 1.5rem;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: white;
  resize: none;
  overflow: hidden;
  line-height: 1.5;
  font-family: inherit;
}

.text-input:focus {
  border-color: var(--secondary-background-sky-blue);
}

.website-input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.5rem;
  width: 100%;
  max-width: 400px;
  align-items: center;
}

.website-input {
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1.25rem;
  border: 1px solid var(--accent-victory-green);
  border-radius: 1.5rem;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: white;
}

.website-input:focus {
  border-color: var(--secondary-background-sky-blue);
}

.website-input-container .action-button {
  padding: 0.5rem 2rem;
  height: 2.75rem;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(5, 56, 37);
  margin-top: 0.5rem;
}

.website-input-container .action-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}
</style> 