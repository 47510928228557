// Store module for managing departments that contain swimlanes
// This is separate from the swimlane departments which are the vertical lanes within a swimlane chart
import { getUserDepartments, fetchDepartmentProcesses, createDepartment as createDepartmentApi } from '@/services/api'

const state = {
  departments: [],  // Will be populated from user document
  selectedDepartmentId: null,
  departmentProcesses: {}, // Map of department ID to array of processes
  currentDepartmentProcesses: [] // Processes for the currently selected department
}

const getters = {
  getAllDepartments: state => state.departments,
  getSelectedDepartment: state => state.departments.find(dept => dept.id === state.selectedDepartmentId),
  getDepartmentById: state => id => state.departments.find(dept => dept.id === id),
  getCurrentDepartmentProcesses: state => state.currentDepartmentProcesses
}

const mutations = {
  SET_DEPARTMENTS(state, departments) {
    console.log('[Departments Store] Setting departments:', departments)
    console.log('[Departments Store] First department:', departments[0], typeof departments[0])
    state.departments = departments
    // If we have departments but no selection, select the first one
    if (departments.length > 0 && !state.selectedDepartmentId) {
      const firstDeptId = typeof departments[0] === 'string' ? departments[0] : departments[0].id
      state.selectedDepartmentId = firstDeptId
      console.log('[Departments Store] Auto-selecting first department:', firstDeptId)
    }
  },
  ADD_DEPARTMENT(state, department) {
    state.departments.push(department)
    // Initialize empty processes array for new department
    state.departmentProcesses[department.id] = []
  },
  SET_SELECTED_DEPARTMENT(state, departmentId) {
    console.log('[Departments Store] Setting selected department:', departmentId)
    state.selectedDepartmentId = departmentId
    // Update current processes when department changes
    state.currentDepartmentProcesses = state.departmentProcesses[departmentId] || []
  },
  SET_DEPARTMENT_PROCESSES(state, { departmentId, processes }) {
    console.log('[Departments Store] Setting processes for department:', { departmentId, processes })
    state.departmentProcesses[departmentId] = processes
    // If this is the currently selected department, update currentDepartmentProcesses
    if (departmentId === state.selectedDepartmentId) {
      state.currentDepartmentProcesses = processes
    }
  },
  UPDATE_DEPARTMENT(state, { id, updates }) {
    const index = state.departments.findIndex(dept => dept.id === id)
    if (index !== -1) {
      state.departments[index] = { ...state.departments[index], ...updates }
    }
  },
  REMOVE_DEPARTMENT(state, departmentId) {
    state.departments = state.departments.filter(dept => dept.id !== departmentId)
    // Clean up processes for removed department
    delete state.departmentProcesses[departmentId]
    // If we removed the selected department, select the first available one
    if (state.selectedDepartmentId === departmentId && state.departments.length > 0) {
      state.selectedDepartmentId = state.departments[0].id
      state.currentDepartmentProcesses = state.departmentProcesses[state.departments[0].id] || []
    }
  }
}

const actions = {
  async fetchUserDepartments({ commit, dispatch }, userId) {
    try {
      console.log('[Departments Store] Fetching departments for user:', userId)
      const departments = await getUserDepartments(userId)
      console.log('[Departments Store] Got departments from API:', departments)
      console.log('[Departments Store] Department data type:', typeof departments[0])
      
      // Convert string departments to objects if needed
      const formattedDepartments = departments.map(dept => 
        typeof dept === 'string' ? { id: dept, name: dept.toUpperCase().replace('_', ' ') } : dept
      )
      console.log('[Departments Store] Formatted departments:', formattedDepartments)
      
      commit('SET_DEPARTMENTS', formattedDepartments)
      // If we have departments, fetch processes for the selected one
      if (formattedDepartments.length > 0) {
        const firstDepartmentId = formattedDepartments[0].id
        console.log('[Departments Store] Fetching processes for first department:', firstDepartmentId)
        await dispatch('fetchDepartmentProcesses', firstDepartmentId)
      }
    } catch (error) {
      console.error('[Departments Store] Error fetching user departments:', error)
      // Set default departments if API call fails
      const defaultDepartments = [
        { id: 'sales_dept', name: 'SALES DEPARTMENT' },
        { id: 'marketing_dept', name: 'MARKETING DEPARTMENT' },
        { id: 'hr_dept', name: 'HUMAN RESOURCES' }
      ]
      console.log('[Departments Store] Setting default departments:', defaultDepartments)
      commit('SET_DEPARTMENTS', defaultDepartments)
      await dispatch('fetchDepartmentProcesses', defaultDepartments[0].id)
    }
  },

  async fetchDepartmentProcesses({ commit, dispatch }, departmentId) {
    try {
      console.log('[Departments Store] Fetching processes for department:', departmentId)
      const processes = await fetchDepartmentProcesses(departmentId)
      console.log('[Departments Store] Got processes from API:', processes)
      commit('SET_DEPARTMENT_PROCESSES', { departmentId, processes })
      
      // Save each process to the swimlane store
      for (const process of processes) {
        await dispatch('swimlane/setProcessData', {
          processId: process.id,
          processData: process
        }, { root: true })
      }
    } catch (error) {
      console.error('[Departments Store] Error fetching department processes:', error)
      throw error
    }
  },

  async createDepartment({ commit, rootState }, { name }) {
    try {
      const userId = rootState.swimlane.currentUserId
      if (!userId) {
        throw new Error('No user ID found')
      }

      // Create department in database first
      const newDepartment = await createDepartmentApi(userId, {
        name: name.toUpperCase(),
        processes: []
      })

      // Add to local state after successful API call
      commit('ADD_DEPARTMENT', newDepartment)
      return newDepartment
    } catch (error) {
      console.error('Error creating department:', error)
      throw error
    }
  },

  async selectDepartment({ commit, dispatch, state }, departmentId) {
    commit('SET_SELECTED_DEPARTMENT', departmentId)
    // Fetch processes for newly selected department if we haven't already
    if (!state.departmentProcesses[departmentId]) {
      await dispatch('fetchDepartmentProcesses', departmentId)
    }
  },

  updateDepartment({ commit }, { id, updates }) {
    commit('UPDATE_DEPARTMENT', { id, updates })
  },

  removeDepartment({ commit, state }, departmentId) {
    // Don't allow removing the last department
    if (state.departments.length > 1) {
      commit('REMOVE_DEPARTMENT', departmentId)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} 