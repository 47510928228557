import api from '@/services/api'

export default {
  namespaced: true,

  state: {
    gridSize: 200, // Size of each grid cell in pixels
    occupiedCells: new Map(),
    swimlanePositions: new Map()
  },

  getters: {
    isPositionOccupied: (state) => (x, y) => {
      return state.occupiedCells.has(`${x},${y}`)
    },

    getSwimlaneBounds: (state) => (swimlaneId) => {
      return state.swimlanePositions.get(swimlaneId)
    },

    // Get all occupied positions for visualization/debugging
    getAllOccupiedPositions: (state) => {
      return Array.from(state.occupiedCells.entries()).map(([key, value]) => {
        const [x, y] = key.split(',').map(Number)
        return { x, y, swimlaneId: value }
      })
    }
  },

  mutations: {
    SET_SWIMLANE_POSITION(state, { swimlaneId, position, size }) {
      state.swimlanePositions[swimlaneId] = {
        position: { ...position },
        size: { ...size }
      }
    },

    REMOVE_SWIMLANE(state, swimlaneId) {
      const { [swimlaneId]: removed, ...remaining } = state.swimlanePositions;
      state.swimlanePositions = remaining;
    },

    UPDATE_OCCUPIED_CELLS(state) {
      // Clear existing occupied cells
      state.occupiedCells.clear()

      // Rebuild occupied cells from swimlane positions
      state.swimlanePositions.forEach((data, swimlaneId) => {
        const { position, size } = data
        // console.log('[Grid Store] Updating occupied cells for swimlane:', {
        //   swimlaneId,
        //   position,
        //   size
        // })
        for (let x = position.x; x < position.x + size.width; x++) {
          for (let y = position.y; y < position.y + size.height; y++) {
            state.occupiedCells.set(`${x},${y}`, swimlaneId)
          }
        }
      })
      
      // console.log('[Grid Store] Updated occupied cells:', 
      //   Array.from(state.occupiedCells.entries()).map(([key, value]) => ({
      //     position: key,
      //     swimlaneId: value
      //   }))
      // )
    },

    CLEAR_GRID(state) {
      state.swimlanePositions = {}
    }
  },

  actions: {
    async updateProcessGrid({ commit }, { processId, position, size }) {
      try {
        await api.updateProcessGrid(processId, {
          position: {
            x: position.x,
            y: position.y
          },
          size: {
            width: size.width,
            height: size.height
          }
        })

        // console.log('NOW THIS HERE')
        // console.log('POSITION', position)
        // console.log('SIZE', size)
        
        commit('SET_SWIMLANE_POSITION', {
          swimlaneId: processId,
          position,
          size
        })
      } catch (error) {
        console.error('Failed to update process grid:', error)
        throw error
      }
    },
    
    async loadDepartmentGrid({ commit }, departmentId) {
      try {
        const { data } = await api.getDepartmentGrid(departmentId)
        // Clear existing grid data first
        commit('CLEAR_GRID')
        // Load new grid data
        data.forEach(process => {
          commit('SET_SWIMLANE_POSITION', {
            swimlaneId: process.id,
            position: process.gridPosition,
            size: process.gridSize
          })
        })
      } catch (error) {
        console.error('Failed to load department grid:', error)
        throw error
      }
    },

    // Find next available position in grid
    findAvailablePosition({ state, getters }) {
      // Start from 0,0 and spiral out until we find an empty spot
      let x = 0
      let y = 0
      let layer = 1
      let leg = 0
      
      while (true) {
        if (!getters.isPositionOccupied(x, y)) {
          return { x, y }
        }

        // Move in a spiral pattern
        switch (leg) {
          case 0: x++; if (x === layer) leg++; break    // right
          case 1: y++; if (y === layer) leg++; break    // down
          case 2: x--; if (-x === layer) leg++; break   // left
          case 3: y--; if (-y === layer) { leg = 0; layer++; } break // up
        }
      }
    }
  }
} 