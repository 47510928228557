<template>
  <div>
    <button class="chat-button" @click="toggleChat">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
    </button>
    
    <div v-if="isOpen" class="chat-dialog">
      <div class="chat-header">
        <h3>Chat</h3>
        <button class="close-button" @click="toggleChat">×</button>
      </div>
      <div class="chat-content">
        <!-- Chat content will go here -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatDialog',
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleChat() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
.chat-button {
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  background: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.chat-button:hover {
  background: var(--secondary-background-sky-blue);
}

.chat-dialog {
  position: fixed;
  bottom: 80px;
  width: 300px;
  height: 400px;
  background: var(--primary-background-cream);
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 12px 16px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h3 {
  margin: 0;
  font-size: 16px;
  color: var(--primary-background-cream);
}

.close-button {
  background: none;
  border: none;
  color: var(--primary-background-cream);
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.chat-content {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background: var(--primary-background-cream);
  color: var(--main-text-color);
}
</style> 