<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Select Node to Connect</h3>
        <button class="close-btn" @click="$emit('close')">&times;</button>
      </div>
      <div class="modal-body">
        <div class="node-list">
          <template v-if="availableNodes.length > 0">
            <template v-for="group in availableNodes" :key="group.type">
              <!-- Decision group -->
              <template v-if="group.type === 'decision'">
                <div class="decision-group">
                  <div class="decision-header">
                    <span class="decision-icon">❓</span>
                    {{ group.node.title }}
                  </div>
                  <div class="decision-branches">
                    <!-- Yes branch -->
                    <div class="branch-section">
                      <div class="branch-header yes-branch">
                        <span class="branch-icon">✓</span>
                        Yes Path
                      </div>
                      <div class="branch-nodes">
                        <button
                          v-for="node in group.branches.yes.nodes"
                          :key="node.id"
                          class="node-option branch-node"
                          @click="selectNode(node)"
                        >
                          <div class="node-content">
                            <span class="node-icon">
                              {{ getNodeIcon(node) }}
                            </span>
                            <div class="node-details">
                              <span class="node-title">{{ node.title }}</span>
                              <span class="node-type">{{ getNodeType(node) }}</span>
                            </div>
                          </div>
                          <span class="node-arrow">→</span>
                        </button>
                      </div>
                    </div>
                    
                    <!-- No branch -->
                    <div class="branch-section">
                      <div class="branch-header no-branch">
                        <span class="branch-icon">✗</span>
                        No Path
                      </div>
                      <div class="branch-nodes">
                        <button
                          v-for="node in group.branches.no.nodes"
                          :key="node.id"
                          class="node-option branch-node"
                          @click="selectNode(node)"
                        >
                          <div class="node-content">
                            <span class="node-icon">
                              {{ getNodeIcon(node) }}
                            </span>
                            <div class="node-details">
                              <span class="node-title">{{ node.title }}</span>
                              <span class="node-type">{{ getNodeType(node) }}</span>
                            </div>
                          </div>
                          <span class="node-arrow">→</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Standalone nodes -->
              <template v-else-if="group.type === 'standalone'">
                <div class="standalone-section">
                  <div class="section-header">
                    <span class="section-icon">📍</span>
                    Main Path
                  </div>
                  <button
                    v-for="node in group.nodes"
                    :key="node.id"
                    class="node-option"
                    @click="selectNode(node)"
                  >
                    <div class="node-content">
                      <span class="node-icon">
                        {{ getNodeIcon(node) }}
                      </span>
                      <div class="node-details">
                        <span class="node-title">{{ node.title }}</span>
                        <span class="node-type">{{ getNodeType(node) }}</span>
                      </div>
                    </div>
                    <span class="node-arrow">→</span>
                  </button>
                </div>
              </template>
            </template>
          </template>
          <div v-else class="empty-state">
            <span class="empty-icon">⚠️</span>
            <p class="empty-message">No available nodes to connect to</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeSelectionModal',
  props: {
    nodes: {
      type: Array,
      required: true
    },
    sourceNodeId: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    processId: {
      type: String,
      required: true
    }
  },
  computed: {
    flowData() {
      return this.$store.getters['swimlane/getProcessFlowData'](this.processId)
    },
    validNodes() {
      if (!this.flowData) return []
      
      const existingConnections = this.flowData.connections || []
      return this.nodes.filter(node => {
        // Don't allow connecting to self
        if (node.id === this.sourceNodeId) return false
        
        // Check if connection already exists
        const hasConnection = existingConnections.some(conn => 
          (conn.source === this.sourceNodeId && conn.target === node.id) ||
          (conn.target === this.sourceNodeId && conn.source === node.id)
        )
        
        return !hasConnection
      })
    },
    availableNodes() {
      // Get the actual source node from the flow data
      const sourceNode = this.flowData?.nodes?.find(n => n.id === this.sourceNodeId);
      if (!sourceNode) return [];

      console.log('Source node:', sourceNode);

      // First, try to find if source is in a decision branch
      const decision = this.flowData?.nodes?.find(n => {
        if (n.type !== 'decision') return false;
        
        const yesNode = this.flowData.nodes.find(yn => 
          yn.title === 'Yes' && 
          this.isConnectedToDecision(n.id, yn.id)
        );
        const noNode = this.flowData.nodes.find(nn => 
          nn.title === 'No' && 
          this.isConnectedToDecision(n.id, nn.id)
        );

        console.log('Checking decision:', n.title);
        console.log('Yes node:', yesNode);
        console.log('No node:', noNode);

        const yesBranchNodes = this.getBranchNodes(yesNode);
        const noBranchNodes = this.getBranchNodes(noNode);

        console.log('Yes branch nodes:', yesBranchNodes);
        console.log('No branch nodes:', noBranchNodes);

        const isInYesBranch = yesBranchNodes.some(bn => bn.id === sourceNode.id);
        const isInNoBranch = noBranchNodes.some(bn => bn.id === sourceNode.id);

        console.log('Source in Yes branch?', isInYesBranch);
        console.log('Source in No branch?', isInNoBranch);

        return isInYesBranch || isInNoBranch;
      });

      console.log('Found decision:', decision);

      // If source is in a decision branch, show the opposite branch nodes
      if (decision) {
        const yesNode = this.flowData.nodes.find(n => 
          n.title === 'Yes' && 
          this.isConnectedToDecision(decision.id, n.id)
        );
        const noNode = this.flowData.nodes.find(n => 
          n.title === 'No' && 
          this.isConnectedToDecision(decision.id, n.id)
        );

        const yesBranchNodes = this.getBranchNodes(yesNode);
        const noBranchNodes = this.getBranchNodes(noNode);

        // If source is in Yes branch, show No branch nodes
        if (yesBranchNodes.some(n => n.id === sourceNode.id)) {
          const validNodes = noBranchNodes.filter(n => 
            n.id !== sourceNode.id && 
            n.title !== 'Yes' && 
            n.title !== 'No'
          );

          console.log('Source in Yes branch, showing No branch nodes:', validNodes);

          if (validNodes.length > 0) {
            return [{
              type: 'standalone',
              nodes: validNodes
            }];
          }
        }

        // If source is in No branch, show Yes branch nodes
        if (noBranchNodes.some(n => n.id === sourceNode.id)) {
          const validNodes = yesBranchNodes.filter(n => 
            n.id !== sourceNode.id && 
            n.title !== 'Yes' && 
            n.title !== 'No'
          );

          console.log('Source in No branch, showing Yes branch nodes:', validNodes);

          if (validNodes.length > 0) {
            return [{
              type: 'standalone',
              nodes: validNodes
            }];
          }
        }
      } else {
        // Source is not in any branch, show nodes between most recent decision and current node
        console.log('Source is not in any branch, finding valid nodes');
        
        // Find the most recent decision before the source node
        const decisions = this.flowData.nodes.filter(n => n.type === 'decision');
        let mostRecentDecision = null;
        let mostRecentStep = 0;

        decisions.forEach(d => {
          const match = d.title.match(/\d+/);
          if (match) {
            const step = parseInt(match[0]);
            const sourceMatch = sourceNode.title.match(/\d+/);
            const sourceStep = sourceMatch ? parseInt(sourceMatch[0]) : Infinity;

            if (step < sourceStep && step > mostRecentStep) {
              mostRecentDecision = d;
              mostRecentStep = step;
            }
          }
        });

        console.log('Most recent decision:', mostRecentDecision);

        // Extract step numbers
        const sourceMatch = sourceNode.title.match(/\d+/);
        if (!sourceMatch) return [];
        
        const sourceStep = parseInt(sourceMatch[0]);
        const minStep = mostRecentDecision ? mostRecentStep : 1;
        console.log('Source step:', sourceStep, 'Min step:', minStep);

        // Find all nodes between the most recent decision and current node
        const validNodes = this.flowData.nodes.filter(node => {
          // Skip source node itself
          if (node.id === sourceNode.id) return false;

          // Skip Yes/No nodes
          if (node.title === 'Yes' || node.title === 'No') return false;

          // Skip decision nodes when connecting to entrance
          if (this.mode === 'entrance' && node.type === 'decision') return false;

          // Skip nodes that are already connected to our source node
          console.log('Checking connections for node:', node.title);
          console.log('Source node:', sourceNode);

          // Check if there's already a connection between these nodes
          const existingConnection = this.flowData.connections?.some(conn => {
            const isMatch = (
              // For entrance connections, check if target connects to source
              (this.mode === 'entrance' && conn.source === node.id && conn.target === sourceNode.id) ||
              // For exit connections, check if source connects to target
              (this.mode === 'exit' && conn.source === sourceNode.id && conn.target === node.id)
            );

            if (isMatch) {
              console.log('Found existing connection:', conn);
            }

            return isMatch;
          });

          if (existingConnection) {
            console.log(`Node ${node.title} is already connected to ${sourceNode.title}`);
            return false;
          }

          // Extract step number from node title
          const nodeMatch = node.title.match(/\d+/);
          if (!nodeMatch) return false;

          const nodeStep = parseInt(nodeMatch[0]);
          console.log('Checking node:', node.title, 'step:', nodeStep);

          // When connecting to entrance, show nodes between most recent decision and current node
          if (this.mode === 'entrance') {
            return nodeStep >= minStep && nodeStep <= sourceStep;
          }
          
          // When connecting to exit, show nodes at later steps
          return nodeStep >= sourceStep;
        });

        console.log('Valid nodes:', validNodes);

        if (validNodes.length > 0) {
          return [{
            type: 'standalone',
            nodes: validNodes
          }];
        }
      }

      return [];
    }
  },
  methods: {
    getNodesAfterSource(sourceNode) {
      console.log('Getting nodes after source:', sourceNode.title);
      const result = [];
      const visited = new Set();

      const traverse = (node) => {
        if (!node || visited.has(node.id)) return;
        visited.add(node.id);

        // Find all nodes that could be valid targets
        this.nodes.forEach(n => {
          // Skip if it's the source node or already visited
          if (n.id === sourceNode.id || visited.has(n.id)) return;

          // Add the node if it's valid for connection
          const isValid = this.isValidNodeForConnection(n, sourceNode);
          console.log('Checking node:', n.title, 'isValid:', isValid);
          if (isValid) {
            result.push(n);
            traverse(n);
          }
        });
      };

      traverse(sourceNode);
      return result;
    },

    isValidNodeForConnection(node, sourceNode) {
      // Don't show Yes/No nodes directly
      if (node.title === 'Yes' || node.title === 'No') return false;

      // Don't allow connecting to nodes that would create cycles
      if (this.wouldCreateCycle(sourceNode, node)) return false;

      // Find the decision node that this flow belongs to
      const decision = this.findParentDecision(sourceNode);
      
      // If no decision found, allow connection
      if (!decision) return true;

      // If source is in Yes branch, allow connecting to Yes branch nodes
      if (this.isNodeInYesBranch(sourceNode, decision) && 
          this.isNodeInYesBranch(node, decision)) {
        return true;
      }

      // If source is in No branch, allow connecting to No branch nodes
      if (this.isNodeInNoBranch(sourceNode, decision) && 
          this.isNodeInNoBranch(node, decision)) {
        return true;
      }

      // Allow connecting to nodes that aren't in any branch
      const isNodeInBranch = this.isNodeInYesBranch(node, decision) || 
                            this.isNodeInNoBranch(node, decision);
      return !isNodeInBranch;
    },

    findRelevantDecision(node) {
      // First try to find a decision that this node belongs to
      const parentDecision = this.findParentDecision(node);
      if (parentDecision) return parentDecision;

      // If no parent decision found, find the most recent decision before this node
      return this.nodes.find(n => {
        if (n.type !== 'decision') return false;

        // Check if this decision is before our node in the flow
        const yesNode = this.nodes.find(yn => 
          yn.title === 'Yes' && 
          this.isConnectedToDecision(n.id, yn.id)
        );
        const noNode = this.nodes.find(nn => 
          nn.title === 'No' && 
          this.isConnectedToDecision(n.id, nn.id)
        );

        // Get all nodes in both branches
        const yesBranchNodes = this.getBranchNodes(yesNode);
        const noBranchNodes = this.getBranchNodes(noNode);

        // Check if our node comes after any of these branch nodes
        return [...yesBranchNodes, ...noBranchNodes].some(branchNode => 
          branchNode.connections?.some(conn => 
            this.isNodeInSubsequentFlow(node.id, conn.target)
          )
        );
      });
    },

    isNodeInSubsequentFlow(startNodeId, currentNodeId) {
      const visited = new Set();

      const traverse = (nodeId) => {
        if (!nodeId || visited.has(nodeId)) return false;
        if (nodeId === startNodeId) return true;
        visited.add(nodeId);

        const node = this.nodes.find(n => n.id === nodeId);
        if (!node?.connections) return false;

        return node.connections.some(conn => traverse(conn.target));
      };

      return traverse(currentNodeId);
    },

    findParentDecision(node) {
      // Find the decision node that this node belongs to
      return this.nodes.find(n => {
        if (n.type !== 'decision') return false;
        
        // Get Yes/No nodes for this decision
        const yesNode = this.nodes.find(yn => 
          yn.title === 'Yes' && 
          this.isConnectedToDecision(n.id, yn.id)
        );
        const noNode = this.nodes.find(nn => 
          nn.title === 'No' && 
          this.isConnectedToDecision(n.id, nn.id)
        );

        // Check if our node is in either branch
        const yesBranchNodes = this.getBranchNodes(yesNode);
        const noBranchNodes = this.getBranchNodes(noNode);

        return yesBranchNodes.some(bn => bn.id === node.id) ||
               noBranchNodes.some(bn => bn.id === node.id);
      });
    },

    isNodeInYesBranch(node, decisionNode) {
      const yesNode = this.nodes.find(n => 
        n.title === 'Yes' && 
        this.isConnectedToDecision(decisionNode.id, n.id)
      );
      if (!yesNode) return false;

      const yesBranchNodes = this.getBranchNodes(yesNode);
      return yesBranchNodes.some(n => n.id === node.id);
    },

    isNodeInNoBranch(node, decisionNode) {
      const noNode = this.nodes.find(n => 
        n.title === 'No' && 
        this.isConnectedToDecision(decisionNode.id, n.id)
      );
      if (!noNode) return false;

      const noBranchNodes = this.getBranchNodes(noNode);
      return noBranchNodes.some(n => n.id === node.id);
    },

    isNodeAfterDecision(node, decisionNode) {
      // Get all nodes in both branches
      const yesNode = this.nodes.find(n => 
        n.title === 'Yes' && 
        this.isConnectedToDecision(decisionNode.id, n.id)
      );
      const noNode = this.nodes.find(n => 
        n.title === 'No' && 
        this.isConnectedToDecision(decisionNode.id, n.id)
      );

      const yesBranchNodes = this.getBranchNodes(yesNode);
      const noBranchNodes = this.getBranchNodes(noNode);
      const allBranchNodes = [...yesBranchNodes, ...noBranchNodes];

      // Node is after the decision if it's not in either branch
      // and not before the decision in the flow
      return !allBranchNodes.some(n => n.id === node.id) &&
             !this.isNodeBeforeInFlow(node, decisionNode);
    },

    isNodeBeforeInFlow(node, targetNode) {
      const visited = new Set();

      const traverse = (currentNode) => {
        if (!currentNode || visited.has(currentNode.id)) return false;
        visited.add(currentNode.id);

        if (currentNode.id === targetNode.id) return true;

        // Check all connections from this node
        const connections = currentNode.connections || [];
        for (const conn of connections) {
          const nextNode = this.nodes.find(n => n.id === conn.target);
          if (traverse(nextNode)) return true;
        }

        return false;
      };

      return traverse(node);
    },

    wouldCreateCycle(sourceNode, targetNode) {
      const visited = new Set();

      const traverse = (currentNode) => {
        if (!currentNode) return false;
        if (currentNode.id === sourceNode.id) return true;
        if (visited.has(currentNode.id)) return false;

        visited.add(currentNode.id);

        // Check all connections from this node
        const connections = currentNode.connections || [];
        for (const conn of connections) {
          const nextNode = this.nodes.find(n => n.id === conn.target);
          if (traverse(nextNode)) return true;
        }

        return false;
      };

      return traverse(targetNode);
    },

    groupNodesByDecision(nodes) {
      const groups = [];
      
      // First, find all decision nodes
      const decisionNodes = nodes.filter(n => n.type === 'decision');
      
      decisionNodes.forEach(decision => {
        // Find Yes/No nodes connected to this decision
        const yesNode = this.nodes.find(n => 
          n.title === 'Yes' && 
          this.isConnectedToDecision(decision.id, n.id)
        );
        const noNode = this.nodes.find(n => 
          n.title === 'No' && 
          this.isConnectedToDecision(decision.id, n.id)
        );

        // Find nodes in Yes branch
        const yesBranchNodes = this.getBranchNodes(yesNode);
        const noBranchNodes = this.getBranchNodes(noNode);

        groups.push({
          type: 'decision',
          node: decision,
          branches: {
            yes: {
              node: yesNode,
              nodes: yesBranchNodes
            },
            no: {
              node: noNode,
              nodes: noBranchNodes
            }
          }
        });
      });

      // Add non-decision nodes that aren't part of any branch
      const standaloneNodes = nodes.filter(n => 
        n.type !== 'decision' &&
        n.title !== 'Yes' &&
        n.title !== 'No' &&
        !this.isPartOfAnyBranch(n, groups)
      );

      if (standaloneNodes.length > 0) {
        groups.push({
          type: 'standalone',
          nodes: standaloneNodes
        });
      }

      return groups;
    },

    isConnectedToDecision(decisionId, nodeId) {
      // Check if there's a direct connection from decision to node
      return this.nodes.some(n => 
        n.id === decisionId &&
        n.connections?.some(conn => conn.target === nodeId)
      );
    },

    getBranchNodes(branchNode) {
      if (!branchNode) return [];
      
      const nodes = [];
      const visited = new Set();

      const traverse = (nodeId) => {
        if (!nodeId || visited.has(nodeId)) return;
        visited.add(nodeId);

        const node = this.nodes.find(n => n.id === nodeId);
        if (!node) return;

        nodes.push(node);

        // Find next connected node
        const nextNodeId = node.connections?.find(conn => !conn.type)?.target;
        if (nextNodeId) traverse(nextNodeId);
      };

      traverse(branchNode.id);
      return nodes;
    },

    isPartOfAnyBranch(node, groups) {
      return groups.some(group => 
        group.type === 'decision' &&
        (
          group.branches.yes.nodes.some(n => n.id === node.id) ||
          group.branches.no.nodes.some(n => n.id === node.id)
        )
      );
    },

    getDecisionBranches(decisionNode) {
      // Only show decision branches when connecting to entrances
      if (this.mode === 'exit') return [];
      
      // Find the Yes and No nodes connected to this decision
      return this.nodes.filter(node => 
        (node.title === 'Yes' || node.title === 'No') &&
        this.isConnectedToDecision(decisionNode.id, node.id)
      );
    },
    selectNode(node) {
      // If it's a decision node's branch (Yes/No), we need to include the parent decision node info
      if (node.title === 'Yes' || node.title === 'No') {
        const parentDecision = this.nodes.find(n => 
          n.type === 'decision' && 
          this.getDecisionBranches(n).some(branch => branch.id === node.id)
        );
        if (parentDecision) {
          node.parentDecision = parentDecision;
        }
      }
      
      this.$emit('select', node);
      this.$emit('close');
    },
    getNodeIcon(node) {
      if (node.type === 'decision') return '❓';
      if (node.type === 'finish') return '🏁';
      if (node.title === 'Start') return '🚀';
      return '⚙️';
    },
    getNodeType(node) {
      if (node.type === 'decision') return 'Decision Node';
      if (node.type === 'finish') return 'Finish Node';
      if (node.title === 'Start') return 'Start Node';
      return 'Activity Node';
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 180px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  border-radius: 12px 0 0 12px;
  width: 400px;
  height: 100vh;
  overflow: hidden;
  box-shadow: -8px 0 32px rgba(0, 0, 0, 0.15);
  animation: modalSlideIn 0.3s ease-out;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.modal-header {
  background: var(--accent-victory-green);
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header h3 {
  color: white;
  margin: 0;
  font-size: 1.2em;
  font-weight: 500;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.modal-body {
  padding: 24px;
  height: calc(100vh - 76px);
  overflow-y: auto;
}

.node-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.node-option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  text-align: left;
}

.node-option:hover {
  background: #e9ecef;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.node-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.node-icon {
  width: 32px;
  height: 32px;
  background: var(--accent-victory-green);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2em;
}

.node-details {
  display: flex;
  flex-direction: column;
}

.node-title {
  font-weight: 500;
  color: #212529;
  margin-bottom: 4px;
}

.node-type {
  font-size: 0.9em;
  color: #6c757d;
}

.node-arrow {
  color: #adb5bd;
  font-size: 1.2em;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.2s;
}

.node-option:hover .node-arrow {
  opacity: 1;
  transform: translateX(0);
}

.decision-group {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.decision-header {
  padding: 16px;
  background: var(--accent-victory-green);
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
}

.decision-icon {
  font-size: 1.2em;
}

.decision-branches {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.branch-option {
  background: white;
  padding: 12px;
}

.branch-option:hover {
  background: #f8f9fa;
}

.branch-icon {
  width: 24px;
  height: 24px;
  background: var(--accent-victory-green);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.9em;
}

.branch-title {
  font-weight: 500;
  color: #212529;
}

/* Scrollbar styling */
.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-track {
  background: #f1f3f5;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #ced4da;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #adb5bd;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #f8f9fa;
  border-radius: 8px;
}

.empty-icon {
  font-size: 2.5em;
  margin-bottom: 16px;
}

.empty-message {
  color: #6c757d;
  font-size: 1.1em;
  margin: 0;
}

.branch-section {
  margin-bottom: 16px;
}

.branch-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  font-weight: 500;
  border-radius: 6px;
  margin-bottom: 8px;
}

.yes-branch {
  background: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.no-branch {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.branch-nodes {
  padding-left: 24px;
}

.branch-node {
  background: white;
  border-left: 2px solid #e9ecef;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  font-weight: 500;
  color: var(--accent-victory-green);
  background: rgba(0, 51, 37, 0.05);
  border-radius: 6px;
  margin-bottom: 12px;
}

.section-icon {
  font-size: 1.2em;
}

.standalone-section {
  margin-top: 24px;
}

.decision-group {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
}

.decision-header {
  padding: 16px;
  background: var(--accent-victory-green);
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
}

.decision-branches {
  padding: 16px;
}
</style>
