const initialState = {
  activeStep: 1,
  activeSubStep: 1,
  lastMessage: '',
  stepAnswers: {
    1: {},
    2: {},
    3: {}
  }
}

const state = { ...initialState }

const mutations = {
  SET_ACTIVE_STEP(state, step) {
    state.activeStep = step
  },
  SET_ACTIVE_SUB_STEP(state, subStep) {
    state.activeSubStep = subStep
  },
  SET_LAST_MESSAGE(state, message) {
    state.lastMessage = message
  },
  SET_SUB_STEP_ANSWER(state, { step, subStep, answer }) {
    if (!state.stepAnswers[step]) {
      state.stepAnswers[step] = {}
    }
    state.stepAnswers[step][subStep] = answer
  },
  RESET_STATE(state) {
    Object.assign(state, initialState)
  }
}

const actions = {
  setActiveStep({ commit }, step) {
    commit('SET_ACTIVE_STEP', step)
  },
  setActiveSubStep({ commit }, subStep) {
    commit('SET_ACTIVE_SUB_STEP', subStep)
  },
  setLastMessage({ commit }, message) {
    commit('SET_LAST_MESSAGE', message)
  },
  setSubStepAnswer({ commit }, { step, subStep, answer }) {
    commit('SET_SUB_STEP_ANSWER', { step, subStep, answer })
  },
  async handleAsyncResponse({ commit }, { step, subStep, text, response }) {
    console.log('🔄 Starting async response handler:', { step, subStep, text })
    
    try {
      if (response.success && response.data) {
        console.log('✅ Received structured API response:', response.data)
        commit('SET_SUB_STEP_ANSWER', {
          step,
          subStep,
          answer: response.data
        })
      } else {
        console.log('⚠️ No structured data in response, using raw text')
        commit('SET_SUB_STEP_ANSWER', {
          step,
          subStep,
          answer: { text }
        })
      }
    } catch (error) {
      console.error('❌ Error handling async response:', error)
      commit('SET_SUB_STEP_ANSWER', {
        step,
        subStep,
        answer: { text }
      })
    }
  },
  resetStore({ commit }) {
    commit('RESET_STATE')
  }
}

const getters = {
  currentStep: state => state.activeStep,
  currentSubStep: state => state.activeSubStep,
  lastMessage: state => state.lastMessage,
  getStepAnswer: state => (step, subStep) => {
    return state.stepAnswers[step]?.[subStep]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
} 