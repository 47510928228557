<template>
  <div class="branch-node">
    <!-- Regular node -->
    <div v-if="!node.isDecision" class="node-content">
      <span class="node-icon">
        {{ getNodeIcon(node) }}
      </span>
      <div class="node-details">
        <span class="node-title">{{ node.title }}</span>
        <div v-if="isEditing && editingNodeId === node.id" class="description-edit">
          <input
            :ref="el => { if (el) descriptionInputRef = el }"
            v-model="editedDescription"
            class="description-input"
            @blur="saveDescription"
            @keyup.enter="saveDescription"
            @keyup.esc="cancelEdit"
          />
        </div>
        <span v-else class="step-description editable" @click="startEditing(node)">
          {{ node.description || 'No description available' }}
        </span>
      </div>
    </div>
    
    <!-- Decision node -->
    <div v-else class="nested-decision">
      <div class="nested-decision-header" @click="toggleDecision(node.id)" :class="{ 'collapsed': isDecisionCollapsed(node.id) }">
        <span class="decision-icon">❓</span>
        <div class="decision-details">
          <span class="decision-title">{{ node.title }}</span>
          <div v-if="isEditing && editingNodeId === node.id" class="description-edit">
            <input
              :ref="el => { if (el) descriptionInputRef = el }"
              v-model="editedDescription"
              class="description-input"
              @blur="saveDescription"
              @keyup.enter="saveDescription"
              @keyup.esc="cancelEdit"
              @click.stop
            />
          </div>
          <span v-else class="decision-description editable" @click.stop="startEditing(node)">
            {{ node.description || 'No description available' }}
          </span>
        </div>
        <span class="collapse-icon">{{ isDecisionCollapsed(node.id) ? '▼' : '▲' }}</span>
      </div>
      <div class="nested-branches" v-show="!isDecisionCollapsed(node.id)">
        <!-- Yes branch -->
        <div class="branch-section">
          <div class="branch-header yes-branch" @click="toggleBranch(`${node.id}-yes`)" :class="{ 'collapsed': isBranchCollapsed(`${node.id}-yes`) }">
            <span class="branch-icon">✓</span>
            Yes Path
            <span class="collapse-icon">{{ isBranchCollapsed(`${node.id}-yes`) ? '▼' : '▲' }}</span>
          </div>
          <div class="branch-nodes" v-show="!isBranchCollapsed(`${node.id}-yes`)">
            <decision-branch
              v-for="branchNode in node.yesBranch"
              :key="branchNode.id"
              :node="branchNode"
              :collapsed-decisions="collapsedDecisions"
              :collapsed-branches="collapsedBranches"
              :process-id="processId"
              @toggle-decision="toggleDecision"
              @toggle-branch="toggleBranch"
              @description-updated="$emit('description-updated', $event)"
            />
          </div>
        </div>
        <!-- No branch -->
        <div class="branch-section">
          <div class="branch-header no-branch" @click="toggleBranch(`${node.id}-no`)" :class="{ 'collapsed': isBranchCollapsed(`${node.id}-no`) }">
            <span class="branch-icon">✗</span>
            No Path
            <span class="collapse-icon">{{ isBranchCollapsed(`${node.id}-no`) ? '▼' : '▲' }}</span>
          </div>
          <div class="branch-nodes" v-show="!isBranchCollapsed(`${node.id}-no`)">
            <decision-branch
              v-for="branchNode in node.noBranch"
              :key="branchNode.id"
              :node="branchNode"
              :collapsed-decisions="collapsedDecisions"
              :collapsed-branches="collapsedBranches"
              :process-id="processId"
              @toggle-decision="toggleDecision"
              @toggle-branch="toggleBranch"
              @description-updated="$emit('description-updated', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DecisionBranch',
  props: {
    node: {
      type: Object,
      required: true
    },
    collapsedDecisions: {
      type: Set,
      required: true
    },
    collapsedBranches: {
      type: Set,
      required: true
    },
    processId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      editingNodeId: null,
      editedDescription: '',
      descriptionInputRef: null
    }
  },
  methods: {
    startEditing(node) {
      // Cancel any existing edit
      if (this.isEditing) {
        this.cancelEdit();
      }
      
      this.isEditing = true;
      this.editingNodeId = node.id;
      this.editedDescription = node.description || '';
      
      // Focus the input on next tick
      this.$nextTick(() => {
        if (this.descriptionInputRef) {
          this.descriptionInputRef.focus();
          this.descriptionInputRef.select();
        }
      });
    },

    async saveDescription() {
      if (!this.isEditing || !this.editingNodeId) return;

      const newDescription = this.editedDescription.trim();
      
      if (newDescription !== this.node.description) {
        // Get the current process flow data
        const flowData = this.$store.getters['swimlane/getProcessFlowData'](this.processId);
        
        // Update the node's description in the flow data
        const updatedNodes = flowData.nodes.map(n => {
          if (n.id === this.editingNodeId) {
            return { ...n, description: newDescription };
          }
          return n;
        });

        // Update the store and server with the modified flow data
        await this.$store.dispatch('swimlane/updateProcessFlowData', {
          processId: this.processId,
          flowData: {
            nodes: updatedNodes,
            connections: flowData.connections
          }
        });

        // Emit the update event for local component state
        this.$emit('description-updated', {
          nodeId: this.editingNodeId,
          description: newDescription
        });
      }

      // Reset editing state
      this.isEditing = false;
      this.editingNodeId = null;
      this.editedDescription = '';
    },

    cancelEdit() {
      this.isEditing = false;
      this.editingNodeId = null;
      this.editedDescription = '';
    },

    toggleDecision(id) {
      this.$emit('toggle-decision', id);
    },

    toggleBranch(branchId) {
      this.$emit('toggle-branch', branchId);
    },

    isDecisionCollapsed(id) {
      return this.collapsedDecisions.has(id);
    },

    isBranchCollapsed(branchId) {
      return this.collapsedBranches.has(branchId);
    },

    getNodeIcon(node) {
      switch (node.type) {
        case 'start':
          return '▶️'
        case 'decision':
          return '❓'
        case 'finish':
        case 'end':
          return '🏁'
        default:
          return '⚙️'
      }
    }
  }
}
</script>

<style scoped>
.nested-decision {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
  min-width: 250px;
}

.nested-branches {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 250px;
}

.branch-nodes {
  padding-left: 12px;
  border-left: 2px solid #e9ecef;
  min-width: 230px;
}

.node-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  background: white;
  border-radius: 8px;
  min-width: 250px;
}

.node-details {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 100%;
}

.description-input {
  width: 100%;
  min-width: 180px;
  padding: 4px 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.step-description {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.nested-decision-header {
  padding: 16px;
  background: var(--accent-victory-green);
  color: white;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
  user-select: none;
  min-width: 250px;
}

.decision-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
  max-width: 100%;
}

.node-icon {
  width: 32px;
  height: 32px;
  background: var(--accent-victory-green);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2em;
}

.node-title {
  font-weight: 500;
  color: #212529;
  margin-bottom: 4px;
}

.node-description {
  font-size: 0.9em;
  color: #6c757d;
  line-height: 1.4;
}

.decision-title {
  font-weight: 500;
  margin-bottom: 4px;
}

.decision-description {
  font-size: 0.9em;
  opacity: 0.9;
  line-height: 1.4;
}

.editable {
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 2px 4px;
  border-radius: 4px;
}

.editable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.description-edit {
  width: 100%;
}

.description-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(9, 57, 39, 0.1);
}
</style> 