<template>
  <div class="processes-content">
    <SwimLaneWrapper @navigate="$emit('navigate', $event)" />
  </div>
</template>

<script>
import SwimLaneWrapper from '@/views/SwimLaneWrapper.vue'

export default {
  name: 'ProcessesContent',
  components: {
    SwimLaneWrapper
  },
  emits: ['navigate']
}
</script>

<style scoped>
.processes-content {
  height: 100vh;
  width: 100%;
  background-color: var(--background-color);
}
</style> 