<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h2>Microphone Check</h2>
      <p>Please say something to test your microphone. You should see the icon pulse when it detects your voice.</p>
      <div class="microphone-icon" :class="{ 'active': isDetectingVoice }">
        <i class="fas fa-microphone"></i>
      </div>
      <div class="volume-meter" v-if="isListening">
        <div class="meter-fill" :style="{ width: volumeLevel + '%' }"></div>
      </div>
      <div class="button-group">
        <button 
          class="action-button continue"
          @click="handleContinue"
        >
          My microphone works
        </button>
        <button 
          class="action-button no-audio"
          @click="handleNoMicrophone"
        >
          Microphone not working
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MicrophoneCheckModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      audioContext: null,
      mediaStream: null,
      analyser: null,
      isListening: false,
      volumeLevel: 0,
      isDetectingVoice: false,
      animationFrame: null
    }
  },
  methods: {
    async initMicrophone() {
      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const source = this.audioContext.createMediaStreamSource(this.mediaStream)
        this.analyser = this.audioContext.createAnalyser()
        this.analyser.fftSize = 256
        source.connect(this.analyser)
        
        this.isListening = true
        this.startListening()
      } catch (error) {
        console.error('Error accessing microphone:', error)
        this.handleNoMicrophone()
      }
    },
    startListening() {
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount)
      
      const checkAudio = () => {
        this.analyser.getByteFrequencyData(dataArray)
        const average = dataArray.reduce((a, b) => a + b) / dataArray.length
        this.volumeLevel = Math.min(100, (average / 128) * 100)
        this.isDetectingVoice = this.volumeLevel > 20
        
        if (this.isListening) {
          this.animationFrame = requestAnimationFrame(checkAudio)
        }
      }
      
      checkAudio()
    },
    stopListening() {
      this.isListening = false
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame)
        this.animationFrame = null
      }
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => track.stop())
        this.mediaStream = null
      }
      if (this.audioContext && this.audioContext.state !== 'closed') {
        this.audioContext.close()
        this.audioContext = null
      }
    },
    handleContinue() {
      this.stopListening()
      this.$emit('continue')
    },
    handleNoMicrophone() {
      this.stopListening()
      this.$emit('no-microphone')
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.initMicrophone()
      } else {
        this.stopListening()
      }
    }
  },
  beforeDestroy() {
    this.stopListening()
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  max-width: 90%;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

p {
  color: #333;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.microphone-icon {
  font-size: 3rem;
  color: var(--accent-victory-green);
  margin: 1.5rem 0;
  transition: transform 0.2s ease;
}

.microphone-icon.active {
  transform: scale(1.1);
  color: var(--secondary-background-sky-blue);
}

.volume-meter {
  width: 80%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin: 1rem auto;
  overflow: hidden;
}

.meter-fill {
  height: 100%;
  background-color: var(--accent-victory-green);
  transition: width 0.1s ease;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.continue {
  background-color: var(--accent-victory-green);
  color: white;
}

.continue:hover {
  background-color: var(--secondary-background-sky-blue);
}

.no-audio {
  background-color: white;
  color: var(--accent-victory-green);
  border: 2px solid var(--accent-victory-green);
}

.no-audio:hover {
  background-color: #f8f8f8;
}
</style> 