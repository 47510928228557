<template>
  <div v-show="isOpen" class="drawer-container">
    <div class="modal-overlay" @click="handleClose"></div>
    <transition name="slide">
      <div v-if="isVisible" class="node-selector-drawer">
        <div class="node-selector-header">
          <div class="header-content">
            <span>{{ title }}</span>
            <button class="close-btn" @click="handleClose">←</button>
          </div>
        </div>
        <div class="node-selector-options" @click="handleNodeTypeSelect">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InfiniteCanvasDrawer',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
    if (this.isOpen) {
      this.$nextTick(() => {
        this.isVisible = true
      })
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.isVisible = true
        })
      } else {
        this.isVisible = false
      }
    }
  },
  methods: {
    handleClose() {
      this.isVisible = false
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    },
    handleNodeTypeSelect(event) {
      console.log('🎯 [InfiniteCanvasDrawer] Node type selected:', {
        target: event.target,
        buttonText: event.target.textContent.trim()
      })
      // Event will bubble up to parent component
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.drawer-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.node-selector-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  padding: 20px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.node-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px -20px 0 -20px;
  padding: 20px;
  background: var(--accent-victory-green);
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.1em;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.node-selector-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
</style> 