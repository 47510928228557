<template>
  <div v-if="!isLoading">
    <EmptyDepartmentsState v-if="!hasDepartments" @navigate="$emit('navigate', $event)" />
    <InfiniteCanvas 
      v-else
      v-model:selected-department="selectedDepartment"
      :initial-scale="scale"
      @navigate="$emit('navigate', $event)"
    />
  </div>
</template>

<script>
import InfiniteCanvas from '@/components/InfiniteCanvas.vue'
import EmptyDepartmentsState from '@/components/EmptyDepartmentsState.vue'
import { mapState } from 'vuex'

export default {
  name: 'SwimLaneWrapper',
  components: {
    InfiniteCanvas,
    EmptyDepartmentsState
  },
  emits: ['navigate'],
  data() {
    return {
      processId: null,
      processName: 'Example Process',
      scale: 0.35,
      selectedDepartment: 'sales_dept',  // Default to sales department
      isLoading: true
    }
  },
  computed: {
    ...mapState('departments', ['departments']),
    hasDepartments() {
      return this.departments && this.departments.length > 0
    }
  },
  async created() {
    try {
      // First try to get user ID from assessment data
      const assessmentData = localStorage.getItem('assessmentData')
      let userId = null
      
      if (assessmentData) {
        const parsedData = JSON.parse(assessmentData)
        if (parsedData.assessment && parsedData.assessment.uid) {
          userId = parsedData.assessment.uid
        }
      }

      // If not found in assessment data, try email as fallback
      if (!userId) {
        userId = localStorage.getItem('userEmail')
      }

      if (!userId) {
        console.warn('[SwimLaneWrapper] No user ID found')
        this.isLoading = false
        return
      }

      // Fetch user's departments
      await this.$store.dispatch('departments/fetchUserDepartments', userId)

      // Set current user ID in store
      await this.$store.dispatch('swimlane/setCurrentUserId', userId)

      // Explicitly fetch user's processes from database
      await this.$store.dispatch('swimlane/fetchUserProcesses', userId)

      // Get user's processes after fetching
      const userProcesses = this.$store.getters['swimlane/getUserProcesses']
      
      if (userProcesses && userProcesses.length > 0) {
        // Use the first process if user has any
        const firstProcess = userProcesses[0]
        this.processId = firstProcess.id
        this.processName = firstProcess.name
      }
      // Only create example data if this is the user's first time (no processes exist)
      else if (!userProcesses || userProcesses.length === 0) {
        this.processId = await this.$store.dispatch('swimlane/initializeExampleData')
      }
    } catch (error) {
      console.error('Error in SwimLaneWrapper created:', error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
div {
  width: 100%;
  height: 100vh;
}
</style> 
