<template>
  <div class="grid-anchor"></div>
</template>

<script>
export default {
  name: 'GridAnchor',
  props: {
    gridPosition: {
      type: Object,
      required: true
    },
    gridSize: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.grid-anchor {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid #666;
  z-index: 9999;
  pointer-events: none;
  left: 0;
  top: 0;
  opacity: 0;
}
</style> 