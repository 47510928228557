<template>
  <div class="empty-state-wrapper">
    <div class="empty-state-container">
      <div class="empty-state-icon">
        <i class="fas fa-building"></i>
      </div>
      <h2 class="empty-state-title">No Departments Selected</h2>
      <p class="empty-state-description">
        Start by selecting departments to create and manage your business processes.
      </p>
      <button class="empty-state-button" @click="$emit('navigate', 'DepartmentsContent')">
        Select Departments
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyDepartmentsState',
  emits: ['navigate']
}
</script>

<style scoped>
.empty-state-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 2rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  z-index: 100;
  padding: 2rem;
  margin-top: -3rem;
  margin-left: -2rem;
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.empty-state-icon {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-victory-green);
  border-radius: 50%;
  margin-bottom: 2rem;
}

.empty-state-icon i {
  font-size: 3.5rem;
  color: white;
}

.empty-state-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--text-color);
  margin: 0 0 1.5rem 0;
}

.empty-state-description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin: 0 0 3rem 0;
  max-width: 500px;
}

.empty-state-button {
  padding: 1rem 3rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  background-color: var(--accent-victory-green);
  border: 2px solid var(--accent-victory-green);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.empty-state-button:hover {
  background-color: white;
  color: var(--accent-victory-green);
}

.empty-state-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--accent-victory-green-rgb), 0.3);
}
</style> 