<template>
  <div class="origin-marker" :style="markerStyle" ref="marker"></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OriginMarker',
  props: {
    panX: {
      type: Number,
      required: true
    },
    panY: {
      type: Number,
      required: true
    },
    scale: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      viewportWidth: 0,
      viewportHeight: 0,
      sidebarWidth: 300,
      lastPanX: null,
      lastPanY: null,
      lastGridX: null,
      lastGridY: null,
      debounceTimeout: null,
      isInitialized: false
    }
  },
  computed: {
    ...mapState('grid', ['gridSize']),
    
    markerStyle() {
      if (!this.isInitialized) return { opacity: 0 }
      
      // Calculate new position - offset from center
      const offsetX = 400  // Distance from left
      const offsetY = 300  // Distance from top
      
      const canvasX = (offsetX - this.panX) / this.scale
      const canvasY = (offsetY - this.panY) / this.scale
      
      const gridX = Math.round(canvasX / this.gridSize)
      const gridY = Math.round(canvasY / this.gridSize)
      
      // Only emit update if position changed
      if (gridX !== this.lastGridX || gridY !== this.lastGridY) {
        if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          console.log('GRID CORDS YELLOW:', { x: gridX, y: gridY })
          this.$emit('position-update', { 
            x: gridX,
            y: gridY,
            pixelX: canvasX,
            pixelY: canvasY
          })
        }, 100)
      }
      
      this.lastGridX = gridX
      this.lastGridY = gridY
      this.lastPanX = this.panX
      this.lastPanY = this.panY
      
      return {
        transform: `translate(${gridX * this.gridSize}px, ${gridY * this.gridSize}px) scale(${1/this.scale})`
      }
    }
  },
  methods: {
    updateDimensions() {
      const wrapper = this.$el.closest('.infinite-canvas-wrapper')
      if (wrapper) {
        const rect = wrapper.getBoundingClientRect()
        this.viewportWidth = rect.width
        this.viewportHeight = rect.height
        this.isInitialized = true
      }
    }
  },
  mounted() {
    // Initial dimensions
    this.updateDimensions()
    
    // Update dimensions when window resizes
    window.addEventListener('resize', this.updateDimensions)
    
    // Force a recalculation after a short delay to ensure everything is ready
    setTimeout(() => {
      this.updateDimensions()
    }, 100)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDimensions)
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }
  }
}
</script>

<style scoped>
.origin-marker {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: yellow;
  border-radius: 50%;
  border: 2px solid #666;
  z-index: 9999;
  pointer-events: none;
  left: 0;
  top: 0;
  opacity: 0;
}
</style> 