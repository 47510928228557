<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Create New Department</h3>
        <button class="close-btn" @click="$emit('close')">&times;</button>
      </div>
      <div class="modal-body">
        <div class="input-group">
          <div>Department Name</div>
          <input 
            v-model="departmentName"
            type="text"
            placeholder="Enter a descriptive name for your department"
            @keyup.enter="handleSubmit"
            ref="nameInput"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel-btn" @click="$emit('close')">Cancel</button>
        <button 
          class="create-btn" 
          @click="handleSubmit" 
          :disabled="!departmentName.trim()"
        >
          Create Department
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepartmentModal',
  data() {
    return {
      departmentName: ''
    }
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  methods: {
    handleSubmit() {
      if (this.departmentName.trim()) {
        this.$emit('submit', this.departmentName.trim())
        this.departmentName = ''
      }
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.2s ease-out;
}

.modal-content {
  background: white;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  animation: slideUp 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.modal-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #212529;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.modal-body {
  padding: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group div {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
}

.input-group input {
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  transition: border-color 0.15s ease-in-out;
}

.input-group input:focus {
  outline: none;
  border-color: #003325;
  box-shadow: 0 0 0 1px #003325;
}

.modal-footer {
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid #dee2e6;
}

.cancel-btn {
  padding: 6px 12px;
  background: #e9ecef;
  border: none;
  border-radius: 4px;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.cancel-btn:hover {
  background: #dee2e6;
}

.create-btn {
  padding: 6px 12px;
  background: #748B82;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-btn:not(:disabled) {
  background: #003325;
}

.create-btn:hover:not(:disabled) {
  background: #004432;
}

.create-btn:disabled {
  background: #748B82;
  opacity: 0.65;
  cursor: not-allowed;
}
</style> 