<template>
  <section class="hero" ref="heroSection">
    <elevenlabs-convai v-if="isVisible && scriptLoaded" agent-id="fkflxGdoPUwbz5lv4v5T"></elevenlabs-convai>
    <img src="@/assets/logo.png" alt="Victory AI Logo" class="hero-logo">
    <div class="hero-text">
      <h1>Optimize Your Business Processes</h1>
      <h3>By building AI agents into your company</h3>
    </div>
    <div class="button-container">
      <button @click="scrollToChallenge" class="learn-more-btn">Learn More</button>
      <button @click="$router.push('/schedule')" class="schedule-btn">Schedule Call</button>
    </div>
    <div class="semicircle"></div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  computed: {
    isBannerVisible() {
      return !this.$store.state.whitePaperBannerClosed;
    }
  },
  watch: {
    isBannerVisible: {
      immediate: true,
      handler(isVisible) {
        if (isVisible) {
          document.documentElement.style.setProperty('--banner-offset', '-60px');
        } else {
          document.documentElement.style.setProperty('--banner-offset', '0');
        }
      }
    }
  },
  beforeUnmount() {
    document.documentElement.style.removeProperty('--banner-offset');
  },
  data() {
    return {
      scriptLoaded: false,
      isVisible: false,
      observer: null
    }
  },
  methods: {
    loadScript() {
      if (!document.getElementById('elevenlabs-convai-script')) {
        const script = document.createElement('script')
        script.src = 'https://elevenlabs.io/convai-widget/index.js'
        script.async = true
        script.id = 'elevenlabs-convai-script'
        script.onload = () => {
          this.scriptLoaded = true
        }
        document.head.appendChild(script)
      } else {
        this.scriptLoaded = true
      }
    },
    removeScript() {
      const script = document.getElementById('elevenlabs-convai-script')
      if (script) {
        document.head.removeChild(script)
        this.scriptLoaded = false
      }
    },
    scrollToChallenge() {
      this.$emit('navigate-to-challenge');
    },
    setupIntersectionObserver() {
      this.observer = new IntersectionObserver((entries) => {
        const isIntersecting = entries[0].isIntersecting
        if (isIntersecting && !this.scriptLoaded) {
          this.loadScript()
        }
        this.isVisible = isIntersecting
      }, {
        threshold: 0.1
      })
      
      this.observer.observe(this.$refs.heroSection)
    }
  },
  mounted() {
    this.setupIntersectionObserver()
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
    if (!document.querySelector('.hero')) {
      this.removeScript()
    }
  }
}
</script>

<style scoped>
.hero {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5%;
  position: relative;
  overflow: hidden;
  margin-top: var(--banner-offset, 0);
  transition: margin-top 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero-logo {
  width: 400px;
  height: auto;
  margin-bottom: 2rem;
  transition: all 0.5s ease;
}

.hero-text {
  margin-bottom: 2rem;
  line-height: 1.2;
}

h1, h2, h3 {
  margin: 12px;
}

h1 {
  font-size: 3rem;
  color: var(--accent-victory-green);
}

h2 {
  font-size: 2.5rem;
  color: var(--main-text-color);
}

h3 {
  font-size: 2rem;
  color: var(--accent-victory-green);
  font-weight: normal;
}

.button-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.learn-more-btn, .schedule-btn {
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.learn-more-btn {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.schedule-btn {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.learn-more-btn:hover, .schedule-btn:hover {
  transform: scale(1.05);
}

.learn-more-btn:hover {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.schedule-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.semicircle {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100px;
  background-color: var(--accent-sunny-yellow);
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  z-index: 10;
}

@media (max-width: 768px) {
  .hero {
    padding-bottom: 15%;
    min-height: 100vh;
  }

  .hero-logo {
    width: 280px;
    margin-bottom: 1.5rem;
  }

  .hero-text {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}
</style>
