export default {
  namespaced: true,
  
  state: {
    activeModal: null, // Can be 'steps-list' or 'node-selector' or null
  },

  mutations: {
    SET_ACTIVE_MODAL(state, modalName) {
      state.activeModal = modalName
    },
  },

  actions: {
    async openModal({ commit, state }, modalName) {
      // If there's already an active modal, close it first
      if (state.activeModal) {
        commit('SET_ACTIVE_MODAL', null)
        // Wait for the closing animation to finish
        await new Promise(resolve => setTimeout(resolve, 300))
      }
      commit('SET_ACTIVE_MODAL', modalName)
    },

    closeModal({ commit }) {
      commit('SET_ACTIVE_MODAL', null)
    }
  },

  getters: {
    isModalOpen: state => modalName => state.activeModal === modalName
  }
} 