<template>
  <div class="swimlane-content" @click="handleWrapperClick">
    <div v-for="(swimlane, index) in departmentSwimlanes" 
         :key="swimlane.id" 
         class="swimlane-container"
         @mousedown.left="startDrag"
         :style="swimlaneStyle">
      <!-- Add delete button -->
      <div class="delete-swimlane-wrapper">
        <div class="delete-swimlane-btn" @click.stop="handleDeleteClick">×</div>
      </div>
      <div class="swimlane-title">
        <div class="title-container">
          <div v-if="!isEditingTitle || editingSwimlaneId !== swimlane.id" 
               class="swimlane-name" 
               @click.stop="startEditingTitle(swimlane, index)">
            {{ swimlane.name }}
          </div>
          <input v-else
                 :ref="el => { if (el) titleInput = el }"
                 v-model="editedTitle"
                 class="swimlane-name-input"
                 @blur="saveSwimlaneTitle"
                 @keyup.enter="saveSwimlaneTitle"
                 @keyup.esc="cancelEditTitle"
          />
          <button class="info-btn" @click.stop="handleInfoClick">
            <i class="fas fa-info-circle"></i>
          </button>
        </div>
      </div>
      
      <div class="swimlane-chart">
        <grid-anchor 
          :grid-position="currentGridPosition"
          :grid-size="gridSize"
        />
        <div class="departments-column" @mousedown.stop>
          <div v-for="(dept, index) in swimlane.swimDepartments" 
               :key="dept.id" 
               class="department"
               draggable="true"
               @dragstart="startDepartmentDrag(index, $event)"
               @dragover.prevent
               @dragenter.prevent="dragEnterDepartment(index)"
               @drop="dropDepartment(index)"
               :class="{ 'drag-over': dragOverIndex === index }"
               :style="getDepartmentStyle(dept)">
            <div class="department-content">
              <div class="department-header" 
                   :style="getDepartmentHeaderStyle(dept)">
                {{ dept.name }}
              </div>
            </div>

            <!-- Separate roles column -->
            <div v-if="dept.roles && dept.roles.length > 0" 
                 class="department-roles-column"
                 :style="getRolesColumnStyle(dept)">
              <div class="department-roles">
                <div v-for="(role, roleIndex) in dept.roles" 
                     :key="role.id"
                     class="role"
                     :style="getRoleStyle(role, roleIndex)">
                  <div class="role-content">
                    <div v-if="!role.isEditing" 
                         class="role-name" 
                         @click="startEditingRole(dept.id, role)"
                         :style="{ color: role.isLightTheme ? 'var(--accent-victory-green)' : 'white' }">
                      {{ role.name }}
                    </div>
                    <input v-else
                           ref="roleNameInput"
                           v-model="role.editedName"
                           class="role-name-input"
                           @blur="saveRoleName(dept.id, role)"
                           @keyup.enter="saveRoleName(dept.id, role)"
                           @keyup.esc="cancelEditRole(role)"
                           :style="{ color: role.isLightTheme ? 'var(--accent-victory-green)' : 'white' }"
                    />
                    <div class="role-delete-btn" @click.stop="showDeleteRoleModal(dept.id, role.id)">
                      <i class="fas fa-minus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="department-edge">
              <div class="hover-area"
                   @mouseenter="hoveredDepartment = index"
                   @mouseleave="hoveredDepartment = null">
              </div>
              <div v-show="hoveredDepartment === index" class="department-buttons">
                <button class="dept-btn remove-btn" 
                        @click.stop="showDeleteDepartmentModal(index)"
                        :disabled="swimlane.swimDepartments.length <= 1">
                  <span>-</span>
                </button>
                <button class="dept-btn add-btn" @click.stop="openDepartmentModal(index)">
                  <span>+</span>
                </button>
              </div>
            </div>
            
            <div class="department-hover-circle" @click="addGridRow(index)">
              <span class="plus-symbol">+</span>
            </div>
          </div>
        </div>

        <div class="grid-container">
          <node-connection
            v-for="conn in connections"
            :key="conn.id"
            :start-x="getConnectionStartX(conn)"
            :start-y="getConnectionStartY(conn)"
            :end-x="getConnectionEndX(conn)"
            :end-y="getConnectionEndY(conn)"
            :type="conn.type"
          />
          
          <div v-for="colIndex in numberOfColumns" :key="colIndex" class="grid-column">
            <div v-for="rowIndex in totalGridRows"
                 :key="`${colIndex}-${rowIndex}`"
                 class="grid-cell"
                 :data-col="colIndex"
                 :data-row="rowIndex"
                 @mouseenter="hoveredCell = `${colIndex}-${rowIndex}`"
                 @mouseleave="hoveredCell = null">
              <div v-if="hoveredCell === `${colIndex}-${rowIndex}` && !isOccupied(colIndex, rowIndex) && false" 
                   class="add-node-hint"
                   @click="openNodeSelector(colIndex, rowIndex)">
                <span>+</span>
              </div>
            </div>
          </div>
          
          <template v-for="node in nodes" :key="node.id">
            <flow-node
              v-if="node.type !== 'decision'"
              :id="node.id"
              :title="node.title"
              :description="node.description"
              :position="node.position"
              :type="node.type"
              @update:description="updateNodeDescription(node.id, $event)"
              @nodeDrag="handleNodeDrag"
              @nodeDragEnd="handleNodeDragEnd"
              @node-selection-request="handleNodeSelectionRequest"
              @delete-request="showDeleteNodeModal"
            />
            <decision-node
              v-else
              :id="node.id"
              :content="node.title"
              :description="node.description"
              :position="node.position"
              @update:description="updateNodeDescription(node.id, $event)"
              @nodeDrag="handleNodeDrag"
              @nodeDragEnd="handleNodeDragEnd"
              @delete-request="showDeleteNodeModal"
            />
          </template>
        </div>
      </div>
    </div>

    <!-- Remove the old modal and add DeleteNodeModal -->
    <DeleteNodeModal 
      v-if="showDeleteModal"
      :show="true"
      :type="deleteModalType"
      @close="showDeleteModal = false"
      @confirm="confirmDelete"
    />

    <department-modal
      v-if="showDepartmentModal"
      @close="showDepartmentModal = false"
      @submit="handleNewDepartment"
    />

    <node-selection-modal
      v-if="showNodeSelectionModal"
      :nodes="nodes"
      :source-node-id="sourceNodeId"
      :mode="connectionMode"
      @close="showNodeSelectionModal = false"
      @select="handleNodeConnection"
      class="modern-modal"
    />
  </div>
</template>

<script>
import { reactive } from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import FlowNode from '@/components/FlowNode.vue'
import NodeConnection from '@/components/NodeConnection.vue'
import DecisionNode from '@/components/DecisionNode.vue'
import DepartmentModal from '@/components/DepartmentModal.vue'
import NodeSelectionModal from '@/components/NodeSelectionModal.vue'
import GridAnchor from '@/components/GridAnchor.vue'
import DeleteNodeModal from '@/components/DeleteNodeModal.vue'

export default {
  name: 'SwimLane',
  components: {
    FlowNode,
    NodeConnection,
    DecisionNode,
    DepartmentModal,
    NodeSelectionModal,
    GridAnchor,
    DeleteNodeModal
  },
  props: {
    processId: {
      type: String,
      required: true
    },
    processName: {
      type: String,
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    initialGridPosition: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      hoveredDepartment: null,
      hoveredCell: null,
      selectedCell: null,
      sourceNodeId: null,
      showDepartmentModal: false,
      selectedDepartmentIndex: null,
      draggedDeptIndex: null,
      dragOverIndex: null,
      isConnectingExit: false,
      isConnectingEntrance: false,
      showNodeSelectionModal: false,
      connectionMode: null,
      isEditingTitle: false,
      editedTitle: '',
      editingSwimlaneId: null,
      titleInput: null,
      showStepsListModal: false,
      position: null,  // Initialize as null, will be set in created hook
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      dragStartPosition: null,  // Initialize as null, will be set during drag
      dragOffset: { x: 0, y: 0 },
      showDeleteModal: false  // Add this line
    }
  },
  computed: {
    flowData() {
      const data = this.$store.getters['swimlane/getProcessFlowData'](this.processId) || { nodes: [], connections: [] }
      // console.log('[SwimLane] flowData computed:', {
      //   processId: this.processId,
      //   nodes: data.nodes?.length,
      //   connections: data.connections?.length
      // })
      return data
    },
    
    departmentSwimlanes() {
      const departments = this.$store.getters['swimlane/getProcessDepartments'](this.processId)
      // console.log('[SwimLane] departmentSwimlanes computed:', {
      //   processId: this.processId,
      //   departments: departments?.length
      // })
      return departments ? [{ id: this.processId, name: this.processName, swimDepartments: departments }] : []
    },

    nodes() {
      return this.flowData?.nodes || []
    },

    connections() {
      return this.flowData?.connections || []
    },

    isStartNodeSelector() {
      if (!this.sourceNodeId) return false;
      const sourceNode = this.getNodeById(this.sourceNodeId);
      return sourceNode && sourceNode.title === 'Start';
    },

    isYesNoNodeSelector() {
      if (!this.sourceNodeId) return false;
      const sourceNode = this.getNodeById(this.sourceNodeId);
      return sourceNode && (sourceNode.title === 'Yes' || sourceNode.title === 'No');
    },

    totalGridRows() {
      if (!this.departmentSwimlanes || !this.departmentSwimlanes[0]) return 0;
      
      return this.departmentSwimlanes[0].swimDepartments.reduce((total, dept) => {
        return total + Math.max(1, (dept.roles || []).length);
      }, 0);
    },
    
    chartHeight() {
      return `${this.totalGridRows * 250}px`;
    },
    
    containerHeight() {
      // Add just enough padding (50px) to prevent overflow while maintaining layout
      return `${(this.totalGridRows * 250) + 50}px`;
    },

    numberOfColumns() {
      // Get the rightmost node position
      const maxColumn = this.nodes.reduce((max, node) => {
        const nodeColumn = Math.floor(node.position.x / (264 + 100)) + 1
        return Math.max(max, nodeColumn)
      }, 0)
      
      // Always have at least 5 columns, or more if nodes exist beyond that
      return Math.max(5, maxColumn + 1) // +1 for single column buffer space
    },

    gridWidth() {
      const cellWidth = 264
      const horizontalGap = 100
      return `${(cellWidth + horizontalGap) * this.numberOfColumns}px`
    },
    
    ...mapState('grid', ['gridSize']),
    
    swimlaneStyle() {
      // console.log('[SwimLane] swimlaneStyle computed - position:', this.position)
      const style = {
        transform: `translate(${this.position.x * this.gridSize}px, ${this.position.y * this.gridSize}px)`,
        cursor: this.isDragging ? 'grabbing' : 'grab',
        position: 'absolute',  // Add absolute positioning
        left: 0,              // Reset left position
        top: 0               // Reset top position
      }
      // console.log('[SwimLane] swimlaneStyle computed - transform:', style.transform)
      return style
    },

    isFinishNodeSource() {
      if (!this.sourceNodeId) return false;
      const sourceNode = this.getNodeById(this.sourceNodeId);
      return sourceNode && sourceNode.type === 'finish';
    },

    isGridNodeSelector() {
      // Return true if this menu was opened from a grid cell
      return this.selectedCell !== null && this.sourceNodeId === null;
    },

    isFromPlusButton() {
      // Return true if this menu was opened from a plus button on a node
      return this.sourceNodeId && this.nodeSelectorPosition.top && !this.selectedCell;
    },

    currentGridPosition() {
      // // console.log('[SwimLane] currentGridPosition computed:', {
      //   position: this.position
      // })
      return { ...this.position }
    }
  },
  methods: {
    ...mapActions('grid', ['updateProcessGrid']),

    handleScaleChange(scale) {
      this.scale = scale
    },
    
    getDepartmentRow(department) {
      const swimlane = this.departmentSwimlanes[0]
      return swimlane.swimDepartments.findIndex(dept => dept.id === department) + 1
    },
    
    getNodeById(id) {
      return this.flowData.nodes.find(node => node.id === id)
    },
    
    handleNodeDragStart(event) {
      const node = this.flowData.nodes.find(n => n.id === this.draggedNodeId)
      if (node) {
        this.previousPosition = { ...node.position }
      }
    },
    
    handleNodeDrag({ id, position }) {
      const nodeIndex = this.flowData.nodes.findIndex(n => n.id === id)
      if (nodeIndex !== -1) {
        if (!this.previousPosition) {
          this.previousPosition = { ...this.flowData.nodes[nodeIndex].position }
        }
        this.flowData.nodes[nodeIndex].position = position
        
        // Update store
        this.$store.dispatch('swimlane/updateProcessFlowData', {
          processId: this.processId,
          flowData: this.flowData
        })
      }
    },
    
    handleNodeDragEnd({ id, event }) {
      // Grid cell dimensions
      const cellWidth = 264
      const cellHeight = 250
      const horizontalGap = 100
      const columnWidth = cellWidth + horizontalGap
      const nodeWidth = 200
      const nodeHeight = 100
      
      // Get current node position
      const node = this.flowData.nodes.find(n => n.id === id)
      if (!node) return
      
      // Calculate the nearest grid position
      const col = Math.round(node.position.x / columnWidth)
      const row = Math.round(node.position.y / cellHeight)
      
      // Constrain row to valid range (0 to totalGridRows - 1)
      const constrainedRow = Math.max(0, Math.min(row, this.totalGridRows - 1))
      
      // Calculate snapped position
      const snappedX = col * columnWidth + (cellWidth - nodeWidth) / 2
      const snappedY = constrainedRow * cellHeight + (cellHeight - nodeHeight) / 2
      
      // Check if any other node occupies this position
      const isOccupied = this.flowData.nodes.some(otherNode => {
        if (otherNode.id === id) return false // Skip the current node
        return otherNode.position.x === snappedX && otherNode.position.y === snappedY
      })
      
      // Update node position - if occupied, return to previous position
      const nodeIndex = this.flowData.nodes.findIndex(n => n.id === id)
      if (nodeIndex !== -1) {
        this.flowData.nodes[nodeIndex].position = isOccupied 
          ? this.previousPosition                // Return to previous grid position
          : { x: snappedX, y: snappedY }        // Snap to new position
      }
      
      // Reset previousPosition after drag ends
      this.previousPosition = null
    },
    
    handleAddNode({ sourceId, position }) {
      // Generate a new unique ID
      const newId = Math.max(...this.nodes.map(n => parseInt(n.id.replace('node', '')))) + 1
      
      // Add the new node
      const newNode = {
        id: `node${newId}`,
        title: `Node ${newId}`,
        position: position
      }
      console.log('Adding new node:', newNode)
      this.flowData.nodes.push(newNode)

      // Create connection from source node if sourceId is provided
      if (sourceId) {
        const existingSourceExit = this.flowData.connections.find(
          c => c.source === sourceId
        )

        if (existingSourceExit) {
          // Handle existing connection chain
          const targetNode = this.flowData.nodes.find(n => n.id === existingSourceExit.target)
          if (targetNode) {
            // Move the target node to the right
            targetNode.position.x = position.x + (cellWidth + horizontalGap)
            targetNode.position.y = position.y
          }

          // Update the connection to point to the new node
          existingSourceExit.target = `node${newId}`

          // Add a new connection from the new node to the old target
          this.flowData.connections.push({
            id: `conn${this.getNextConnId()}`,
            source: `node${newId}`,
            target: targetNode.id
          })
        } else {
          // Simple connection from source to new node
          this.flowData.connections.push({
            id: `conn${this.getNextConnId()}`,
            source: sourceId,
            target: `node${newId}`
          })
        }
      }

      // If it's a decision node, add Yes/No nodes with appropriate titles
      if (type === 'decision') {
        // Get total rows
        const totalRows = this.totalGridRows
        const lastRow = totalRows - 1
        
        // Add "Yes" node
        const yesId = `node${newId + 1}`
        this.flowData.nodes.push({
          id: yesId,
          title: 'Yes',
          position: {
            x: basePosition.x + (cellWidth + horizontalGap),
            y: currentRow === 0 ? basePosition.y : basePosition.y - cellHeight
          },
          type: 'activity'
        })

        // Add "No" node
        const noId = `node${newId + 2}`
        this.flowData.nodes.push({
          id: noId,
          title: 'No',
          position: {
            x: basePosition.x + (cellWidth + horizontalGap),
            y: currentRow === lastRow ? basePosition.y : basePosition.y + cellHeight
          },
          type: 'activity'
        })

        // Add connections from decision to Yes/No nodes
        this.flowData.connections.push({
          id: `conn${this.getNextConnId()}`,
          source: `node${newId}`,
          target: yesId,
          type: 'yes'
        })

        this.flowData.connections.push({
          id: `conn${this.getNextConnId()}`,
          source: `node${newId}`,
          target: noId,
          type: 'no'
        })
      }

      // Update step names to ensure proper numbering
      this.updateStepNames()

      // Clean up flowData by removing any undefined or null values
      const cleanedFlowData = {
        nodes: this.flowData.nodes.filter(Boolean),
        connections: this.flowData.connections.filter(Boolean)
      }

      // Persist changes to store
      this.$store.dispatch('swimlane/updateProcessFlowData', {
        processId: this.processId,
        flowData: cleanedFlowData
      })

      this.showNodeSelector = false
      this.sourceNodeId = null
    },
    
    handleRemoveNode(nodeId) {
      // Get the node being removed
      const nodeToRemove = this.getNodeById(nodeId)
      if (!nodeToRemove) return

      // Ensure connections array exists
      if (!this.flowData.connections) {
        this.flowData.connections = []
      }

      if (nodeToRemove.type === 'decision') {
        console.log('Removing decision node:', nodeId)
        
        // Find the incoming connection to the decision node
        const incomingConnection = this.flowData.connections.find(conn => conn.target === nodeId)
        console.log('Incoming connection:', incomingConnection)
        
        // Find Yes and No connections from the decision node
        const yesConnection = this.flowData.connections.find(conn => 
          conn.source === nodeId && conn.type === 'yes'
        )
        const noConnection = this.flowData.connections.find(conn => 
          conn.source === nodeId && conn.type === 'no'
        )
        console.log('Yes connection:', yesConnection)
        console.log('No connection:', noConnection)

        // Function to get all nodes in a path
        const getPathNodes = (startNodeId, visited = new Set()) => {
          const pathNodes = []
          let currentId = startNodeId
          
          while (currentId) {
            // Prevent infinite loops by checking if we've seen this node before
            if (visited.has(currentId)) break
            visited.add(currentId)

            const node = this.getNodeById(currentId)
            if (!node) break
            
            if (node.title !== 'Yes' && node.title !== 'No') {
              pathNodes.push(node)
            }
            
            const nextConn = this.flowData.connections.find(
              c => c.source === currentId && !c.type
            )
            currentId = nextConn ? nextConn.target : null
          }
          return pathNodes
        }

        // Get nodes from both paths
        const yesPathNodes = yesConnection ? getPathNodes(yesConnection.target) : []
        const noPathNodes = noConnection ? getPathNodes(noConnection.target) : []
        console.log('Yes path nodes:', yesPathNodes)
        console.log('No path nodes:', noPathNodes)

        // Remove all existing connections involving the decision node and its paths
        const nodesToRemove = new Set([nodeId])
        if (yesConnection) nodesToRemove.add(yesConnection.target)
        if (noConnection) nodesToRemove.add(noConnection.target)

        // Find and preserve decision node connections (for node removal)
        const decisionConnections = this.flowData.connections.filter(conn => {
          const connSourceNode = this.getNodeById(conn.source)
          return connSourceNode && 
                 connSourceNode.type === 'decision' && 
                 connSourceNode.id !== nodeToRemove.id && // Don't preserve connections from the node being deleted
                 (conn.type === 'yes' || conn.type === 'no')
        })

        // Filter connections
        this.flowData.connections = this.flowData.connections.filter(conn => {
          // Keep decision node connections
          if (decisionConnections.some(dc => dc.id === conn.id)) return true

          // Keep connections that aren't part of the removed decision node or its paths
          return !nodesToRemove.has(conn.source) && 
                 !nodesToRemove.has(conn.target) &&
                 !yesPathNodes.some(n => n.id === conn.source || n.id === conn.target) &&
                 !noPathNodes.some(n => n.id === conn.source || n.id === conn.target)
        })

        // Create new connections for the merged chain
        if (incomingConnection) {
          let previousNodeId = incomingConnection.source
          
          // First connect all nodes from yes path
          for (const node of yesPathNodes) {
            this.flowData.connections.push({
              id: `conn${this.getNextConnId()}`,
              source: previousNodeId,
              target: node.id
            })
            previousNodeId = node.id
          }

          // Then connect all nodes from no path
          for (const node of noPathNodes) {
            this.flowData.connections.push({
              id: `conn${this.getNextConnId()}`,
              source: previousNodeId,
              target: node.id
            })
            previousNodeId = node.id
          }
        }

        // Remove Yes/No nodes and the decision node
        this.flowData.nodes = this.flowData.nodes.filter(node => 
          !nodesToRemove.has(node.id)
        )

      } else {
        // Handle non-decision node removal (existing code)
        const incomingConnection = this.flowData.connections.find(conn => conn.target === nodeId)
        const outgoingConnection = this.flowData.connections.find(conn => conn.source === nodeId)
        
        if (incomingConnection && outgoingConnection) {
          const maxId = Math.max(...(this.connections?.map(c => c.id) || [0]))
          this.flowData.connections.push({
            id: maxId + 1,
            source: incomingConnection.source,
            target: outgoingConnection.target
          })
        }

        this.flowData.connections = this.flowData.connections.filter(
          conn => conn.source !== nodeId && conn.target !== nodeId
        )

        this.flowData.nodes = this.flowData.nodes.filter(
          node => node.id !== nodeId
        )
      }

      // Update step names
      this.updateStepNames()
    },
    
    startDrag(event) {
      if (event.target.closest('.node-container')) return
      
      this.isDragging = true
      this.dragStartX = event.clientX
      this.dragStartY = event.clientY
      
      // Get current grid position
      this.dragStartPosition = { ...this.position }
      
      window.addEventListener('mousemove', this.handleDrag)
      window.addEventListener('mouseup', this.stopDrag)
    },

    handleDrag(event) {
      if (!this.isDragging) return

      // Calculate total movement from start position
      const deltaX = event.clientX - this.dragStartX
      const deltaY = event.clientY - this.dragStartY

      // Convert to grid movement
      const deltaGridX = Math.round(deltaX / this.gridSize / this.scale)
      const deltaGridY = Math.round(deltaY / this.gridSize / this.scale)

      // Update position based on drag start position
      this.position = {
        x: this.dragStartPosition.x + deltaGridX,
        y: this.dragStartPosition.y + deltaGridY
      }
    },

    async stopDrag() {
      if (!this.isDragging) return
      
      this.isDragging = false
      window.removeEventListener('mousemove', this.handleDrag)
      window.removeEventListener('mouseup', this.stopDrag)

      // Update store with grid coordinates
      await this.updateProcessGrid({
          processId: this.processId,
          position: { ...this.position },
          size: { width: 1, height: 1 }
      })

      // Reset drag state
      this.dragStartX = 0
      this.dragStartY = 0
      this.dragStartPosition = null
    },

    getDepartmentStyle(dept) {
      const rowCount = Math.max(1, (dept.roles || []).length);
      const height = rowCount * 250; // 250px per grid row
      return {
        backgroundColor: dept.color,
        height: `${height}px`
      }
    },

    getDepartmentHeaderStyle(dept) {
      const rowCount = Math.max(1, (dept.roles || []).length);
      const height = rowCount * 250; // Match department height
      return {
        height: `${height}px`,
        backgroundColor: dept.color,
        color: dept.isLightTheme ? 'var(--accent-victory-green)' : 'white'
      }
    },

    getRolesColumnStyle(dept) {
      const rowCount = Math.max(1, (dept.roles || []).length);
      const height = rowCount * 250;
      return {
        height: `${height}px`
      }
    },

    getRoleStyle(role, roleIndex) {
      return {
        backgroundColor: role.color || 'transparent'
      }
    },
    
    addDepartment(name) {
      const newDept = {
        id: `dept_${Date.now()}`,
        name: name.toUpperCase(),
        roles: []
      }
      
      this.$store.dispatch('swimlane/addDepartmentToProcess', {
        processId: this.processId,
        department: newDept,
        index: this.selectedDepartmentIndex + 1
      })
    },
    
    removeDepartment(index) {
      this.$store.dispatch('swimlane/removeDepartmentFromProcess', {
        processId: this.processId,
        index
      })
    },
    
    handleDepartmentHover(index) {
      this.hoveredDepartment = index;
    },
    
    handleDepartmentLeave() {
      this.hoveredDepartment = null;
    },
    
    isOccupied(col, row) {
      if (!this.departmentSwimlanes || !this.departmentSwimlanes[0]) return false;
      
      let currentRow = 0;
      for (const dept of this.departmentSwimlanes[0].swimDepartments) {
        const deptRows = Math.max(1, (dept.roles || []).length);
        if (row >= currentRow && row < currentRow + deptRows) {
          // Check if there's a node in this position
          return this.nodes.some(node => 
            node.col === col && 
            node.row === row
          );
        }
        currentRow += deptRows;
      }
      return false;
    },

    handleWrapperClick(event) {
      console.log('Wrapper click event triggered');
      // Only close if we're not clicking the add-node-hint or the menu itself
      if (event.target.closest('.add-node-hint') || event.target.closest('.node-selector-menu')) {
        console.log('Clicked inside menu or add button, not closing');
        return;
      }
      
      // Close the menu if clicking outside and menu is open
      if (this.showNodeSelector) {
        console.log('Closing node selector from wrapper click');
        this.closeNodeSelector();
      }
      if (this.isEditingTitle && !event.target.closest('.swimlane-title')) {
        this.saveSwimlaneTitle();
      }
    },

    openNodeSelector(col, row) {
      console.log('Grid plus button clicked:', { col, row });
      
      // Prevent event propagation
      event.stopPropagation();
      
      this.selectedCell = { col, row }
      this.$emit('node-selection-request', {
        sourceId: null,
        nodeType: 'grid',
        title: '',
        processId: this.processId,
        gridPosition: { col, row }
      })
    },

    closeNodeSelector() {
      console.log('Closing node selector');
      this.showNodeSelector = false;
      this.selectedCell = null;
      this.sourceNodeId = null;
    },

    handleNodeTypeSelector({ sourceId, position }) {
      // Ensure sourceId is in nodeX format
      this.sourceNodeId = sourceId.startsWith('node') ? sourceId : `node${sourceId}`
      console.log('Setting source node ID:', this.sourceNodeId);
      this.showNodeSelector = true;
      
      // Get the source node's DOM element
      const sourceNode = document.querySelector(`[data-node-id="${this.sourceNodeId}"]`)
      if (sourceNode) {
        const rect = sourceNode.getBoundingClientRect()
        const chartContainer = document.querySelector('.infinite-canvas-container')
        const chartRect = chartContainer.getBoundingClientRect()
        const chartTransform = getComputedStyle(chartContainer).transform
        const matrix = new DOMMatrix(chartTransform)
        
        // Calculate position relative to the chart's transform
        const relativeTop = (rect.top - chartRect.top) / matrix.m11  // Divide by scale
        const relativeLeft = (rect.right - chartRect.left) / matrix.m11  // Divide by scale
        
        this.nodeSelectorPosition = {
          top: `${relativeTop}px`,
          left: `${relativeLeft + 20}px`,
          transform: `scale(${1 / matrix.m11})` // Counter the chart's scale
        }
      } else {
        // Fallback to the provided position if node element not found
        this.nodeSelectorPosition = {
          top: `${position.y}px`,
          left: `${position.x + 20}px`
        }
      }
    },

    updateNodePositionsRecursively(nodeId, xOffset) {
        const node = this.flowData.nodes.find(n => n.id === nodeId)
        if (!node) return

        // Update this node's position
        node.position.x += xOffset

        // If it's a decision node, handle both Yes and No paths
        if (node.type === 'decision') {
            // Find Yes and No connections
            const yesConn = this.flowData.connections.find(c => 
                c.source === nodeId && 
                c.type === 'yes'
            )
            const noConn = this.flowData.connections.find(c => 
                c.source === nodeId && 
                c.type === 'no'
            )

            // Update Yes path
            if (yesConn) {
                const yesNode = this.flowData.nodes.find(n => n.id === yesConn.target)
                if (yesNode) {
                    yesNode.position.x += xOffset
                    // Find and update the chain after Yes node
                    const yesNextConn = this.flowData.connections.find(c => 
                        c.source === yesNode.id && 
                        !c.type
                    )
                    if (yesNextConn) {
                        this.updateNodePositionsRecursively(yesNextConn.target, xOffset)
                    }
                }
            }

            // Update No path
            if (noConn) {
                const noNode = this.flowData.nodes.find(n => n.id === noConn.target)
                if (noNode) {
                    noNode.position.x += xOffset
                    // Find and update the chain after No node
                    const noNextConn = this.flowData.connections.find(c => 
                        c.source === noNode.id && 
                        !c.type
                    )
                    if (noNextConn) {
                        this.updateNodePositionsRecursively(noNextConn.target, xOffset)
                    }
                }
            }
        } else {
            // For non-decision nodes, just follow the chain
        const nextConnection = this.flowData.connections.find(c => 
            c.source === nodeId && 
                !c.type
        )

        // If there's a next node, recursively update its position
        if (nextConnection) {
            this.updateNodePositionsRecursively(nextConnection.target, xOffset)
            }
        }
    },

    addNode(type, gridPosition = null, sourceId = null) {
      console.log('SwimLane addNode called:', { type, gridPosition, sourceId })
      
      if (type === 'connect-exit') {
        this.connectionMode = 'exit'
        this.showNodeSelectionModal = true
        this.showNodeSelector = false
        return
      }
      if (type === 'connect-entrance') {
        this.connectionMode = 'entrance'
        this.showNodeSelectionModal = true
        this.showNodeSelector = false
        return
      }

      // Define cell dimensions at the top of the method
      const cellWidth = 264
      const cellHeight = 250
      const horizontalGap = 100
      const nodeWidth = 200
      const nodeHeight = 100

      // Calculate base position based on whether it's from grid or connection
      let basePosition
      let currentRow = 0  // Initialize currentRow

      if (gridPosition) {
        console.log('Using grid position:', gridPosition)
        const { col, row } = gridPosition
        const adjustedCol = col - 1
        currentRow = row - 1
        basePosition = {
          x: adjustedCol * (cellWidth + horizontalGap) + (cellWidth - nodeWidth) / 2,
          y: currentRow * cellHeight + (cellHeight - nodeHeight) / 2
        }
      } else if (sourceId) {
        console.log('Using source node position from:', sourceId)
        const sourceNode = this.getNodeById(sourceId)
        if (sourceNode) {
          basePosition = {
            x: sourceNode.position.x + (cellWidth + horizontalGap),
            y: sourceNode.position.y
          }
          // Extract row from Y position
          currentRow = Math.floor(sourceNode.position.y / cellHeight)
        }
      }

      console.log('Calculated base position:', basePosition)
      if (!basePosition) {
        console.error('Could not calculate base position')
        return
      }

      // Get next available node ID
      const getNextNodeId = () => {
        const nodeIds = this.flowData.nodes.map(n => {
          const match = n.id.match(/\d+/)
          return match ? parseInt(match[0]) : 0
        })
        return Math.max(0, ...nodeIds) + 1
      }

      const newId = getNextNodeId()
      console.log('Generated new node ID:', newId)

      // Add the new node
      const newNode = {
        id: `node${newId}`,
        title: type === 'finish' ? 'Finish' : 'Pending Step',
        position: basePosition,
        type: type
      }
      console.log('Adding new NODE:', newNode)
      this.flowData.nodes.push(newNode)

      // Create connection from source node if sourceId is provided
      if (sourceId) {
        console.log('Creating connection from source:', sourceId)
        const existingSourceExit = this.flowData.connections.find(
          c => c.source === sourceId
        )

        if (existingSourceExit) {
          console.log('Found existing connection:', existingSourceExit)
          // Handle existing connection chain
          const targetNode = this.flowData.nodes.find(n => n.id === existingSourceExit.target)
          
          // If we're adding a decision node, we'll connect the existing target to the Yes node
          if (type === 'decision') {
            const yesNodeId = `node${newId + 1}`  // Yes node ID
            
            // Move the target node and all subsequent nodes to the right of Yes node
            if (targetNode) {
              const xOffset = 2 * (cellWidth + horizontalGap)  // Two steps to the right
              this.updateNodePositionsRecursively(targetNode.id, xOffset)
              targetNode.position.y = currentRow === 0 ? basePosition.y : basePosition.y - cellHeight
            }

            // Update the connection to point from Yes to the old target
            this.flowData.connections = this.flowData.connections.filter(
              c => c.id !== existingSourceExit.id
            )
            
            // Add connection from source to decision
            this.flowData.connections.push({
              id: `conn${this.getNextConnId()}`,
              source: sourceId,
              target: `node${newId}`  // Connect to decision node
            })

            if (targetNode) {
              // Add connection from Yes to target
              this.flowData.connections.push({
                id: `conn${this.getNextConnId()}`,
                source: yesNodeId,  // Connect from Yes node
                target: targetNode.id
              })
            }
          } else {
            // Non-decision node handling
            if (targetNode) {
              const xOffset = cellWidth + horizontalGap
              this.updateNodePositionsRecursively(targetNode.id, xOffset)
            }
            existingSourceExit.target = `node${newId}`
            this.flowData.connections.push({
              id: `conn${this.getNextConnId()}`,
              source: `node${newId}`,
              target: targetNode.id
            })
          }
        } else {
          console.log('No existing connection found, creating new connection from source:', sourceId, 'to new node:', `node${newId}`)
          if (sourceId) {
            this.flowData.connections.push({
              id: `conn${this.getNextConnId()}`,
              source: sourceId,
              target: `node${newId}`
            })
          }
        }
      } else {
        console.log('No sourceId provided, skipping connection creation')
      }

      // If it's a decision node, add Yes/No nodes with appropriate titles
      if (type === 'decision') {
        // Get total rows
        const totalRows = this.totalGridRows
        const lastRow = totalRows - 1
        
        // Add "Yes" node
        const yesId = `node${newId + 1}`
        this.flowData.nodes.push({
          id: yesId,
          title: 'Yes',
          position: {
            x: basePosition.x + (cellWidth + horizontalGap),
            y: currentRow === 0 ? basePosition.y : basePosition.y - cellHeight
          },
          type: 'activity'
        })

        // Add "No" node
        const noId = `node${newId + 2}`
        this.flowData.nodes.push({
          id: noId,
          title: 'No',
          position: {
            x: basePosition.x + (cellWidth + horizontalGap),
            y: currentRow === lastRow ? basePosition.y : basePosition.y + cellHeight
          },
          type: 'activity'
        })

        // Add connections from decision to Yes/No nodes
        this.flowData.connections.push({
          id: `conn${this.getNextConnId()}`,
          source: `node${newId}`,
          target: yesId,
          type: 'yes'
        })

        this.flowData.connections.push({
          id: `conn${this.getNextConnId()}`,
          source: `node${newId}`,
          target: noId,
          type: 'no'
        })
      }

      // Update step names to ensure proper numbering
      console.log('Updating step names')
      this.updateStepNames()

      // Clean up flowData by removing any undefined or null values
      const cleanedFlowData = {
        nodes: this.flowData.nodes.filter(Boolean),
        connections: this.flowData.connections.filter(Boolean)
      }

      // Persist changes to store
      this.$store.dispatch('swimlane/updateProcessFlowData', {
        processId: this.processId,
        flowData: cleanedFlowData
      })

      this.showNodeSelector = false
      this.sourceNodeId = null
    },

    getConnectionStartX(connection) {
      const sourceNode = this.getNodeById(connection.source)
      if (sourceNode.type === 'decision') {
        if (connection.type === 'yes') {
          return sourceNode.position.x + 160  // Full width of diamond
        } else if (connection.type === 'no') {
          return sourceNode.position.x + 160  // Full width of diamond
        }
      }
      return sourceNode.position.x + 200
    },

    getConnectionStartY(connection) {
      const sourceNode = this.getNodeById(connection.source)
      if (sourceNode.type === 'decision') {
        if (connection.type === 'yes') {
          return sourceNode.position.y + 5
        } else if (connection.type === 'no') {
          return sourceNode.position.y + 90
        }
      }
      return sourceNode.position.y + 50
    },

    getConnectionEndX(connection) {
      const targetNode = this.getNodeById(connection.target)
      if (targetNode.type === 'decision') {
        return targetNode.position.x + 20  // Offset slightly to hit the left vertex
      }
      return targetNode.position.x
    },

    getConnectionEndY(connection) {
      const targetNode = this.getNodeById(connection.target)
      if (targetNode.type === 'decision') {
        return targetNode.position.y + 50
      }
      return targetNode.position.y + 50
    },

    openDepartmentModal(index) {
      // Emit event to parent with department details and index
      this.$emit('department-modal-request', {
        processId: this.processId,
        departmentIndex: index
      });
    },

    handleNewDepartment(name) {
      const swimlane = this.departmentSwimlanes[0]  // Get the current swimlane
      if (!swimlane) return

      const newDept = {
        id: `dept_${Date.now()}`,
        name: name.toUpperCase(),
        isLightTheme: false
      }
      
      this.$store.dispatch('swimlane/addDepartment', {
        swimlaneId: this.processId,
        department: newDept,
        index: this.departmentData?.departmentIndex + 1  // Add after the clicked department
      })
    },

    startDepartmentDrag(index, event) {
      this.draggedDeptIndex = index
      event.dataTransfer.effectAllowed = 'move'
    },

    dragEnterDepartment(index) {
      if (index !== this.draggedDeptIndex) {
        this.dragOverIndex = index
      }
    },

    async dropDepartment(dropIndex) {
      if (this.draggedDeptIndex === null || dropIndex === this.draggedDeptIndex) {
        this.dragOverIndex = null;
        return;
      }

      try {
        // Dispatch the reorderDepartments action
        await this.$store.dispatch('swimlane/reorderDepartments', {
          processId: this.processId,
          fromIndex: this.draggedDeptIndex,
          toIndex: dropIndex
        });
      } catch (error) {
        console.error('Error reordering departments:', error);
      }

      // Reset drag state
      this.draggedDeptIndex = null;
      this.dragOverIndex = null;
    },

    updateNodeDescription(nodeId, description) {
      const node = this.flowData.nodes.find(n => n.id === nodeId)
      if (node) {
        node.description = description
      }
    },
    
    handleNodeConnection(selectedNode) {
      const sourceNode = this.getNodeById(this.sourceNodeId)
      const targetNode = selectedNode

      if (!sourceNode || !targetNode) {
        console.error('Source or target node not found')
        return
      }

      // Get fresh flowData from store
      const flowData = this.$store.getters['swimlane/getProcessFlowData'](this.processId)
      if (!flowData) {
        console.error('Flow data not found')
        return
      }
      this.flowData = flowData

      console.log('Connecting nodes:', {
        source: sourceNode.title,
        target: targetNode.title,
        mode: this.connectionMode
      });

      // Prevent connecting FROM finish nodes only when trying to connect their exit
      if (sourceNode.type === 'finish' && this.connectionMode === 'exit') {
        console.warn('Cannot connect from a finish node\'s exit')
        this.showNodeSelectionModal = false
        return
      }

      // When connecting TO a finish node, always treat it as an entrance connection
      if (targetNode.type === 'finish') {
        // Add connection
        const connection = {
          id: Math.max(...this.connections.map(c => c.id)) + 1,
          source: sourceNode.id,
          target: targetNode.id
        }
        this.flowData.connections.push(connection)
        
        // Update the finish node's inputs array
        if (!targetNode.connections) {
          targetNode.connections = { inputs: [], outputs: [] }
        }
        if (!targetNode.connections.inputs.includes(sourceNode.id)) {
          targetNode.connections.inputs.push(sourceNode.id)
        }
        
        this.showNodeSelectionModal = false
        this.sourceNodeId = null
        this.connectionMode = null
        return
      }

      // For all other connections, proceed with normal connection logic
      if (this.connectionMode === 'exit') {
        // Find any node that connects to the target (previous node in chain)
        const targetEntranceConnection = this.flowData.connections.find(
          c => c.target === targetNode.id
        )

        // Find any node that the target connects to (next node in chain)
        const targetExitConnection = this.flowData.connections.find(
          c => c.source === targetNode.id
        )

        // Find the Start node's connection
        const startNode = this.flowData.nodes.find(n => n.title === 'Start')
        const startConnection = startNode ? this.flowData.connections.find(
          c => c.source === startNode.id
        ) : null;

        // Remove connections but preserve Start connection
        this.flowData.connections = this.flowData.connections.filter(c => 
          (c.source === startNode?.id) || (c.source !== sourceNode.id && c.target !== targetNode.id)
        )

        // Connect source to target
        this.flowData.connections.push({
          id: Math.max(...this.connections.map(c => c.id)) + 1,
          source: sourceNode.id,
          target: targetNode.id
        })

        // If target had a next node, maintain that connection
        if (targetExitConnection) {
          this.flowData.connections.push({
            id: Math.max(...this.connections.map(c => c.id)) + 1,
            source: targetNode.id,
            target: targetExitConnection.target
          })
        }
      } else {
        // Connecting an entrance
        console.log('Connecting entrance:', {
          source: { id: sourceNode.id, title: sourceNode.title },
          target: { id: targetNode.id, title: targetNode.title }
        });

        // Find any node that the source connects to (next node in chain)
        const sourceExitConnection = this.flowData.connections.find(
          c => c.source === sourceNode.id && !c.type
        )
        console.log('Source exit connection:', sourceExitConnection)

        // Find any node that the target connects to (next node in chain)
        const targetExitConnection = this.flowData.connections.find(
          c => c.source === targetNode.id && !c.type
        )
        console.log('Target exit connection:', targetExitConnection)

        // Find the Start node's connection and preserve it
        const startNode = this.flowData.nodes.find(n => n.title === 'Start')
        const startConnection = startNode ? this.flowData.connections.find(
          c => c.source === startNode.id
        ) : null

        // Find and preserve decision node connections
        const decisionConnections = this.flowData.connections.filter(conn => {
          const connSourceNode = this.getNodeById(conn.source)
          const isSourceNodeDecision = connSourceNode && connSourceNode.type === 'decision'
          return isSourceNodeDecision && 
                 (conn.type === 'yes' || conn.type === 'no') &&
                 (!sourceNode || connSourceNode.id !== sourceNode.id) // Exclude source node's connections if it exists
        })

        // Find if source or target are part of a decision branch
        const findDecisionBranch = (nodeId) => {
          // First check if the node is directly connected to a decision
          const directYesConnection = decisionConnections.find(c => c.type === 'yes' && c.target === nodeId)
          const directNoConnection = decisionConnections.find(c => c.type === 'no' && c.target === nodeId)
          
          if (directYesConnection) return { type: 'yes', decisionId: directYesConnection.source }
          if (directNoConnection) return { type: 'no', decisionId: directNoConnection.source }

          // If not directly connected, trace back through the chain
          const findPreviousNode = (currentId) => {
            const connection = this.flowData.connections.find(c => c.target === currentId && !c.type)
            if (!connection) return null
            return connection.source
          }

          // Trace back through the chain until we find a Yes/No node
          let currentId = nodeId
          while (currentId) {
            const node = this.getNodeById(currentId)
            if (node && (node.title === 'Yes' || node.title === 'No')) {
              const yesConn = decisionConnections.find(c => c.type === 'yes' && c.target === currentId)
              const noConn = decisionConnections.find(c => c.type === 'no' && c.target === currentId)
              if (yesConn) return { type: 'yes', decisionId: yesConn.source }
              if (noConn) return { type: 'no', decisionId: noConn.source }
            }
            currentId = findPreviousNode(currentId)
          }
          return null
        }

        const sourceBranch = findDecisionBranch(sourceNode.id)
        const targetBranch = findDecisionBranch(targetNode.id)
        console.log('Source branch info:', sourceBranch)
        console.log('Target branch info:', targetBranch)

        // Special handling for connecting between branches of the same decision
        if (sourceBranch && targetBranch && sourceBranch.decisionId === targetBranch.decisionId) {
          // Find all nodes in the source branch (No branch)
          const findBranchChain = (startId, branchType) => {
            const chain = []
            let currentId = startId
            while (currentId) {
              const node = this.getNodeById(currentId)
              if (!node) break
              chain.push(node)
              const nextConn = this.flowData.connections.find(
                c => c.source === currentId && !c.type
              )
              currentId = nextConn ? nextConn.target : null
            }
            return chain
          }

          const sourceBranchChain = findBranchChain(sourceNode.id, sourceBranch.type)
          console.log('Source branch chain:', sourceBranchChain)

          // Find the Yes/No nodes
          const yesNode = this.flowData.nodes.find(n => n.title === 'Yes')
          const noNode = this.flowData.nodes.find(n => n.title === 'No')

          // Remove all connections in the source branch chain
          this.flowData.connections = this.flowData.connections.filter(c => {
            // Keep decision node's Yes/No connections
            if (c.source === sourceBranch.decisionId && (c.type === 'yes' || c.type === 'no')) return true
            
            // Keep connections not involving our chain
            return !sourceBranchChain.some(node => 
              c.source === node.id || c.target === node.id
            )
          })

          // Connect target to source
          const newConnection = {
            id: Math.max(...this.connections.map(c => c.id)) + 1,
            source: targetNode.id,
            target: sourceNode.id
          }
          this.flowData.connections.push(newConnection)

          // Update titles for all nodes in the chain
          sourceBranchChain.forEach(node => {
            if (node.title !== 'Yes' && node.title !== 'No') {
              const baseTitle = node.title.replace(/[YN]$/, '')
              node.title = baseTitle + 'Y'
            }
          })

          // Update step names after modifying connections
          this.updateStepNames()
          return
        }

        // Find all nodes in source's branch that need to be preserved
        const findBranchNodes = (startId, branchType) => {
          const nodes = []
          let currentId = startId
          while (currentId) {
            const node = this.getNodeById(currentId)
            if (node) nodes.push(node)
            const nextConn = this.flowData.connections.find(
              c => c.source === currentId && !c.type
            )
            currentId = nextConn ? nextConn.target : null
          }
          return nodes
        }

        // Get all nodes in the source's branch
        const branchNodes = sourceBranch ? findBranchNodes(sourceNode.id, sourceBranch.type) : []

        // Store the chain of connections we need to maintain
        const chainToPreserve = []
        for (const node of branchNodes) {
          const nextConn = this.flowData.connections.find(
            c => c.source === node.id && !c.type
          )
          if (nextConn) chainToPreserve.push(nextConn)
        }
        console.log('Chain to preserve:', chainToPreserve)

        // Remove all non-start and non-decision connections that we'll be modifying
        this.flowData.connections = this.flowData.connections.filter(c => {
          // Always preserve the Start node's connection
          if (startNode && c.source === startNode.id) return true
          
          // Preserve decision branch connections (Yes/No from decision)
          if (decisionConnections.some(dc => dc.id === c.id)) return true
          
          // Don't remove connections that aren't part of our changes
          return c.source !== sourceNode.id && 
                 c.target !== sourceNode.id && 
                 c.source !== targetNode.id && 
                 c.target !== targetNode.id &&
                 !chainToPreserve.some(pc => pc.source === c.source && pc.target === c.target)
        })

        // Connect target to source
        const newConnection = {
          id: Math.max(...this.connections.map(c => c.id)) + 1,
          source: targetNode.id,
          target: sourceNode.id
        }
        this.flowData.connections.push(newConnection)

        // If target had an outgoing connection, connect source to it
        if (targetExitConnection) {
          const newOutgoingConnection = {
            id: Math.max(...this.connections.map(c => c.id)) + 1,
            source: sourceNode.id,
            target: targetExitConnection.target
          }
          this.flowData.connections.push(newOutgoingConnection)
        }

        // Reconnect the preserved chain
        chainToPreserve.forEach(conn => {
          this.flowData.connections.push({
            id: Math.max(...this.connections.map(c => c.id)) + 1,
            source: conn.source,
            target: conn.target
          })
        })

        // If source is part of a decision branch, maintain its branch suffix
        if (sourceBranch) {
          // Update all nodes in the branch to maintain their suffixes
          branchNodes.forEach(node => {
            if (node.title !== 'Yes' && node.title !== 'No') {
              const suffix = sourceBranch.type === 'yes' ? 'Y' : 'N'
              const baseTitle = node.title.replace(/[YN]$/, '')
              node.title = baseTitle + suffix
            }
          })
        }

        // Update step names after modifying connections
        this.updateStepNames()
      }

      this.showNodeSelectionModal = false
      this.sourceNodeId = null
      this.connectionMode = null
    },
    
    updateStepNames() {
      // Find the start node
      const startNode = this.flowData.nodes.find(n => n.title === 'Start');
      if (!startNode) return;

      // Reset all non-special nodes to 'Pending Step'
      this.flowData.nodes.forEach(node => {
        if (node.title !== 'Start' && 
            node.title !== 'Yes' && 
            node.title !== 'No') {
          if (node.type === 'finish') {
            // For finish nodes, just reset to 'Finish'
            node.title = 'Finish';
          } else {
            node.title = 'Pending Step';
          }
        }
      });

      let globalStepCount = 1;
      const visited = new Set();
      const orderedNodes = [];

      const processPath = (currentId, branchInfo = null) => {
        if (!currentId || visited.has(currentId)) return;
        
        const node = this.flowData.nodes.find(n => n.id === currentId);
        if (!node) return;

        visited.add(currentId);
        
        if (node.title === 'Start') {
          // Skip start node for step counting, just process its next connection
          const nextConn = this.flowData.connections.find(
            c => c.source === currentId && c.type !== 'yes' && c.type !== 'no'
          );
          if (nextConn) {
            processPath(nextConn.target, branchInfo);
          }
        } else if (node.type === 'decision') {
          // For decision nodes, process both yes and no paths
          const yesConn = this.flowData.connections.find(
            c => c.source === currentId && c.type === 'yes'
          );
          const noConn = this.flowData.connections.find(
            c => c.source === currentId && c.type === 'no'
          );
          
          // Add decision node to ordered list before processing branches
          if (node.title.startsWith('Pending')) {
            // If we're in a branch, use the branch's numbering
            if (branchInfo) {
              orderedNodes.push({ 
                node, 
                stepNumber: branchInfo.stepNumber + 1
              });
              // Update branchInfo step number for subsequent nodes
              branchInfo.stepNumber++;
            } else {
              orderedNodes.push({ node, stepNumber: globalStepCount });
              globalStepCount++;
            }
          }
          
          // Get the current step number for branching
          const currentStepNumber = branchInfo ? branchInfo.stepNumber : globalStepCount - 1;
          
          if (yesConn) {
            processPath(yesConn.target, { 
              type: 'Y', 
              stepNumber: currentStepNumber,
              parentDecision: node.id 
            });
          }
          if (noConn) {
            processPath(noConn.target, { 
              type: 'N', 
              stepNumber: currentStepNumber,
              parentDecision: node.id 
            });
          }
        } else if (node.title === 'Yes' || node.title === 'No') {
          // Skip Yes/No nodes for step counting, just process their next connections
          const nextConn = this.flowData.connections.find(
            c => c.source === currentId && c.type !== 'yes' && c.type !== 'no'
          );
          if (nextConn) {
            processPath(nextConn.target, branchInfo);
          }
        } else {
          // For regular nodes and finish nodes
          if (node.title === 'Pending Step' || (node.type === 'finish' && !node.title.startsWith('Finish'))) {
            // If we're in a branch, use the branch's step number
            if (branchInfo) {
              orderedNodes.push({ 
                node, 
                branchType: branchInfo.type,
                stepNumber: branchInfo.stepNumber + 1,
                parentDecision: branchInfo.parentDecision
              });
              // Update branchInfo step number
              branchInfo.stepNumber++;
            } else {
              // If not in a branch, use and increment the global counter
              orderedNodes.push({ node, stepNumber: globalStepCount });
              globalStepCount++;
            }
          }
          
          // Process next node in chain
          const nextConn = this.flowData.connections.find(
            c => c.source === currentId && c.type !== 'yes' && c.type !== 'no'
          );
          if (nextConn) {
            processPath(nextConn.target, branchInfo);
          }
        }
      };

      // Start processing from the start node
      const firstConn = this.flowData.connections.find(
        c => c.source === startNode.id
      );
      
      if (firstConn) {
        processPath(firstConn.target);
      }

      // Update titles for nodes in order
      let currentStep = 1;
      orderedNodes.forEach((item) => {
        const stepNumber = item.stepNumber || currentStep;
        const suffix = item.branchType || '';
        
        // Special handling for finish nodes
        if (item.node.type === 'finish') {
          item.node.title = 'Finish';
        } else {
          item.node.title = `Step ${stepNumber}${suffix}`;
        }
        
        // Only increment the current step if we're not in a branch
        if (!item.branchType) {
          currentStep++;
        }
      });

      // Ensure Start node keeps its title
      startNode.title = 'Start';

      // Process any remaining unvisited nodes
      this.flowData.nodes.forEach(node => {
        if (node.title === 'Pending Step') {
          if (node.type === 'finish') {
            node.title = 'Finish';
          } else {
            node.title = `Step ${currentStep++}`;
          }
        }
      });
    },

    addGridRow(departmentIndex) {
      const swimlane = this.departmentSwimlanes[0]
      if (!swimlane) return

      const department = swimlane.swimDepartments[departmentIndex]
      if (!department) return

      // Create a new role
      const newRole = {
        name: `Role ${(department.roles?.length || 0) + 1}`
      }

      // Dispatch action to add role
      this.$store.dispatch('swimlane/addRole', {
        swimlaneId: swimlane.id,
        departmentId: department.id,
        role: newRole
      })
    },

    startEditingRole(departmentId, role) {
      role.isEditing = true;
      role.editedName = role.name;
      this.$nextTick(() => {
        const input = this.$refs.roleNameInput;
        if (input && input.length > 0) {
          input[input.length - 1].focus();
          input[input.length - 1].select();
        }
      });
    },

    saveRoleName(departmentId, role) {
      role.isEditing = false;
      const newName = role.editedName.trim();
      if (newName && newName !== role.name) {
        this.$store.dispatch('swimlane/updateRoleName', {
          swimlaneId: this.departmentSwimlanes[0].id,
          departmentId: departmentId,
          roleId: role.id,
          newName: newName
        });
      }
      delete role.editedName;
    },

    cancelEditRole(role) {
      role.isEditing = false;
      delete role.editedName;
    },

    removeRole(departmentId, roleId) {
      console.log('Removing role:', { departmentId, roleId });
      this.$store.dispatch('swimlane/removeRole', {
        swimlaneId: this.processId,
        departmentId,
        roleId
      });
    },

    startEditingTitle(swimlane, index) {
      this.isEditingTitle = true;
      this.editingSwimlaneId = swimlane.id;
      this.editedTitle = swimlane.name;
      this.$nextTick(() => {
        if (this.titleInput) {
          this.titleInput.focus();
          this.titleInput.select();
        }
      });
    },

    async saveSwimlaneTitle() {
      if (this.isEditingTitle && this.editingSwimlaneId) {
        const newTitle = this.editedTitle.trim();
        if (newTitle) {
          try {
            await this.$store.dispatch('swimlane/updateProcessName', {
              processId: this.processId,
              newName: newTitle
            });
            // Update local state
            this.$emit('update:processName', newTitle);
          } catch (error) {
            console.error('Error saving swimlane title:', error);
          }
        }
        this.isEditingTitle = false;
        this.editingSwimlaneId = null;
        this.titleInput = null;
      }
    },

    cancelEditTitle() {
      this.isEditingTitle = false;
      this.editingSwimlaneId = null;
      this.editedTitle = '';
      this.titleInput = null;
    },

    showDeleteDepartmentModal(index) {
      if (this.departmentSwimlanes[0].swimDepartments.length <= 1) return;
      console.log('Opening delete department modal:', { index });
      // Emit the delete event to InfiniteCanvas
      const department = this.departmentSwimlanes[0].swimDepartments[index];
      this.$emit('node-delete-request', {
        nodeId: department.id,
        title: 'Department',
        type: 'Department',
        processId: this.processId,
        departmentId: department.id,
        departmentIndex: index
      });
    },

    showDeleteRoleModal(deptId, roleId) {
      console.log('Opening delete role modal:', { deptId, roleId });
      // Emit the delete event to InfiniteCanvas
      this.$emit('node-delete-request', {
        nodeId: roleId,
        title: 'Role',
        type: 'Role',
        processId: this.processId,
        departmentId: deptId
      });
    },

    showDeleteNodeModal(nodeId) {
      console.log('SwimLane: showDeleteNodeModal called with nodeId:', nodeId);
      const node = this.getNodeById(nodeId);
      console.log('SwimLane: Found node:', node);
      
      // Emit the delete event to InfiniteCanvas
      this.$emit('node-delete-request', {
        nodeId: nodeId,
        title: node?.title,
        type: node?.type,
        processId: this.processId
      });
    },

    handleInfoClick() {
      // Emit event to parent with nodes and connections
      this.$emit('show-steps-list', {
        nodes: this.nodes,
        connections: this.flowData.connections
      });
      // Print exact flow
      this.printExactFlow();
    },

    printExactFlow() {
      // Find the start node
      const startNode = this.flowData.nodes.find(n => n.title === 'Start');
      if (!startNode) {
        // console.log('EXACT NODES: No start node found');
        return;
      }

      const visitedNodes = new Set();
      const exactFlow = [];

      const processNode = (node, depth = 0, branchType = '') => {
        if (visitedNodes.has(node.id)) return;
        visitedNodes.add(node.id);

        // Add node to flow with proper indentation
        const indent = '  '.repeat(depth);
        exactFlow.push(`${indent}${node.title}${branchType ? ` (${branchType})` : ''}`);

        // If it's a decision node, process yes and no branches
        if (node.type === 'decision') {
          // Find yes branch
          const yesConn = this.flowData.connections.find(c => 
            c.source === node.id && 
            c.type === 'yes'
          );
          if (yesConn) {
            const yesNode = this.flowData.nodes.find(n => n.id === yesConn.target);
            if (yesNode && yesNode.title === 'Yes') {
              // Find the next node after Yes
              const nextYesConn = this.flowData.connections.find(c => 
                c.source === yesNode.id && 
                !c.type
              );
              if (nextYesConn) {
                const nextYesNode = this.flowData.nodes.find(n => n.id === nextYesConn.target);
                if (nextYesNode) {
                  processNode(nextYesNode, depth + 1, 'Yes Path');
                }
              }
            

          // Find no branch
          const noConn = this.flowData.connections.find(c => 
            c.source === node.id && 
            c.type === 'no'
          );
          if (noConn) {
            const noNode = this.flowData.nodes.find(n => n.id === noConn.target);
            if (noNode && noNode.title === 'No') {
              // Find the next node after No
              const nextNoConn = this.flowData.connections.find(c => 
                c.source === noNode.id && 
                !c.type
              );
              if (nextNoConn) {
                const nextNoNode = this.flowData.nodes.find(n => n.id === nextNoConn.target);
                if (nextNoNode) {
                  processNode(nextNoNode, depth + 1, 'No Path');
                }
              }
            }
          }
        } else {
          // For non-decision nodes, follow the next connection
          const nextConn = this.flowData.connections.find(c => 
            c.source === node.id && 
            !c.type
          );
          if (nextConn) {
            const nextNode = this.flowData.nodes.find(n => n.id === nextConn.target);
            if (nextNode) {
              processNode(nextNode, depth, branchType);
            }
          }
        }
      }
    }
    };

      // Start processing from the start node
      processNode(startNode);

      // Print the exact flow
      // console.log('EXACT NODES:');
      exactFlow.forEach(line => console.log(line));
    },

    handleDeleteClick() {
      this.$emit('delete-process', this.processId);
    },

    handleNodeSelectionRequest(data) {
      this.$emit('node-selection-request', {
        ...data,
        processId: this.processId
      })
    },

    getNextConnId() {
      const connIds = this.flowData.connections.map(c => {
        // Handle both string and number IDs
        if (typeof c.id === 'string') {
          const match = c.id.match(/\d+/);
          return match ? parseInt(match[0]) : 0;
        }
        return typeof c.id === 'number' ? c.id : 0;
      });
      return Math.max(0, ...connIds) + 1;
    },

    confirmDelete() {
      console.log('Confirming deletion:', {
        type: this.deleteModalType,
        target: this.deleteTarget,
        targetId: this.deleteTargetId
      });

      if (this.deleteModalType === 'Role') {
        console.log('Removing role:', {
          departmentId: this.deleteTarget,
          roleId: this.deleteTargetId
        });
        this.removeRole(this.deleteTarget, this.deleteTargetId);
      } else if (this.deleteModalType === 'Department') {
        console.log('Removing department:', this.deleteTarget);
        this.removeDepartment(this.deleteTarget);
      }

      // Reset and close modal
      this.showDeleteModal = false;
      this.deleteModalType = null;
      this.deleteTarget = null;
      this.deleteTargetId = null;
    },
  },
  created() {
    // Initialize position with default values if initialGridPosition is not provided
    // console.log('[SwimLane] created - initialGridPosition:', this.initialGridPosition)
    this.position = {
      x: this.initialGridPosition?.x || 0,
      y: this.initialGridPosition?.y || 0
    }
    // console.log('[SwimLane] created - initialized position:', this.position)
  },
  mounted() {
    // console.log('[SwimLane] mounted - Component props:', {
    //   processId: this.processId,
    //   processName: this.processName,
    //   scale: this.scale
    // })
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.stopDrag)
  },
  watch: {
    flowData: {
      handler(newData) {
        // console.log('[SwimLane] Flow data updated:', {
        //   processId: this.processId,
        //   nodes: newData?.nodes?.length,
        //   connections: newData?.connections?.length,
        //   nodes: newData?.nodes
        // })
      },
      deep: true
    },
    departmentSwimlanes: {
      handler(newSwimlanes) {
        // console.log('[SwimLane] Department swimlanes updated:', {
        //   processId: this.processId,
        //   swimlaneCount: newSwimlanes?.length,
        //   departments: newSwimlanes?.[0]?.swimDepartments?.length
        // })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.swimlane-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.swimlane-container {
  min-height: v-bind('containerHeight');
  height: auto;
  padding: 2rem 4rem 4rem 4rem;
  background-color: var(--primary-background-cream);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin: 0;
  overflow: visible;
  position: relative;
  user-select: none;
  touch-action: none;
  transform-origin: center;
  will-change: transform;
  transition: none;
  width: fit-content;
  z-index: 2;
}

h1 {
  color: var(--main-text-color);
  margin-bottom: 2rem;
  text-align: center;
}

.swimlane-chart {
  background-color: var(--accent-victory-green);
  border-radius: 8px;
  padding: 0;
  display: flex;
  min-height: v-bind('chartHeight');
  height: auto;
  overflow: visible;
  position: relative;
  min-width: fit-content;
  width: 100%;
  transform-origin: center;
}

.departments-column {
  width: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: v-bind('chartHeight');
  gap: 0;
  margin-right: 50px;
  position: relative;
  z-index: 1000;
  pointer-events: all;
}

.department {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.department-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 10px 0;
  align-items: center;
  width: 40px; /* Fixed width for department header */
}

.department-header {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  /* padding: 10px; */
  font-weight: bold;
  min-width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  margin-left: 10px;
}

.department-roles-column {
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  margin-left: 25px;
}

.department-roles {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.role {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9em;
  height: 250px;
  width: 100%;
}

.role-name {
  font-weight: 500;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.role-name:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.role-name-input {
  font-weight: 500;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  text-align: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  padding: 4px;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  width: auto;
}

.role-name-input:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.department-edge {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  pointer-events: auto;
}

.hover-area {
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  bottom: 0;
  transform: translateY(50%);
  cursor: pointer;
  pointer-events: auto;
}

.department-buttons {
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px;
  border-radius: 4px;
  z-index: 1001;
  pointer-events: auto;
}

.hover-area:hover + .department-buttons,
.department-buttons:hover {
  display: flex !important;
}

.dept-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: background-color 0.2s;
}

.dept-btn.add-btn {
  background-color: var(--accent-victory-green);
}

.dept-btn.add-btn:hover {
  background-color: #0D4E36;
}

.dept-btn.remove-btn {
  background-color: #dc3545;
}

.dept-btn.remove-btn:hover {
  background-color: #bd2130;
}

.dept-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.department-hover-circle {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #dc3545;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1001;
}

.department:hover .department-hover-circle {
  opacity: 1;
}

.plus-symbol {
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
}

.grid-container {
  display: flex;
  flex-grow: 0;
  height: v-bind('chartHeight');
  gap: 0 100px;
  position: relative;
  z-index: 1;
  width: v-bind('gridWidth');
  min-width: fit-content;
  pointer-events: auto;
  cursor: default;
}

.grid-column {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  gap: 0;
  width: 264px;
  flex: 0 0 264px;
  position: relative;
  z-index: 2;
}

.grid-cell {
  width: 250px;
  height: 250px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  transition: background-color 0.2s;
}

.grid-cell:last-child {
  border-bottom: none;
}

.grid-column:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.grid-cell.drag-over {
  background-color: rgba(255, 255, 255, 0.1);
}

.add-node-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--accent-victory-green);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0.6;
  transition: opacity 0.2s;
  cursor: pointer;
}

.add-node-hint:hover {
  opacity: 1;
}

.node-selector-menu {
  position: absolute;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  user-select: none;
  pointer-events: auto;
  transform: none !important;
}

.node-selector-header {
  padding: 8px 12px;
  background-color: rgb(0, 51, 37);
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.node-selector-options {
  padding: 8px;
}

.node-type-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 4px;
}

.node-type-btn:hover {
  background-color: #f0f0f0;
}

.department {
  height: 250px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: grab;
  transition: transform 0.2s, box-shadow 0.2s;
}

.department:active {
  cursor: grabbing;
}

.department.drag-over {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.role-content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.role-delete-btn {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #dc3545;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s, background-color 0.2s;
  z-index: 1000;
}

.role:hover .role-delete-btn {
  opacity: 1;
}

.role-delete-btn:hover {
  background-color: #bd2130;
}

.role-delete-btn i {
  font-size: 12px;
}

.swimlane-title {
  margin-bottom: 2rem;
  text-align: center;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.swimlane-name {
  color: var(--main-text-color);
  font-size: 3rem;  /* Increased from 2rem */
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  padding: 8px 16px;  /* Increased padding for better proportions */
  border-radius: 4px;
  transition: background-color 0.2s;
}

.swimlane-name:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.swimlane-name-input {
  color: var(--main-text-color);
  font-size: 3rem;  /* Increased from 2rem to match swimlane-name */
  font-weight: bold;
  background: transparent;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;  /* Increased padding to match swimlane-name */
  outline: none;
  text-align: center;
  width: 100%;
  max-width: 600px;  /* Increased from 500px to accommodate larger text */
}

.swimlane-name-input:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.info-btn {
  background: none;
  border: none;
  color: var(--accent-victory-green);
  font-size: 3em;  /* Increased from 1.2em to 3em (2.5 times bigger) */
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 50%;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;  /* Increased to accommodate larger icon */
  height: 48px;     /* Increased to accommodate larger icon */
}

.info-btn:hover {
  background-color: rgba(0, 51, 37, 0.1);
  transform: scale(1.1);
}

.delete-swimlane-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px; /* Increased padding */
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1000;
}

.swimlane-container:hover .delete-swimlane-wrapper {
  opacity: 1;
}

.delete-swimlane-btn {
  width: 48px; /* Increased from 32px */
  height: 48px; /* Increased from 32px */
  border-radius: 50%;
  background-color: var(--accent-victory-green);
  color: white;
  font-size: 36px; /* Increased from 24px */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  border: none;
  line-height: 1;
}

.delete-swimlane-btn:hover {
  background-color: #dc3545;
}

.add-node-hint:hover {
  opacity: 1;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.modal-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-btn {
  background: #f0f0f0;
  color: var(--main-text-color);
}

.cancel-btn:hover {
  background: #e0e0e0;
}

.submit-btn {
  background: var(--accent-victory-green);
  color: white;
}

.submit-btn:hover {
  background: var(--secondary-background-sky-blue);
}

.process-container {
  margin-bottom: 2rem;
  position: relative;
  transform-origin: 0 0;
}

.grid-visualization {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.grid-line {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.grid-line.vertical {
  width: 1px;
}

.grid-line.horizontal {
  height: 1px;
}

.occupied-cells-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.grid-cell-occupied {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.3);
  border: 2px solid rgba(255, 0, 0, 0.5);
  pointer-events: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
}

.modal h3 {
  margin-top: 0;
  color: var(--main-text-color);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-btn {
  padding: 0.5rem 1rem;
  border: 1px solid var(--accent-victory-green);
  background: none;
  color: var(--accent-victory-green);
  border-radius: 4px;
  cursor: pointer;
}

.delete-btn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #dc3545;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: rgba(0, 51, 37, 0.1);
}

.delete-btn:hover {
  background-color: #bd2130;
}
</style>
