<template>
  <div v-show="isOpen" class="drawer-container">
    <div class="modal-overlay" @click="handleClose"></div>
    <transition name="slide">
      <div v-if="isVisible" class="steps-list-drawer">
        <div class="node-selector-header">
          <div class="header-content">
            <span>Process Steps</span>
            <button class="close-btn" @click="handleClose">←</button>
          </div>
        </div>
        <div class="node-selector-options" @wheel.stop>
          <div class="steps-list">
            <template v-if="processedNodes.length > 0">
              <template v-for="item in processedNodes" :key="item.id">
                <!-- Regular step -->
                <div v-if="!item.isDecision" class="step-item">
                  <div class="step-content">
                    <span class="step-icon">
                      {{ getNodeIcon(item) }}
                    </span>
                    <div class="step-details">
                      <span class="step-title">{{ item.title }}</span>
                      <div v-if="isEditing && editingNodeId === item.id" class="description-edit">
                        <input
                          :ref="el => { if (el) descriptionInputRef = el }"
                          v-model="editedDescription"
                          class="description-input"
                          @blur="saveDescription"
                          @keyup.enter="saveDescription"
                          @keyup.esc="cancelEdit"
                        />
                      </div>
                      <span v-else class="step-description editable" @click="startEditing(item)">
                        {{ item.description || 'No description available' }}
                      </span>
                    </div>
                  </div>
                </div>
                
                <!-- Decision step with branches -->
                <div v-else class="decision-group">
                  <div class="decision-header" @click="toggleDecision(item.id)" :class="{ 'collapsed': isDecisionCollapsed(item.id) }">
                    <span class="decision-icon">❓</span>
                    <div class="decision-details">
                      <span class="decision-title">{{ item.title }}</span>
                      <div v-if="isEditing && editingNodeId === item.id" class="description-edit">
                        <input
                          :ref="el => { if (el) descriptionInputRef = el }"
                          v-model="editedDescription"
                          class="description-input"
                          @blur="saveDescription"
                          @keyup.enter="saveDescription"
                          @keyup.esc="cancelEdit"
                          @click.stop
                        />
                      </div>
                      <span v-else class="decision-description editable" @click.stop="startEditing(item)">
                        {{ item.description || 'No description available' }}
                      </span>
                    </div>
                    <span class="collapse-icon">{{ isDecisionCollapsed(item.id) ? '▼' : '▲' }}</span>
                  </div>
                  <div class="decision-branches" v-show="!isDecisionCollapsed(item.id)">
                    <!-- Yes branch -->
                    <div class="branch-section">
                      <div class="branch-header yes-branch" @click="toggleBranch(`${item.id}-yes`)" :class="{ 'collapsed': isBranchCollapsed(`${item.id}-yes`) }">
                        <span class="branch-icon">✓</span>
                        Yes Path
                        <span class="collapse-icon">{{ isBranchCollapsed(`${item.id}-yes`) ? '▼' : '▲' }}</span>
                      </div>
                      <div class="branch-nodes" v-show="!isBranchCollapsed(`${item.id}-yes`)">
                        <decision-branch
                          v-for="branchNode in item.yesBranch"
                          :key="branchNode.id"
                          :node="branchNode"
                          :collapsed-decisions="collapsedDecisions"
                          :collapsed-branches="collapsedBranches"
                          :process-id="processId"
                          @toggle-decision="toggleDecision"
                          @toggle-branch="toggleBranch"
                          @description-updated="$emit('description-updated', $event)"
                        />
                      </div>
                    </div>
                    
                    <!-- No branch -->
                    <div class="branch-section">
                      <div class="branch-header no-branch" @click="toggleBranch(`${item.id}-no`)" :class="{ 'collapsed': isBranchCollapsed(`${item.id}-no`) }">
                        <span class="branch-icon">✗</span>
                        No Path
                        <span class="collapse-icon">{{ isBranchCollapsed(`${item.id}-no`) ? '▼' : '▲' }}</span>
                      </div>
                      <div class="branch-nodes" v-show="!isBranchCollapsed(`${item.id}-no`)">
                        <decision-branch
                          v-for="branchNode in item.noBranch"
                          :key="branchNode.id"
                          :node="branchNode"
                          :collapsed-decisions="collapsedDecisions"
                          :collapsed-branches="collapsedBranches"
                          :process-id="processId"
                          @toggle-decision="toggleDecision"
                          @toggle-branch="toggleBranch"
                          @description-updated="$emit('description-updated', $event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div v-else class="empty-state">
              <span class="empty-icon">⚠️</span>
              <p class="empty-message">No steps found in this process</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DecisionBranch from './DecisionBranch.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StepsListModal',
  components: {
    DecisionBranch
  },
  props: {
    nodes: {
      type: Array,
      required: true
    },
    connections: {
      type: Array,
      required: true
    },
    processId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      collapsedDecisions: new Set(),
      collapsedBranches: new Set(),
      isVisible: false,
      isEditing: false,
      editingNodeId: null,
      editedDescription: '',
      descriptionInputRef: null
    }
  },
  mounted() {
    if (this.isOpen) {
      this.$nextTick(() => {
        this.isVisible = true
      })
    }
  },
  computed: {
    ...mapGetters('modal', ['isModalOpen']),
    
    isOpen() {
      return this.isModalOpen('steps-list')
    },
    
    modalWidth() {
      // Base width for no nesting
      const baseWidth = 300;
      // Additional width per nesting level
      const widthPerLevel = 100;
      // Calculate max nesting depth
      const maxDepth = this.calculateMaxNestingDepth(this.processedNodes);
      // Calculate total width (min 300px, max 800px)
      return Math.min(800, Math.max(300, baseWidth + (maxDepth * widthPerLevel))) + 'px';
    },
    
    processedNodes() {
      console.log('NODES HERE - Processing nodes:', {
        nodes: this.nodes,
        connections: this.connections.map(c => ({
          id: c.id,
          source: c.source,
          target: c.target,
          type: c.type
        }))
      });

      if (!this.nodes || this.nodes.length === 0) {
        return [];
      }

      // Find the start node first
      const startNode = this.nodes.find(n => n.title === 'Start');
      if (!startNode) return this.nodes;

      // Process nodes in sequence following connections
      const processedNodes = [];
      const processedIds = new Set();
      let currentNode = startNode;

      const processNodeAndBranches = (node, processedIds) => {
        if (processedIds.has(node.id)) return null;
        processedIds.add(node.id);

        // Base node info
        const processedNode = {
          ...node,
          isDecision: node.type === 'decision'
        };

        // If this is a decision node, process its branches
        if (node.type === 'decision') {
          // Find Yes/No connections, skip self-referential ones
          const yesConn = this.connections.find(c => 
            c.source === node.id && 
            c.type === 'yes' && 
            c.source !== c.target
          );
          const noConn = this.connections.find(c => 
            c.source === node.id && 
            c.type === 'no' && 
            c.source !== c.target
          );

          console.log('NODES HERE - Decision node connections:', {
            nodeId: node.id,
            yesConn,
            noConn
          });

          // Process Yes branch
          if (yesConn) {
            const yesBranchNodes = [];
            let currentId = yesConn.target;
            
            // Skip the "Yes" node and get its target
            const yesNode = this.nodes.find(n => n.id === currentId);
            if (yesNode && yesNode.title === 'Yes') {
              const nextConn = this.connections.find(c => 
                c.source === yesNode.id && 
                c.source !== c.target
              );
              if (nextConn) {
                let currentNode = this.nodes.find(n => n.id === nextConn.target);
                while (currentNode && !processedIds.has(currentNode.id)) {
                  const processedBranchNode = processNodeAndBranches(currentNode, new Set([...processedIds]));
                  if (processedBranchNode) {
                    yesBranchNodes.push(processedBranchNode);
                    // Find next connection in the branch
                    const nextBranchConn = this.connections.find(c => 
                      c.source === currentNode.id && 
                      !c.type && 
                      !processedIds.has(c.target) &&
                      c.source !== c.target
                    );
                    currentNode = nextBranchConn ? this.nodes.find(n => n.id === nextBranchConn.target) : null;
                  } else {
                    currentNode = null;
                  }
                }
              }
            }
            processedNode.yesBranch = yesBranchNodes;
          }

          // Process No branch
          if (noConn) {
            const noBranchNodes = [];
            let currentId = noConn.target;
            
            // Skip the "No" node and get its target
            const noNode = this.nodes.find(n => n.id === currentId);
            if (noNode && noNode.title === 'No') {
              const nextConn = this.connections.find(c => 
                c.source === noNode.id && 
                c.source !== c.target
              );
              if (nextConn) {
                let currentNode = this.nodes.find(n => n.id === nextConn.target);
                while (currentNode && !processedIds.has(currentNode.id)) {
                  const processedBranchNode = processNodeAndBranches(currentNode, new Set([...processedIds]));
                  if (processedBranchNode) {
                    noBranchNodes.push(processedBranchNode);
                    // Find next connection in the branch
                    const nextBranchConn = this.connections.find(c => 
                      c.source === currentNode.id && 
                      !c.type && 
                      !processedIds.has(c.target) &&
                      c.source !== c.target
                    );
                    currentNode = nextBranchConn ? this.nodes.find(n => n.id === nextBranchConn.target) : null;
                  } else {
                    currentNode = null;
                  }
                }
              }
            }
            processedNode.noBranch = noBranchNodes;
          }
        }

        return processedNode;
      };

      // Process all nodes starting from Start
      while (currentNode) {
        const processedNode = processNodeAndBranches(currentNode, processedIds);
        if (processedNode) {
          processedNodes.push(processedNode);
          // Find next node through connections (skip yes/no connections)
          const nextConnection = this.connections.find(c => 
            c.source === currentNode.id && 
            !c.type && 
            !processedIds.has(c.target)
          );
          currentNode = nextConnection ? this.nodes.find(n => n.id === nextConnection.target) : null;
        } else {
          currentNode = null;
        }
      }

      console.log('NODES HERE - Final processed nodes:', processedNodes);
      return processedNodes;
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.isVisible = true
        })
      } else {
        this.isVisible = false
      }
    }
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    
    handleClose() {
      // Cancel any active editing when closing the modal
      if (this.isEditing) {
        this.cancelEdit();
      }
      this.isVisible = false;
      setTimeout(() => {
        this.closeModal();
      }, 300);
    },
    toggleDecision(id) {
      if (this.collapsedDecisions.has(id)) {
        this.collapsedDecisions.delete(id);
      } else {
        this.collapsedDecisions.add(id);
      }
    },
    toggleBranch(branchId) {
      if (this.collapsedBranches.has(branchId)) {
        this.collapsedBranches.delete(branchId);
      } else {
        this.collapsedBranches.add(branchId);
      }
    },
    isDecisionCollapsed(id) {
      return this.collapsedDecisions.has(id);
    },
    isBranchCollapsed(branchId) {
      return this.collapsedBranches.has(branchId);
    },
    getNodeIcon(node) {
      switch (node.type) {
        case 'start':
          return '▶️'
        case 'decision':
          return '❓'
        case 'finish':
        case 'end':
          return '🏁'
        default:
          return '⚙️'
      }
    },
    getNodeType(node) {
      switch (node.type) {
        case 'start':
          return 'Start'
        case 'decision':
          return 'Decision'
        case 'finish':
        case 'end':
          return 'Finish'
        default:
          return 'Activity'
      }
    },
    startEditing(node) {
      // Cancel any existing edit
      if (this.isEditing) {
        this.cancelEdit();
      }
      
      this.isEditing = true;
      this.editingNodeId = node.id;
      this.editedDescription = node.description || '';
      
      // Focus the input on next tick
      this.$nextTick(() => {
        if (this.descriptionInputRef) {
          this.descriptionInputRef.focus();
          this.descriptionInputRef.select();
        }
      });
    },

    async saveDescription() {
      if (!this.isEditing || !this.editingNodeId) return;

      const newDescription = this.editedDescription.trim();
      const node = this.nodes.find(n => n.id === this.editingNodeId);
      
      if (node && newDescription !== node.description) {
        // Get the current process flow data
        const flowData = this.$store.getters['swimlane/getProcessFlowData'](this.processId);
        
        // Update the node's description in the flow data
        const updatedNodes = flowData.nodes.map(n => {
          if (n.id === this.editingNodeId) {
            return { ...n, description: newDescription };
          }
          return n;
        });

        // Update the store and server with the modified flow data
        await this.$store.dispatch('swimlane/updateProcessFlowData', {
          processId: this.processId,
          flowData: {
            nodes: updatedNodes,
            connections: flowData.connections
          }
        });

        // Emit the update event for local component state
        this.$emit('description-updated', {
          nodeId: this.editingNodeId,
          description: newDescription
        });
      }

      // Reset editing state
      this.isEditing = false;
      this.editingNodeId = null;
      this.editedDescription = '';
    },

    cancelEdit() {
      this.isEditing = false;
      this.editingNodeId = null;
      this.editedDescription = '';
    },

    calculateMaxNestingDepth(nodes, currentDepth = 0) {
      if (!nodes || nodes.length === 0) return currentDepth;
      
      let maxDepth = currentDepth;
      
      for (const node of nodes) {
        if (node.isDecision) {
          const yesBranchDepth = this.calculateMaxNestingDepth(node.yesBranch || [], currentDepth + 1);
          const noBranchDepth = this.calculateMaxNestingDepth(node.noBranch || [], currentDepth + 1);
          maxDepth = Math.max(maxDepth, yesBranchDepth, noBranchDepth);
        }
      }
      
      return maxDepth;
    },
  },
  beforeUnmount() {
    // Cancel any active editing when component is unmounted
    if (this.isEditing) {
      this.cancelEdit();
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.drawer-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.steps-list-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  padding: 20px;
  width: v-bind(modalWidth);
  transition: width 0.3s ease-in-out;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.node-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px -20px 0 -20px;
  padding: 20px;
  background: var(--accent-victory-green);
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 500;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.node-selector-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(100vh - 80px); /* Account for header height */
  padding-right: 8px; /* Add padding for scrollbar */
}

.step-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.step-item:hover {
  background-color: #f0f0f0;
}

.step-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.step-icon, .node-icon {
  width: 32px;
  height: 32px;
  background: var(--accent-victory-green);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2em;
}

.step-details, .node-details {
  display: flex;
  flex-direction: column;
}

.step-title, .node-title {
  font-weight: 500;
  color: #212529;
  margin-bottom: 4px;
}

.step-type, .node-type {
  font-size: 0.9em;
  color: #6c757d;
}

.decision-group {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}

.decision-header {
  padding: 16px;
  background: var(--accent-victory-green);
  color: white;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.decision-header:hover {
  background: var(--accent-victory-green-dark, #218838);
}

.decision-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.decision-title {
  font-weight: 500;
  margin-bottom: 4px;
}

.decision-description {
  font-size: 0.9em;
  opacity: 0.9;
  line-height: 1.4;
}

.decision-icon {
  font-size: 1.2em;
  margin-top: 2px;
}

.collapse-icon {
  margin-left: auto;
  font-size: 0.8em;
  transition: transform 0.3s ease;
  margin-top: 2px;
}

.decision-header.collapsed .collapse-icon {
  transform: rotate(180deg);
}

.decision-branches {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 250px; /* Ensure minimum width for content */
}

.branch-section {
  margin-bottom: 12px;
  min-width: 230px; /* Ensure minimum width for branch content */
}

.branch-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  font-weight: 500;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.branch-header .collapse-icon {
  margin-left: auto;
  font-size: 0.8em;
  transition: transform 0.3s ease;
}

.branch-header.collapsed .collapse-icon {
  transform: rotate(180deg);
}

.yes-branch {
  background: rgba(40, 167, 69, 0.1);
  color: #28a745;
  transition: background-color 0.2s;
}

.yes-branch:hover {
  background: rgba(40, 167, 69, 0.2);
}

.no-branch {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  transition: background-color 0.2s;
}

.no-branch:hover {
  background: rgba(220, 53, 69, 0.2);
}

.branch-nodes {
  padding-left: 24px;
}

.branch-node {
  background: white;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid #e9ecef;
}

.node-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #f8f9fa;
  border-radius: 8px;
}

.empty-icon {
  font-size: 2.5em;
  margin-bottom: 16px;
}

.empty-message {
  color: #6c757d;
  font-size: 1.1em;
  margin: 0;
}

/* Scrollbar styling */
.node-selector-options::-webkit-scrollbar {
  width: 8px;
}

.node-selector-options::-webkit-scrollbar-track {
  background: transparent;
}

.node-selector-options::-webkit-scrollbar-thumb {
  background: #ced4da;
  border-radius: 4px;
}

.node-selector-options::-webkit-scrollbar-thumb:hover {
  background: #adb5bd;
}

.steps-list-drawer.closing {
  animation: modalSlideOut 0.3s ease-out forwards;
}

.nested-decision {
  margin-top: 12px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.nested-decision-header {

  background: var(--accent-victory-green);
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.95em;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.nested-decision-header:hover {
  background: var(--accent-victory-green-dark, #218838);
}

.nested-decision-header .collapse-icon {
  margin-left: auto;
  font-size: 0.8em;
}

.nested-decision-header.collapsed .collapse-icon {
  transform: rotate(180deg);
}

.nested-branches {
  padding: 12px;
  background: rgba(0, 0, 0, 0.02);
}

.nested-branch-section {
  margin-bottom: 12px;
}

.nested-branch-section:last-child {
  margin-bottom: 0;
}

.nested-branch-nodes {
  padding-left: 16px;
}

.nested-branch-nodes .branch-node {
  background: white;
}

.editable {
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 2px 4px;
  border-radius: 4px;
}

.editable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.description-edit {
  width: 100%;
}

.description-input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid var(--accent-victory-green);
  border-radius: 4px;
  font-size: 0.9em;
  line-height: 1.4;
  background: white;
  color: #212529;
}

.description-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(9, 57, 39, 0.1);
}

.step-details, .node-details, .decision-details {
  display: flex;
  flex-direction: column;
  min-width: 200px; /* Ensure minimum width for text content */
  max-width: 100%;
}

.step-description, .decision-description {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.description-input {
  width: 100%;
  min-width: 180px;
}
</style> 