<template>
  <div class="business-dashboard-view">
    <Sidebar @navigate="handleNavigation" :currentComponent="currentComponent" />
    <div class="main-content business-dashboard-route">
      <component 
        :is="currentComponent" 
        :assessmentData="assessmentData || defaultAssessmentData"
        @navigate="handleNavigation"
        @refresh-assessment="handleAssessmentRefresh"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import BusinessDashboardContent from '@/components/BusinessDashboardContent.vue'
import ChatAssessment from '@/components/ChatAssessment.vue'
import DiscoveryCallContent from '@/components/DiscoveryCallContent.vue'
import StakeholdersContent from '@/components/StakeholdersContent.vue'
import EmployeesContent from '@/components/EmployeesContent.vue'
import ProposalContent from '@/components/ProposalContent.vue'
import ReportsContent from '@/components/ReportsContent.vue'
import ProjectsContent from '@/components/ProjectsContent.vue'
import SupportContent from '@/components/SupportContent.vue'
import ProcessesContent from '@/components/ProcessesContent.vue'
import DepartmentsContent from '@/components/DepartmentsContent.vue'
import { mapActions } from 'vuex'

export default {
  name: 'BusinessDashboardView',
  components: {
    Sidebar,
    BusinessDashboardContent,
    AssessmentContent: ChatAssessment,
    DiscoveryCallContent,
    StakeholdersContent,
    EmployeesContent,
    ProposalContent,
    ReportsContent,
    ProjectsContent,
    SupportContent,
    ProcessesContent,
    DepartmentsContent
  },
  data() {
    return {
      currentComponent: 'BusinessDashboardContent',
      assessmentData: null,
      defaultAssessmentData: {
        step: 0,
        name: '',
        email: '',
        score: undefined,
        grade: undefined,
        created_at: null,
        completed_at: null,
        current_results: null
      }
    }
  },
  watch: {
    // Watch for localStorage changes
    '$route': {
      handler() {
        this.fetchAssessmentData();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['setLoginState']),
    handleNavigation(component) {
      console.log('Dashboard Navigation - Login Status:', this.$store.state.loggedIn);
      this.currentComponent = component;
      // Fetch latest assessment data when navigation occurs
      this.fetchAssessmentData();
    },
    async handleAssessmentRefresh() {
      await this.fetchAssessmentData();
    },
    async fetchAssessmentData() {
      try {
        // Get stored assessment data
        const storedData = localStorage.getItem('assessmentData');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          if (parsedData.assessment) {
            console.log('Updating assessment data from localStorage:', parsedData.assessment);
            this.assessmentData = parsedData.assessment;
            
            // Also ensure assessment_id is stored
            if (!localStorage.getItem('assessment_id') && parsedData.assessment.id) {
              localStorage.setItem('assessment_id', parsedData.assessment.id);
            }
            
            return;
          }
        }

        // If no stored data, set to default
        this.assessmentData = { ...this.defaultAssessmentData };
      } catch (error) {
        console.error('Error fetching assessment data:', error);
        this.assessmentData = { ...this.defaultAssessmentData };
      }
    }
  },
  async created() {
    await this.fetchAssessmentData();
  }
}
</script>

<style scoped>
.business-dashboard-view {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  background-color: var(--primary-background-cream);
}

:deep(.business-dashboard-route) {
  padding-top: 0 !important;
}
</style>
