<template>
  <div class="stakeholders-content">
    <div v-if="loading" class="loading-state">
      <i class="fas fa-spinner fa-spin"></i>
      <p>Loading departments...</p>
    </div>

    <EmptyDepartmentsState
      v-else-if="departments.length === 0"
      @navigate="$emit('navigate', $event)"
    />

    <div v-else class="content-layout">
      <!-- Header Section -->
      <div class="header-section">
        <h2>Department Managers</h2>
        <p class="description">
          Assign managers for each department. These individuals will serve as primary points of 
          contact with Victory AI, helping to ensure smooth implementation and communication 
          throughout the process.
        </p>
        <div class="responsibilities">
          <h4>Manager Responsibilities:</h4>
          <ul>
            <li>Primary point of contact with Victory AI</li>
            <li>Coordinate implementation within the department</li>
            <li>Provide necessary department information</li>
            <li>Participate in planning and review meetings</li>
          </ul>
        </div>
      </div>

      <div class="main-content">
        <!-- Rest of the existing content -->
        <!-- Departments Grid -->
        <div class="departments-grid">
          <div v-for="dept in departments" 
               :key="dept.name" 
               class="department-card">
            <div class="department-header">
              <div class="department-icon">
                <i :class="getDepartmentIcon(dept.name)"></i>
              </div>
              <h3>{{ dept.name }}</h3>
            </div>

            <div v-if="dept.manager" class="manager-section">
              <div class="manager-info">
                <i class="fas fa-user manager-icon"></i>
                <div class="manager-details">
                  <span class="manager-name">{{ dept.manager }}</span>
                  <span class="manager-email">{{ dept.managerEmail }}</span>
                </div>
              </div>
              <button class="change-manager-btn" @click="openManagerModal(dept)">
                <i class="fas fa-pen"></i>
                Change Manager
              </button>
            </div>
            <div v-else class="no-manager">
              <p>No manager assigned</p>
              <button class="add-manager-btn" @click="openManagerModal(dept)">
                <i class="fas fa-plus"></i>
                Add Manager
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Manager Modal -->
    <div v-if="showManagerModal" 
         class="modal-overlay"
         @click="closeManagerModal">
      <div class="modal-content" @click.stop>
        <button class="close-modal" @click="closeManagerModal">
          <i class="fas fa-times"></i>
        </button>
        <h2>{{ selectedDepartment?.manager ? 'Change' : 'Add' }} Manager for {{ selectedDepartment?.name }}</h2>
        
        <div class="form-group">
          <label>Manager Name</label>
          <input type="text" 
                 v-model="managerName" 
                 placeholder="Enter manager name">
        </div>
        <div class="form-group">
          <label>Manager Email</label>
          <input type="email" 
                 v-model="managerEmail" 
                 placeholder="Enter manager email">
        </div>

        <div class="modal-actions">
          <button class="cancel-btn" @click="closeManagerModal">Cancel</button>
          <button class="save-btn" 
                  @click="saveManager"
                  :disabled="!isFormValid">
            Save Manager
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBusinessPlan, updateDepartmentManager } from '@/services/api'
import EmptyDepartmentsState from '@/components/EmptyDepartmentsState.vue'

export default {
  name: 'StakeholdersContent',
  components: {
    EmptyDepartmentsState
  },
  data() {
    return {
      loading: true,
      departments: [],
      showManagerModal: false,
      selectedDepartment: null,
      managerName: '',
      managerEmail: ''
    }
  },
  computed: {
    isFormValid() {
      return this.managerName.trim() && 
             this.managerEmail.trim() && 
             /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.managerEmail)
    }
  },
  methods: {
    getDepartmentIcon(name) {
      const icons = {
        'Sales': 'fas fa-chart-line',
        'Customer Service': 'fas fa-headset',
        'Marketing': 'fas fa-bullhorn',
        'Operations': 'fas fa-cogs',
        'Inventory Management': 'fas fa-boxes',
        'Finance': 'fas fa-calculator'
      }
      return icons[name] || 'fas fa-building'
    },
    selectDepartment(department) {
      this.selectedDepartment = department
    },
    openManagerModal(department) {
      this.selectedDepartment = department
      this.managerName = department.manager || ''
      this.managerEmail = department.managerEmail || ''
      this.showManagerModal = true
    },
    closeManagerModal() {
      this.showManagerModal = false
      this.selectedDepartment = null
      this.managerName = ''
      this.managerEmail = ''
    },
    async saveManager() {
      if (!this.isFormValid) return

      try {
        const assessmentData = localStorage.getItem('assessmentData')
        if (assessmentData) {
          const parsedData = JSON.parse(assessmentData)
          const uid = parsedData.assessment.uid
          
          if (uid) {
            const response = await updateDepartmentManager(
              uid,
              this.selectedDepartment.name,
              this.managerName.trim(),
              this.managerEmail.trim()
            )
            
            if (response.success) {
              const departmentIndex = this.departments.findIndex(
                d => d.name === this.selectedDepartment.name
              )
              
              if (departmentIndex !== -1) {
                this.departments[departmentIndex].manager = this.managerName.trim()
                this.departments[departmentIndex].managerEmail = this.managerEmail.trim()
              }
            }
          }
        }
        this.closeManagerModal()
      } catch (error) {
        console.error('Error saving manager:', error)
        alert('There was an error saving the manager. Please try again.')
      }
    },
    async fetchDepartments() {
      try {
        this.loading = true
        const assessmentData = localStorage.getItem('assessmentData')
        console.log('Assessment data from localStorage:', assessmentData)
        
        if (assessmentData) {
          const parsedData = JSON.parse(assessmentData)
          console.log('Parsed assessment data:', parsedData)
          const uid = parsedData.assessment.uid
          console.log('UID from assessment:', uid)
          
          if (uid) {
            const response = await getBusinessPlan(uid)
            console.log('Response from getBusinessPlan:', response)
            if (response.success && response.plan) {
              this.departments = response.plan
              console.log('Departments set to:', this.departments)
              if (this.departments.length > 0) {
                this.selectDepartment(this.departments[0])
              }
            } else {
              console.log('No plan data in response:', response)
            }
          } else {
            console.log('No UID found in assessment data')
          }
        } else {
          console.log('No assessment data found in localStorage')
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
      } finally {
        this.loading = false
      }
    }
  },
  async created() {
    await this.fetchDepartments()
  }
}
</script>

<style scoped>
.stakeholders-content {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* min-height: calc(100vh - 40px); */
}

.content-layout {
  display: grid;
  grid-template-columns: 450px 1fr;
  gap: 3rem;
  width: 100%;
  max-width: 1600px;
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-section {
  text-align: left;
}

.header-section h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
}

.description {
  color: #666;
  line-height: 1.5;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.responsibilities {
  text-align: left;
  background: #f8f8f8;
  padding: 2rem;
  border-radius: 8px;
}

.responsibilities h4 {
  color: var(--main-text-color);
  margin: 0 0 1rem 0;
  font-size: 1.3rem;
}

.responsibilities ul {
  margin: 0;
  padding-left: 1.5rem;
  color: #666;
}

.responsibilities li {
  margin-bottom: 0.75rem;
  line-height: 1.5;
  font-size: 1.2rem;
}

.departments-grid {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  overflow-y: auto;
  padding-right: 1.5rem;
  max-height: 700px;
}

.department-card {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
}

.department-header {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.department-icon {
  width: 42px;
  height: 42px;
  background: rgba(var(--accent-victory-green-rgb), 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent-victory-green);
  font-size: 1.3rem;
}

.department-header h3 {
  color: var(--main-text-color);
  margin: 0;
  font-size: 1.4rem;
  flex: 1;
}

.manager-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 0 0.5rem;
}

.manager-info {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  flex: 1;
}

.manager-icon {
  color: var(--accent-victory-green);
  font-size: 1.2rem;
}

.manager-details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-width: 0;
}

.manager-name {
  font-weight: bold;
  color: var(--main-text-color);
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manager-email {
  font-size: 1.1rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-manager {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  padding: 0 0.5rem;
}

.no-manager p {
  margin: 0;
  font-size: 1.2rem;
  color: #666;
}

.add-manager-btn, .change-manager-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  background-color: var(--accent-victory-green);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  white-space: nowrap;
  min-width: 160px;
  margin-right: 1rem;
}

.change-manager-btn {
  background-color: transparent;
  color: var(--accent-victory-green);
  border: 1px solid var(--accent-victory-green);
}

.add-manager-btn:hover, .change-manager-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.modal-content h2 {
  color: var(--accent-victory-green);
  margin: 0 0 1.25rem 0;
  font-size: 1.3rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.25rem;
  color: var(--main-text-color);
  font-weight: bold;
  font-size: 0.9rem;
}

.form-group input {
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.9rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.cancel-btn, .save-btn {
  padding: 0.6rem 1.25rem;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.cancel-btn {
  background: none;
  border: 1px solid #ddd;
  color: #666;
}

.save-btn {
  background-color: var(--accent-victory-green);
  border: none;
  color: white;
}

.save-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.save-btn:not(:disabled):hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Loading and Empty States */
.loading-state, .empty-state {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  color: #666;
}

.loading-state i, .empty-state i {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: var(--accent-victory-green);
}

.action-button {
  margin-top: 0.75rem;
  padding: 0.6rem 1.25rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.action-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Scrollbar Styling */
.departments-grid::-webkit-scrollbar {
  width: 8px;
}

.departments-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.departments-grid::-webkit-scrollbar-thumb {
  background: var(--accent-victory-green);
  border-radius: 4px;
}

.departments-grid::-webkit-scrollbar-thumb:hover {
  background: var(--accent-sunny-yellow);
}
</style>

