<template>
  <div class="login-form">
    <h1>Login</h1>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <input type="email" id="email" v-model="email" placeholder="Email" required>
      </div>
      <div class="form-group">
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" placeholder="Password" required>
        <span class="password-toggle" @click="togglePassword">
          {{ showPassword ? '👁️' : '👁️‍🗨️' }}
        </span>
      </div>
      <div class="form-group remember-forgot">
        <label>
          <input type="checkbox" v-model="rememberMe">
          Remember me
        </label>
        <a href="#" class="forgot-password" @click.prevent="switchToForgotPassword">Forgot password?</a>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <button type="submit" class="login-button" :disabled="isLoading">
        {{ isLoading ? 'Logging in...' : 'Login' }}
      </button>
    </form>
    <p class="signup-link">Don't have an account? <a href="#" @click.prevent="switchToSignUp">Sign up</a></p>
  </div>
</template>

<script>
import { loginUser } from '@/services/api';
import { mapActions } from 'vuex';

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      showPassword: false,
      activeDot: 0,
      isLoading: false,
      errorMessage: '',
      // slides: [
      //   {
      //     image: require('@/assets/slide1.png'),
      //     title: 'AI Strategy Assessment',
      //     content: 'Start with our comprehensive AI readiness assessment to understand your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide2.png'),
      //     title: 'Custom AI Solutions',
      //     content: 'Get personalized AI strategies tailored to your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide3.png'),
      //     title: 'Expert Support',
      //     content: 'Our team of experts is here to guide you every step of the way.'
      //   }
      // ]
    }
  },
  created() {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      this.email = rememberedEmail;
      this.rememberMe = true;
    }
  },
  methods: {
    ...mapActions(['setShowHeader', 'setLoginState']),
    async handleLogin() {
      this.isLoading = true;
      this.errorMessage = '';
      try {
        const response = await loginUser(this.email, this.password);
        
        if (response.assessment) {
          localStorage.setItem('assessmentData', JSON.stringify(response));
          localStorage.setItem('assessment_id', response.assessment.assessment_id);
        }
        
        if (response.user_type) {
          localStorage.setItem('user_type', response.user_type);
        }
        
        this.setLoginState(true);
        
        this.setShowHeader(false);
        this.$router.push('/business-dashboard');
      } catch (error) {
        console.error('Login error:', error);
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    switchToForgotPassword() {
      this.$emit('switch-component', 'ForgotPasswordForm')
    },
    switchToSignUp() {
      this.$emit('switch-component', 'SignUpForm')
    },
    setActiveDot(index) {
      this.activeDot = index;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector('#email').focus()
    })
  }
}
</script>

<style scoped>
.login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-form h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--accent-victory-green);
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}

input[type="email"], input[type="password"], input[type="text"] {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

input[type="email"]:focus, input[type="password"]:focus, input[type="text"]:focus {
  outline: none;
  border-color: var(--accent-victory-green);
  background-color: white;
  box-shadow: 0 0 0 3px rgba(0, 128, 0, 0.1);
}

.password-toggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease;
  color: #666;
}

.remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

.login-button {
  width: 100%;
  padding: 0.875rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.signup-link {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .login-form {
    width: 100%;
    padding: 0;
  }

  input[type="email"],
  input[type="password"],
  input[type="text"] {
    width: 100%;
    font-size: 1rem;
  }
}
</style>
