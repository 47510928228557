<template>
  <div class="chat-assessment">
    <div class="assessment-banner">
      <h1>Initial Discovery</h1>
    </div>
    <div class="assessment-layout">
      <ChatSteps class="steps-section" />
      <RealtimeChat class="chat-section" />
      <ChatConstruction class="construction-section" />
    </div>
  </div>
</template>

<script>
import ChatSteps from './ChatSteps.vue'
import RealtimeChat from './RealtimeChat.vue'
import ChatConstruction from './ChatConstruction.vue'

export default {
  name: 'ChatAssessment',
  components: {
    ChatSteps,
    RealtimeChat,
    ChatConstruction
  }
}
</script>

<style scoped>
.chat-assessment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-cream);
}

.assessment-banner {
  padding: 1rem 2rem;
  background-color: var(--primary-background-cream);
  border-bottom: 1px solid #E5E5E5;
}

.assessment-banner h1 {
  color: var(--accent-victory-green);
  font-size: 1.5rem;
  margin: 0;
}

.assessment-layout {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.steps-section {
  width: 20%;
  min-width: 15rem;
  max-width: 20rem;
  border-right: 1px solid #E5E5E5;
}

.chat-section {
  flex: 1;
  min-width: 0;
  position: relative;
}

.construction-section {
  width: 25%;
  min-width: 20rem;
  max-width: 25rem;
  border-left: 1px solid #E5E5E5;
  background-color: var(--primary-background-cream);
  height: 80vh;
}
</style> 