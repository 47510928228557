import { 
  fetchProcessData, 
  fetchDepartmentProcesses, 
  updateProcessData,
  createProcess,
  fetchUserProcesses,
  updateProcessName
} from '@/services/api'

// Function to determine if we should use light text
const shouldUseLightText = (color) => {
  // Handle CSS variables
  if (color.startsWith('var(--')) return false;
  
  // Convert hex to RGB
  let r, g, b;
  if (color.startsWith('#')) {
    const hex = color.replace('#', '');
    r = parseInt(hex.substr(0, 2), 16);
    g = parseInt(hex.substr(2, 2), 16);
    b = parseInt(hex.substr(4, 2), 16);
  } else {
    // Default to dark text if color format is unknown
    return false;
  }
  
  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.7;
};

const state = {
  processes: {},
  departments: [
    { id: 'sales_dept', name: 'Sales Department' },
    { id: 'marketing_dept', name: 'Marketing Department' },
    { id: 'hr_dept', name: 'Human Resources' }
  ],
  currentDepartment: null,
  currentUserId: null,
  colorScheme: {
    backgrounds: [
      'var(--secondary-background-sky-blue)',
      '#FFD892',
      '#2D7A62',
      '#8FD4E6',
      '#4B3832',
      '#FFE5B9',
      '#5E4B3B',
      '#A8DFF0',
      '#614B3C',
      '#FFE0A3',
      '#523B35',
      '#7FCAE0',
      '#483432',
      '#FFD67F',
      '#574139',
      '#B8E6F5',
      '#3D2F2F',
      '#725A48',
      '#4A6670'
    ]
  }
}

const getters = {
  getProcessFlowData: (state) => (processId) => {
    return state.processes[processId]?.flowData || {
      nodes: [],
      connections: []
    }
  },
  getProcessDepartments: (state) => (processId) => {
    const process = state.processes[processId]
    return process?.swimDepartments || [
      { id: 'customer', name: 'CUSTOMER', roles: [], color: state.colorScheme.backgrounds[0] },
      { id: 'sales', name: 'SALES', roles: [], color: state.colorScheme.backgrounds[1] },
      { id: 'stocks', name: 'STOCKS', roles: [], color: state.colorScheme.backgrounds[2] },
      { id: 'finance', name: 'FINANCE', roles: [], color: state.colorScheme.backgrounds[3] }
    ]
  },
  getDepartmentProcesses: (state) => (departmentId) => {
    const processes = Object.values(state.processes).filter(
      process => process.departmentId === departmentId
    )
    // console.log('[Swimlane Store] Getting department processes:', departmentId, processes)
    return processes
  },
  getDepartmentColor: (state) => (index) => {
    return state.colorScheme.backgrounds[index % state.colorScheme.backgrounds.length]
  },
  getUserProcesses: (state) => {
    return Object.values(state.processes).filter(
      process => process.userId === state.currentUserId
    )
  }
}

const actions = {
  async fetchUserProcesses({ commit }, userId) {
    try {
      const processes = await fetchUserProcesses(userId)
      // console.log('[Swimlane Store] Fetched user processes:', processes)
      
      // Update store with fetched processes
      processes.forEach(process => {
        // console.log('[Swimlane Store] Setting process:', process.id, {
        //   position: process.position,
        //   gridPosition: process.gridPosition
        // })
        commit('SET_PROCESS', { processId: process.id, data: process })
        // Update grid store with process position
        if (process.gridPosition) {
          // console.log('[Swimlane Store] Updating grid position for process:', process.id, {
          //   gridPosition: process.gridPosition,
          //   gridSize: process.gridSize
          // })
          commit('grid/SET_SWIMLANE_POSITION', {
            swimlaneId: process.id,
            position: {
              x: process.gridPosition.x,
              y: process.gridPosition.y
            },
            size: { width: 1, height: 1 }
          }, { root: true })
        }
      })
      
      return processes
    } catch (error) {
      console.error('Error fetching user processes:', error)
      throw error
    }
  },

  async fetchProcess({ commit }, processId) {
    try {
      const processData = await fetchProcessData(processId)
      commit('SET_PROCESS', { processId, data: processData })
      // Update grid store with process position
      if (processData.gridPosition) {
        // console.log('[Swimlane Store] Updating grid position for process:', processId, {
        //   gridPosition: processData.gridPosition,
        //   gridSize: processData.gridSize
        // })
        commit('grid/SET_SWIMLANE_POSITION', {
          swimlaneId: processId,
          position: {
            x: processData.gridPosition.x,
            y: processData.gridPosition.y
          },
          size: { width: 1, height: 1 }
        }, { root: true })
      }
      return processId
    } catch (error) {
      console.error('Error fetching process:', error)
      throw error
    }
  },

  async fetchDepartmentProcesses({ commit }, departmentId) {
    try {
      const processes = await fetchDepartmentProcesses(departmentId)
      processes.forEach(process => {
        commit('SET_PROCESS', { processId: process.id, data: process })
        // Update grid store with process position
        if (process.gridPosition) {
          // console.log('[Swimlane Store] Updating grid position for process:', process.id, {
          //   gridPosition: process.gridPosition,
          //   gridSize: process.gridSize
          // })
          commit('grid/SET_SWIMLANE_POSITION', {
            swimlaneId: process.id,
            position: {
              x: process.gridPosition.x,
              y: process.gridPosition.y
            },
            size: { width: 1, height: 1 }
          }, { root: true })
        }
      })
      return processes
    } catch (error) {
      console.error('Error fetching department processes:', error)
      throw error
    }
  },

  async updateProcessFlowData({ commit, state }, { processId, flowData }) {
    try {
      // Get the current process data
      const currentProcess = state.processes[processId];
      if (!currentProcess) return;

      // Create the update object with flowData nested correctly
      const updateData = {
        ...currentProcess,
        flowData: {
          nodes: [...(flowData.nodes || [])],
          connections: [...(flowData.connections || [])]
        },
        // Remove any root level nodes or connections
        nodes: undefined,
        connections: undefined
      };

      // Update the process data
      await updateProcessData(processId, updateData);
      commit('UPDATE_PROCESS_FLOW', { processId, flowData });
    } catch (error) {
      console.error('Error updating process flow:', error);
      throw error;
    }
  },

  async updateProcessPosition({ commit, state }, { processId, position }) {
    try {
      const currentProcess = state.processes[processId];
      if (!currentProcess) return;

      const updateData = {
        ...currentProcess,
        position: position
      };

      await updateProcessData(processId, updateData);
      commit('UPDATE_PROCESS_POSITION', { processId, position });
      // Update grid store with new position
      commit('grid/SET_SWIMLANE_POSITION', {
        swimlaneId: processId,
        position: position,
        size: currentProcess.gridSize || { width: 1, height: 1 }
      }, { root: true });
    } catch (error) {
      console.error('Error updating process position:', error);
      throw error;
    }
  },

  async createProcess({ commit, state }, { departmentId, processData }) {
    try {
      const processWithUser = {
        ...processData,
        userId: state.currentUserId
      }
      const newProcess = await createProcess(departmentId, processWithUser)
      commit('SET_PROCESS', { processId: newProcess.id, data: newProcess })
      return newProcess.id
    } catch (error) {
      console.error('Error creating process:', error)
      throw error
    }
  },

  async initializeExampleData({ dispatch, state }) {
    // console.log('[Store] Handling legacy initializeExampleData call')
    const defaultDepartmentId = state.departments[0]?.id || 'default_department'

    // Grid cell dimensions
    const cellWidth = 264
    const cellHeight = 250
    const horizontalGap = 100
    const columnWidth = cellWidth + horizontalGap
    const nodeWidth = 200
    const nodeHeight = 100

    // Calculate grid-aligned positions
    const startX = (cellWidth - nodeWidth) / 2  // Center in first column
    const step1X = columnWidth + (cellWidth - nodeWidth) / 2  // Center in second column
    const startY = (cellHeight - nodeHeight) / 2  // Center vertically in first row

    const emptyProcess = {
      name: 'New Process',
      userId: state.currentUserId,
      flowData: {
        nodes: [
          {
            id: 'node1',
            title: 'Start',
            position: { x: startX, y: startY },
            type: 'start'
          },
          {
            id: 'node2',
            title: 'Step 1',
            position: { x: step1X, y: startY },
            type: 'activity'
          }
        ],
        gridPosition: [0, 0],
        connections: [
          {
            id: 'conn1',
            source: 'node1',
            target: 'node2'
          }
        ]
      },
      swimDepartments: [
        { id: 'customer', name: 'CUSTOMER', roles: [], color: state.colorScheme.backgrounds[0] },
        { id: 'sales', name: 'SALES', roles: [], color: state.colorScheme.backgrounds[1] },
        { id: 'stocks', name: 'STOCKS', roles: [], color: state.colorScheme.backgrounds[2] },
        { id: 'finance', name: 'FINANCE', roles: [], color: state.colorScheme.backgrounds[3] }
      ]
    }
    
    const processId = await dispatch('createProcess', {
      departmentId: defaultDepartmentId,
      processData: emptyProcess
    })
    return processId
  },

  setCurrentUserId({ commit }, userId) {
    commit('SET_CURRENT_USER_ID', userId)
  },

  async updateProcessName({ commit, state }, { processId, newName }) {
    try {
      const currentProcess = state.processes[processId];
      if (!currentProcess) return;

      // Use the new API function to update the name
      await updateProcessName(processId, newName);
      
      // Update local state
      commit('UPDATE_PROCESS_NAME', { processId, name: newName });
    } catch (error) {
      console.error('Error updating process name:', error);
      throw error;
    }
  },

  async addRole({ commit, state }, { swimlaneId, departmentId, role }) {
    try {
      // Add role to local state
      commit('ADD_ROLE', { 
        processId: swimlaneId, 
        departmentId, 
        role 
      });

      // Get the updated process data
      const process = state.processes[swimlaneId];
      if (process) {
        // Update the process in the database
        await updateProcessData(swimlaneId, process);
      }
    } catch (error) {
      console.error('Error adding role:', error);
      throw error;
    }
  },

  async removeRole({ commit, state }, { swimlaneId, departmentId, roleId }) {
    try {
      // Remove role from local state
      commit('REMOVE_ROLE', {
        processId: swimlaneId,
        departmentId,
        roleId
      });

      // Get the updated process data
      const process = state.processes[swimlaneId];
      if (process) {
        // Update the process in the database
        await updateProcessData(swimlaneId, process);
      }
    } catch (error) {
      console.error('Error removing role:', error);
      throw error;
    }
  },

  async addDepartment({ commit, state }, { swimlaneId, department, index }) {
    try {
      // Add department to local state
      commit('ADD_DEPARTMENT', {
        processId: swimlaneId,
        department,
        index
      });

      // Get the updated process data
      const process = state.processes[swimlaneId];
      if (process) {
        // Update the process in the database
        await updateProcessData(swimlaneId, process);
      }
    } catch (error) {
      console.error('Error adding department:', error);
      throw error;
    }
  },

  async updateRoleName({ commit, state }, { swimlaneId, departmentId, roleId, newName }) {
    try {
      // Update role name in local state
      commit('UPDATE_ROLE_NAME', {
        processId: swimlaneId,
        departmentId,
        roleId,
        newName
      });

      // Get the updated process data
      const process = state.processes[swimlaneId];
      if (process) {
        // Update the process in the database
        await updateProcessData(swimlaneId, process);
      }
    } catch (error) {
      console.error('Error updating role name:', error);
      throw error;
    }
  },

  async removeDepartment({ commit, state }, { processId, departmentId, departmentIndex }) {
    try {
      // Remove department from local state
      commit('REMOVE_DEPARTMENT', {
        processId,
        departmentId,
        departmentIndex
      });

      // Get the updated process data
      const process = state.processes[processId];
      if (process) {
        // Update the process in the database
        await updateProcessData(processId, process);
      }
    } catch (error) {
      console.error('Error removing department:', error);
      throw error;
    }
  },

  async reorderDepartments({ commit, state }, { processId, fromIndex, toIndex }) {
    try {
      // Reorder departments in local state
      commit('REORDER_DEPARTMENTS', {
        processId,
        fromIndex,
        toIndex
      });

      // Get the updated process data
      const process = state.processes[processId];
      if (process) {
        // Update the process in the database
        await updateProcessData(processId, process);
      }
    } catch (error) {
      console.error('Error reordering departments:', error);
      throw error;
    }
  }
}

const mutations = {
  SET_PROCESS(state, { processId, data }) {
    state.processes = {
      ...state.processes,
      [processId]: data
    }
  },

  REMOVE_PROCESS(state, processId) {
    const { [processId]: removedProcess, ...remainingProcesses } = state.processes;
    state.processes = remainingProcesses;
  },

  UPDATE_PROCESS_FLOW(state, { processId, flowData }) {
    if (state.processes[processId]) {
      state.processes[processId] = {
        ...state.processes[processId],
        flowData: {
          nodes: [...(flowData.nodes || [])],
          connections: [...(flowData.connections || [])]
        }
      }
    }
  },

  SET_DEPARTMENTS(state, departments) {
    state.departments = departments
  },

  SET_CURRENT_DEPARTMENT(state, departmentId) {
    state.currentDepartment = departmentId
  },

  SET_CURRENT_USER_ID(state, userId) {
    state.currentUserId = userId
  },

  UPDATE_PROCESS_POSITION(state, { processId, position }) {
    if (state.processes[processId]) {
      state.processes[processId] = {
        ...state.processes[processId],
        position
      }
    }
  },

  UPDATE_PROCESS_NAME(state, { processId, name }) {
    if (state.processes[processId]) {
      state.processes[processId] = {
        ...state.processes[processId],
        name
      }
    }
  },

  UPDATE_ROLE_NAME(state, { processId, departmentId, roleId, newName }) {
    if (state.processes[processId]) {
      const process = state.processes[processId];
      const updatedDepartments = process.swimDepartments.map(dept => {
        if (dept.id === departmentId) {
          return {
            ...dept,
            roles: dept.roles.map(role => {
              if (role.id === roleId) {
                return {
                  ...role,
                  name: newName
                };
              }
              return role;
            })
          };
        }
        return dept;
      });
      
      state.processes[processId] = {
        ...process,
        swimDepartments: updatedDepartments
      };
    }
  },

  ADD_ROLE(state, { processId, departmentId, role }) {
    const process = state.processes[processId];
    if (process) {
      const department = process.swimDepartments.find(d => d.id === departmentId);
      if (department) {
        if (!department.roles) {
          department.roles = [];
        }

        // Get next color from the color scheme
        const colorIndex = department.roles.length % state.colorScheme.backgrounds.length;
        const backgroundColor = state.colorScheme.backgrounds[colorIndex];

        department.roles.push({
          id: `role_${Date.now()}`,
          name: role.name,
          color: backgroundColor,
          isLightTheme: !shouldUseLightText(backgroundColor)
        });
      }
    }
  },

  REMOVE_ROLE(state, { processId, departmentId, roleId }) {
    if (state.processes[processId]) {
      const process = state.processes[processId];
      const updatedDepartments = process.swimDepartments.map(dept => {
        if (dept.id === departmentId) {
          return {
            ...dept,
            roles: dept.roles.filter(role => role.id !== roleId)
          };
        }
        return dept;
      });
      
      state.processes[processId] = {
        ...process,
        swimDepartments: updatedDepartments
      };
    }
  },

  ADD_DEPARTMENT(state, { processId, department, index }) {
    if (state.processes[processId]) {
      const process = state.processes[processId];
      const updatedDepartments = [...process.swimDepartments];
      
      // Get next color from the color scheme
      const colorIndex = updatedDepartments.length % state.colorScheme.backgrounds.length;
      const backgroundColor = state.colorScheme.backgrounds[colorIndex];

      department.color = backgroundColor;
      department.isLightTheme = !shouldUseLightText(backgroundColor);
      
      // Insert the department at the specified index
      updatedDepartments.splice(index, 0, department);
      
      state.processes[processId] = {
        ...process,
        swimDepartments: updatedDepartments
      };
    }
  },

  REMOVE_DEPARTMENT(state, { processId, departmentId, departmentIndex }) {
    if (state.processes[processId]) {
      const process = state.processes[processId];
      
      // Calculate row ranges for each department before removal
      const departmentRows = new Map();
      let currentRow = 0;
      process.swimDepartments.forEach((dept, index) => {
        const rowCount = Math.max(1, (dept.roles || []).length);
        departmentRows.set(index, {
          start: currentRow,
          end: currentRow + rowCount - 1
        });
        currentRow += rowCount;
      });

      // Get the rows that will be removed
      const removedRows = departmentRows.get(departmentIndex);
      const rowsToRemove = removedRows ? removedRows.end - removedRows.start + 1 : 0;

      // Remove the department
      const updatedDepartments = process.swimDepartments.filter((_, index) => index !== departmentIndex);
      
      // Calculate the new total number of rows
      const totalRows = updatedDepartments.reduce((total, dept) => {
        return total + Math.max(1, (dept.roles || []).length);
      }, 0);

      // If there are nodes in the flowData, we need to adjust their positions
      if (process.flowData && process.flowData.nodes) {
        // Create a map of occupied positions
        const occupiedPositions = new Map();
        process.flowData.nodes.forEach(node => {
          const row = Math.floor(node.position.y / 250);
          const col = Math.floor(node.position.x / 364); // 264 + 100 for cell width + gap
          const key = `${col}-${row}`;
          occupiedPositions.set(key, node);
        });

        // Function to check if a position is occupied
        const isPositionOccupied = (col, row) => {
          return occupiedPositions.has(`${col}-${row}`);
        };

        // Function to find the next available position
        const findNextAvailablePosition = (col, startRow) => {
          let row = startRow;
          while (row >= 0 && isPositionOccupied(col, row)) {
            row--;
          }
          return Math.max(0, row);
        };

        // Update node positions
        process.flowData.nodes = process.flowData.nodes.map(node => {
          const currentRow = Math.floor(node.position.y / 250);
          const currentCol = Math.floor(node.position.x / 364);

          // If node is in or below the removed department's rows
          if (currentRow >= removedRows.start) {
            // Calculate new row position
            let newRow;
            if (currentRow > removedRows.end) {
              // Node is below the removed department - shift up by the number of removed rows
              newRow = currentRow - rowsToRemove;
            } else {
              // Node is in the removed department - find next available position
              newRow = findNextAvailablePosition(currentCol, removedRows.start);
            }

            // Ensure the new position is within bounds
            newRow = Math.min(newRow, totalRows - 1);
            
            // Update the occupiedPositions map
            occupiedPositions.delete(`${currentCol}-${currentRow}`);
            occupiedPositions.set(`${currentCol}-${newRow}`, node);
            
            // Update node position
            return {
              ...node,
              position: {
                ...node.position,
                y: newRow * 250 + (250 - (node.height || 100)) / 2
              }
            };
          }
          return node;
        });
      }
      
      state.processes[processId] = {
        ...process,
        swimDepartments: updatedDepartments
      };
    }
  },

  REORDER_DEPARTMENTS(state, { processId, fromIndex, toIndex }) {
    if (state.processes[processId]) {
      const process = state.processes[processId];
      const departments = [...process.swimDepartments];
      
      // Remove the department from its original position
      const [movedDepartment] = departments.splice(fromIndex, 1);
      
      // Insert it at the new position
      departments.splice(toIndex, 0, movedDepartment);
      
      // Update the process with the reordered departments
      state.processes[processId] = {
        ...process,
        swimDepartments: departments
      };
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
