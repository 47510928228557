<template>
  <div class="test-container">
    <h2>Gemini Audio Stream</h2>
    <button 
      @click="toggleButton" 
      :disabled="isLoading"
      :class="{ 'button-start': !isStreaming, 'button-stop': isStreaming }"
    >
      {{ isStreaming ? 'Stop Audio Stream' : 'Start Audio Stream' }}
    </button>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import { updateButtonState, getButtonState, GEMINI_WS_URL } from '@/services/api'

class AudioStreamer {
  constructor(context) {
    this.context = context;
    this.audioQueue = [];
    this.isPlaying = false;
    this.sampleRate = 24000;
    this.bufferSize = 7680;
    this.processingBuffer = new Float32Array(0);
    this.scheduledTime = 0;
    this.gainNode = this.context.createGain();
    this.gainNode.connect(this.context.destination);
    this.isStreamComplete = false;
    this.checkInterval = null;
    this.initialBufferTime = 0.1;
  }

  addAudioData(audioData) {
    const float32Array = new Float32Array(audioData);
    const newBuffer = new Float32Array(this.processingBuffer.length + float32Array.length);
    newBuffer.set(this.processingBuffer);
    newBuffer.set(float32Array, this.processingBuffer.length);
    this.processingBuffer = newBuffer;

    while (this.processingBuffer.length >= this.bufferSize) {
      const buffer = this.processingBuffer.slice(0, this.bufferSize);
      this.audioQueue.push(buffer);
      this.processingBuffer = this.processingBuffer.slice(this.bufferSize);
    }

    if (!this.isPlaying) {
      this.isPlaying = true;
      this.scheduledTime = this.context.currentTime + this.initialBufferTime;
      this.scheduleNextBuffer();
    }
  }

  createAudioBuffer(audioData) {
    const audioBuffer = this.context.createBuffer(1, audioData.length, this.sampleRate);
    audioBuffer.getChannelData(0).set(audioData);
    return audioBuffer;
  }

  scheduleNextBuffer() {
    const SCHEDULE_AHEAD_TIME = 0.2;

    while (
      this.audioQueue.length > 0 &&
      this.scheduledTime < this.context.currentTime + SCHEDULE_AHEAD_TIME
    ) {
      const audioData = this.audioQueue.shift();
      const audioBuffer = this.createAudioBuffer(audioData);
      const source = this.context.createBufferSource();
      
      source.buffer = audioBuffer;
      source.connect(this.gainNode);

      const startTime = Math.max(this.scheduledTime, this.context.currentTime);
      source.start(startTime);
      this.scheduledTime = startTime + audioBuffer.duration;
    }

    if (this.audioQueue.length === 0 && this.processingBuffer.length === 0) {
      if (this.isStreamComplete) {
        this.isPlaying = false;
        if (this.checkInterval) {
          clearInterval(this.checkInterval);
          this.checkInterval = null;
        }
      } else {
        if (!this.checkInterval) {
          this.checkInterval = window.setInterval(() => {
            if (
              this.audioQueue.length > 0 ||
              this.processingBuffer.length >= this.bufferSize
            ) {
              this.scheduleNextBuffer();
            }
          }, 100);
        }
      }
    } else {
      const nextCheckTime = (this.scheduledTime - this.context.currentTime) * 1000;
      setTimeout(
        () => this.scheduleNextBuffer(),
        Math.max(0, nextCheckTime - 50)
      );
    }
  }

  stop() {
    this.isPlaying = false;
    this.isStreamComplete = true;
    this.audioQueue = [];
    this.processingBuffer = new Float32Array(0);
    this.scheduledTime = this.context.currentTime;

    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }

    this.gainNode.gain.linearRampToValueAtTime(0, this.context.currentTime + 0.1);

    setTimeout(() => {
      this.gainNode.disconnect();
      this.gainNode = this.context.createGain();
      this.gainNode.connect(this.context.destination);
    }, 200);
  }

  async resume() {
    if (this.context.state === "suspended") {
      await this.context.resume();
    }
    this.isStreamComplete = false;
    this.scheduledTime = this.context.currentTime + this.initialBufferTime;
    this.gainNode.gain.setValueAtTime(1, this.context.currentTime);
  }
}

export default {
  name: 'Test',
  data() {
    return {
      buttonState: 'start',
      isStreaming: false,
      socket: null,
      sessionId: null,
      audioContext: null,
      mediaStream: null,
      audioStreamer: null,
      isLoading: false,
      error: null,
      processor: null
    }
  },
  async created() {
    try {
      console.log('Component created, fetching initial state...')
      const response = await getButtonState()
      console.log('Initial state response:', response)
      this.buttonState = response.state
    } catch (error) {
      console.error('Error getting initial button state:', error)
      this.error = 'Failed to get initial state'
    }
  },
  beforeDestroy() {
    this.cleanup()
  },
  methods: {
    async toggleButton() {
      try {
        this.isLoading = true
        this.error = null
        
        const response = await updateButtonState(this.isStreaming ? 'stop' : 'start')
        console.log('Server response:', response)
        
        if (response.success) {
          if (!this.isStreaming) {
            await this.startAudioStream()
          } else {
            this.cleanup()
          }
          this.isStreaming = !this.isStreaming
        }
      } catch (error) {
        console.error('Error:', error)
        this.error = 'Failed to toggle audio stream'
      } finally {
        this.isLoading = false
      }
    },
    async startAudioStream() {
      try {
        // Initialize WebSocket connection
        console.log('🔄 Attempting to create WebSocket connection to:', GEMINI_WS_URL)
        this.socket = new WebSocket(GEMINI_WS_URL)
        
        this.socket.onopen = () => {
          console.log('🟢 WebSocket connection established, readyState:', this.socket.readyState)
        }
        
        this.socket.onerror = (error) => {
          console.error('🔴 Socket connection error:', error, 'readyState:', this.socket.readyState)
          this.error = 'WebSocket connection failed'
        }

        this.socket.onclose = (event) => {
          console.log('🔵 WebSocket connection closed:', {
            code: event.code,
            reason: event.reason,
            wasClean: event.wasClean,
            readyState: this.socket.readyState
          })
        }

        this.socket.onmessage = async (event) => {
          try {
            console.log("📥 Received WebSocket message type:", typeof event.data);
            console.log("📦 Message data type:", event.data.constructor.name);
            
            let messageData;
            if (event.data instanceof Blob) {
              console.log("🔄 Converting Blob to text...");
              const text = await event.data.text();
              console.log("📝 Blob content (first 100 chars):", text.substring(0, 100));
              messageData = JSON.parse(text);
            } else {
              messageData = JSON.parse(event.data);
            }
            
            console.log("✅ Parsed message:", messageData);
            
            if (messageData.type === 'connection_established') {
              console.log('🤝 Connection established with session ID:', messageData.session_id)
            }
            
            if (messageData.audio) {
              console.log('🎵 Received audio data of length:', messageData.audio.length)
              if (!this.audioStreamer) {
                console.log('🎧 Creating new AudioStreamer')
                this.audioStreamer = new AudioStreamer(this.audioContext);
                await this.audioStreamer.resume();
              }
              this.audioStreamer.addAudioData(messageData.audio);
            }
          } catch (error) {
            console.error("❌ Error processing WebSocket message:", error);
            console.error("🔍 Error details:", {
              name: error.name,
              message: error.message,
              stack: error.stack
            });
          }
        };
        
        // Get microphone access
        this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })
        console.log('🎤 Microphone access granted')
        this.audioContext = new AudioContext({ sampleRate: 16000 });
        
        // Create script processor for audio processing
        const source = this.audioContext.createMediaStreamSource(this.mediaStream)
        this.processor = this.audioContext.createScriptProcessor(4096, 1, 1)
        
        this.processor.onaudioprocess = (e) => {
          if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const audioData = e.inputBuffer.getChannelData(0)
            console.log('📤 Sending audio data of length:', audioData.length)
            this.socket.send(JSON.stringify({
              audio: Array.from(audioData)
            }))
          }
        }
        
        source.connect(this.processor)
        this.processor.connect(this.audioContext.destination)
        
      } catch (error) {
        console.error('Error starting audio stream:', error)
        this.error = 'Failed to start audio stream'
        throw error
      }
    },
    cleanup() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
      
      if (this.processor) {
        this.processor.disconnect()
        this.processor = null
      }
      
      if (this.audioContext) {
        this.audioContext.close()
        this.audioContext = null
      }
      
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => track.stop())
        this.mediaStream = null
      }

      if (this.audioStreamer) {
        this.audioStreamer.stop();
        this.audioStreamer = null;
      }
    }
  }
}
</script>

<style scoped>
.test-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  margin: 20px;
}

h2 {
  color: #333;
  margin-bottom: 20px;
}

button {
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
  min-width: 200px;
}

.button-start {
  background-color: #4CAF50;
  color: white;
}

.button-stop {
  background-color: #f44336;
  color: white;
}

button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  background-color: #cccccc;
}

.error {
  color: #f44336;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffebee;
  border-radius: 4px;
  text-align: center;
}
</style>